import React from 'react';

export default function Textarea({
    className,
    name,
    id,
    label,
    cols = 30,
    rows = 8,
    placeholder,
    value,
    onChange,
    helperText,
    error,
}) {
    return (
        <div className={`form-group ${className}`}>
            {label && (
            <label className="labelHeader" >
                {label}
            </label>
            )}
            <textarea
                name={name}
                id={id}
                cols={cols}
                rows={rows}
                className="form-control"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
            />
            {error && <span className="error-text">{helperText}</span>}
        </div>
    )
};