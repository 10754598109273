import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PAYMENT_HISTORY_COLUMNS, DEFAULT_LIMIT, DEFAULT_PAGE } from './constants';
import { getPaymentHistoryList } from './actions';

import { NavigationControl } from 'components';
import { PaymentMethods } from './paymentMethods';
import { MainTable } from 'components/mainTable';

export function Billing() {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);

  const historyList = useSelector((state) => state.billing.paymentHistory.data);
  const historyTotal = useSelector((state) => state.billing.paymentHistory.total);
  const isPaymentHistoryLoading = useSelector(
    (state) => state.billing.isPaymentHistoryLoading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentHistoryList({page: currentPage, limit}));
  }, [currentPage, limit]);

  return (
    <div className="content billing_container">
      <NavigationControl title="Billing & Payments" />
      <PaymentMethods title="Billing & Payments" />
      <div className="dashboardContent">
        <div className="invoiceCard">
          <MainTable
            columns={PAYMENT_HISTORY_COLUMNS}
            data={historyList}
            total={historyTotal}
            hasPagination={true}
            hasSearch={true}
            hasLoading={true}
            isPending={isPaymentHistoryLoading}
            title="Invoices"
            setCurrentPage={setCurrentPage}
            setLimit={setLimit}
          />
        </div>
      </div>
    </div>
  );
}
