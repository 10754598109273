import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  highlights: [],
  websiteData: {},
  allHighlights: null,
};

export const { reducer, actions } = createSlice({
  name: 'property-website',
  initialState,
  reducers: {
    setWebsiteData: (state, { payload }) => {
      state.websiteData = payload;
    },
    setPropertyWebsiteHighlights: (state, { payload }) => {
      state.highlights = payload;
    },
    setAllHighlights: (state, { payload }) => {
      state.allHighlights = payload;
    },
  },
});
