import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from 'components/circularProgress';
import Input from 'components/input';

import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';
import { isNumber } from 'helpers';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';
import { updateCompanyServicesPrice } from 'pages/tours/actions';
import { Toastify } from 'hooks';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import { ORDER_SUMMARY } from 'constants/orderSummary';
import { STEPS } from 'pages/tours/create/constants';

export function ServicesOrderSummary(props) {
  const {
    title,
    services,
    handlePay,
    isPaid,
    fixedPercentAmount,
    isLoadingPayCompanyServices,
    note,
    tourId,
    hasBtn = true,
    hasSetPriceOpportunity = true,
    disablePriceSet = false,
    disable,
  } = props;
  const [editingPriceServiceId, setEditingPriceServiceId] = useState(null);
  const [currentServicePrice, setCurrentServicePrice] = useState(null);
  const [isLoadingSavingServicePrice, setIsLoadingSavingServicePrice] =
    useState(false);

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const laterChargeSubtotal = services?.reduce(
    (acc, item) => Number(acc) + Number(item?.price),
    0
  );
  const laterChargeSubtotalPercentPrice = (
    ((laterChargeSubtotal || 0) * fixedPercentAmount) /
    STEPS.hundredPercent
  ).toFixed(STEPS.fixedCount);
  const laterChargeTotal =
    Number(laterChargeSubtotal) + Number(laterChargeSubtotalPercentPrice);

  const handleChangePrice = (e) => {
    const value = e.target.value;
    if (isNumber(value) || !String(value)?.length) {
      setCurrentServicePrice(value);
    }
  };

  const handleSaveServicePrice = ({ id, price }) => {
    setIsLoadingSavingServicePrice(true);
    dispatch(
      updateCompanyServicesPrice({
        tourId: tourId,
        body: {
          service_id: id,
          price: Number(price),
        },
      })
    )
      .then(() => {
        Toastify({
          type: TOAST_TYPES.success,
          message: TOAST_MESSAGES.savedChanges,
        });
        setEditingPriceServiceId(null);
        setCurrentServicePrice(0);
      })
      .catch(() =>
        Toastify({
          type: TOAST_TYPES.error,
          message: TOAST_MESSAGES.tryAgain,
        })
      )
      .finally(() => setIsLoadingSavingServicePrice(false));
  };

  const editServicePrice = (price, id) => {
    if(!isPaid || !price){
      setEditingPriceServiceId(id)
    }
  }

  return (
    <>
      {' '}
      <div className="cardCharge">
        <h3>{title}</h3>
        {!!note && <p className="note">{note}</p>}
        <div className="titleCharge">
          <h4>{ORDER_SUMMARY.more_services}</h4>
        </div>
        <ul>
          {services?.map((serviceItem, idx) => (
            <li key={idx}>
              <span>{serviceItem?.title}</span>{' '}
              {(!!serviceItem?.price && serviceItem?.price_fixed) || isPaid ? (
                `${`$${serviceItem.price}`}`
              ) : (
                <>
                  {editingPriceServiceId === serviceItem.id &&
                  isBrokerageAdmin(user) &&
                  hasSetPriceOpportunity &&
                  !disablePriceSet ? (
                    <div className="price_input_container">
                      <Input
                        placeholder={ORDER_SUMMARY.enter_price}
                        onChange={handleChangePrice}
                        value={currentServicePrice}
                      />
                      <button
                        className="btn cta-primary small"
                        onClick={() =>
                          handleSaveServicePrice({
                            id: serviceItem.id,
                            price: currentServicePrice,
                          })
                        }
                      >
                        {isLoadingSavingServicePrice ? (
                          <CircularProgress
                            type={CIRCULAR_PROGRESS_TYPES.white}
                            size={CIRCULAR_PROGRESS_SIZE_NAMES.extraSmall}
                          />
                        ) : ORDER_SUMMARY.save}
                      </button>
                      <button
                        className="btn cta-outline-primary small"
                        onClick={() => setEditingPriceServiceId(null)}
                      >
                        {ORDER_SUMMARY.cancel}
                      </button>
                    </div>
                  ) : (
                    <span
                      className="not_specified_text underlined"
                      onClick={() => editServicePrice(serviceItem?.price, serviceItem.id)}
                    >
                      {serviceItem?.price || serviceItem?.price_fixed || ORDER_SUMMARY.not_specified}
                    </span>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
        <div className="totalCard">
          <div className="amount withoutTopBorder">
            <p>
              <span>{ORDER_SUMMARY.subtotal}</span>{' '}
              <strong>
                {laterChargeSubtotal ? `$${laterChargeSubtotal}` : ''}
              </strong>
            </p>
            <p>
              <span>{ORDER_SUMMARY.hst}</span>{' '}
              <strong>
                {fixedPercentAmount}% (
                {laterChargeSubtotalPercentPrice
                  ? `$${laterChargeSubtotalPercentPrice || 0}`
                  : 0}
                )
              </strong>
            </p>
          </div>
          <div className="total">
            <p>
              <span>{ORDER_SUMMARY.total}</span>{' '}
              <strong>{laterChargeTotal ? `$${laterChargeTotal}` : ''}</strong>
            </p>
          </div>
        </div>
      </div>
      {!!laterChargeTotal && !isPaid && hasBtn && (
        <button
          disabled={
            disable || isLoadingPayCompanyServices || services.some((serv) => !serv.price)
          }
          onClick={handlePay}
          className="btn cta-primary later_charge_pay_btn"
        >
          {isLoadingPayCompanyServices ? (
            <CircularProgress
              type={CIRCULAR_PROGRESS_TYPES.white}
              size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
            />
          ) : (
            `${ORDER_SUMMARY.pay} $${laterChargeTotal}`
          )}
        </button>
      )}
    </>
  );
}
