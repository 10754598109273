import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  MediaTour,
  NavigationControl,
  Pagination,
  SearchBar,
} from 'components';
import { toursListSelector } from './selectors';
import { getTours } from './actions';
import { types } from 'constants';

const pageTitle = 'Listings';

export function Tours() {
  const [filters, setFilters] = useState({
    statuses: [],
    agents: [],
  });
  const currPage = useSelector((state) => state.tours.currPage);

  const [currentPage, setCurrentPage] = useState(currPage);

  const toursList = useSelector(toursListSelector);

  return (
    <div className="content">
      <NavigationControl title={pageTitle} />
      <SearchBar
        filters={filters}
        setFilters={setFilters}
        action={getTours}
        isFilterShow
        currentPage={currentPage}
        title={pageTitle}
      />
      <div className="dashboardMain">
        {toursList.data?.map((tour) => (
          <MediaTour key={tour.id} tour={tour} types={types} />
        ))}
      </div>
      <div className="pagination-container">
        {!!toursList?.data?.length && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={toursList.meta.total}
            pageSize={toursList.meta.per_page}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}
