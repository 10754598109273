import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { createCompanyUser } from 'pages/users/actions';
import { actions as userActions } from 'pages/users/store';
import { Toastify } from 'hooks';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';

import { Input, Select } from 'components';
import { formatPhoneNumber } from 'helpers/formatPhoneNumber';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';
import { validatePhoneNumberRegex } from 'regex/phoneRegex';

export function CreateCompanyUser() {
  const [phoneNumber, setPhoneNumber] = useState('');

  const user = useSelector((state) => state.auth.user);
  const createLoading = useSelector((state) => state.users.createLoading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .max(50, 'Password is too long')
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .max(50, 'Password is too long')
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    position: yup.string().required('User type is required'),
    name: yup.string('Enter first name').required('First name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    phone: yup
      .string('Enter phone number')
      .required('Phone number is required')
      .min(10, 'Phone number should be of minimum 10 characters length')
      .test('format', 'Phone Number format is wrong', () =>
        validatePhoneNumberRegex.test(phoneNumber)
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
      position: '',
      name: '',
      last_name: '',
      phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isBrokerageAdmin(user)) {
        return;
      }
      dispatch(createCompanyUser(values))
        .then(() => {
          dispatch(userActions.setCreateLoading(false));
          navigate('/users');
        })
        .catch(({ response }) => {
          const message = response.data.errors.email[0];
          dispatch(userActions.setCreateLoading(false));
          Toastify({
            type: TOAST_TYPES.error,
            message: message
              ? TOAST_MESSAGES.existingEmail
              : TOAST_MESSAGES.tryAgain,
          });
        });
    },
  });

  return (
    <div className="create-user-container">
      <div className="form-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <ul className="cstTabs">
            <li>
              <Link to="/create-user" className="active">
                Create
              </Link>
            </li>
          </ul>
          <Select
            id="position"
            label="Select Designation"
            name="position"
            options={[
              {
                label: 'Sales Representative',
                value: 'Sales Representative',
              },
              {
                label: 'Broker',
                value: 'Broker',
              },
            ]}
            placeholder="Select Designation"
            value={formik.values.position}
            onChange={formik.handleChange}
            error={formik.touched.position && Boolean(formik.errors.position)}
            helperText={formik.touched.position && formik.errors.position}
            required
          />
          <Input
            id="email"
            label="Email"
            name="email"
            placeholder="Valid email adrress"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            required
          />
          <div className="row">
            <div className="col-lg-6">
              <Input
                id="name"
                label="First Name"
                name="name"
                placeholder="John"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                required
              />
            </div>
            <div className="col-lg-6">
              <Input
                id="last_name"
                label="Last Name"
                name="last_name"
                placeholder="Doe"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                required
              />
            </div>
          </div>
          <Input
            id="phone"
            label="Phone Number"
            name="phone"
            placeholder="(437) 800-5463"
            value={phoneNumber}
            onChange={(e) => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              setPhoneNumber(formattedPhoneNumber);
              formik.handleChange(e);
            }}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            required
          />
          <Input
            id="password"
            label="Password"
            name="password"
            type="password"
            placeholder="Your password (min 8 chars)"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            required
          />
          <Input
            id="password_confirmation"
            label="Confirm Password"
            name="password_confirmation"
            type="password"
            placeholder="Your password (min 8 chars)"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={
              formik.touched.password_confirmation &&
              Boolean(formik.errors.password_confirmation)
            }
            helperText={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
            }
            required
          />
          <button
            className="btn cta-primary w-100"
            type="submit"
            disabled={createLoading}
          >
            Create an Account
          </button>
        </form>
      </div>
    </div>
  );
}
