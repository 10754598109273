import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'yup';

import {
  changeProfilePassword,
  getProfileData,
  updateProfileData,
  getCompanyUser,
  updateAgentProfile,
} from '../actions';
import { profileDataSelector, userSelector } from '../selectors';
import { formatPhoneNumber } from 'helpers/formatPhoneNumber';
import { validatePhoneNumberRegex } from 'regex/phoneRegex';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';
import { BUTTON_TYPES } from 'components/button/constants';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import { Toastify } from 'hooks';

import { CircularProgress, Input, NavigationControl, Select } from 'components';
import { AgentNoImage } from 'components/agentNoImage';
import { Button } from 'components/button';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export function ProfileSettings() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const isProfileEdit = pathname.includes('profile');
  const profileData = useSelector(
    isProfileEdit ? profileDataSelector : userSelector
  );
  const user = useSelector((state) => state.auth.user);
  const isSavingProgress = useSelector((state) => state.users.isSavingProgress);
  const isUploadingProfileImage = useSelector(
    (state) => state.users.isUploadingProfileImage
  );

  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isNotMatch, setIsNotMatch] = useState(false);
  const [isNotValidPassword, setIsNotValidPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [profile, setProfile] = useState({
    name: '',
    last_name: '',
    phone: '',
    email: '',
    description: '',
    position: '',
    image: '',
  });
  const [changePassword, setChangePassword] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
  });
  const [isEditing, setIsEditing] = useState({
    email: false,
    borkerage: false,
    phone: false,
    description: false,
    name: false,
    position: false,
  });

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const setFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key] || '');
    });
    return formData;
  };

  const changeIsEditing = (field, value) => {
    let editing = {};
    setIsValidEmail(true);
    setIsValidPhone(true);
    Object.keys(isEditing).map((el) => (editing[el] = false));
    setIsEditing({
      ...editing,
      [field]: value,
    });
  };

  const handleChangePassword = (e) => {
    setChangePassword((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const savePassword = (e) => {
    e.preventDefault();
    setIsNotMatch(false);
    setIsNotValidPassword(false);
    if (
      changePassword.password === changePassword.password_confirmation &&
      changePassword.password.length > 7 &&
      changePassword.old_password
    ) {
      dispatch(
        changeProfilePassword(changePassword, () => {
          setChangePassword({
            old_password: '',
            password: '',
            password_confirmation: '',
          });
          setIsPasswordChange(false);
        })
      )
        .then(() => {
          Toastify({
            type: TOAST_TYPES.success,
            message: TOAST_MESSAGES.passwordChanged,
          });
        })
        .catch(() => {
          Toastify({
            type: TOAST_TYPES.error,
            message: TOAST_MESSAGES.wrongPassword,
          });
        });
    } else if (
      changePassword.password !== changePassword.password_confirmation
    ) {
      setIsNotMatch(true);
    } else if (changePassword.password.length < 8) {
      setIsNotValidPassword(true);
    }
  };

  const handleChangeProfile = (e, name) => {
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setProfile((prev) => ({
        ...prev,
        [name]: formattedPhoneNumber,
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  };

  const saveProfileChange = (name) => {
    let data = {};
    if (name === 'name') {
      data = {
        name: profile.name,
        last_name: profile.last_name,
      };
    } else if (name === 'email') {
      if (string().email().isValidSync(profile.email)) {
        data = {
          email: profile.email,
        };
      } else {
        setIsValidEmail(false);
        setIsValidEmail(string().email().isValidSync(profile.email));
      }
    } else if (name === 'phone') {
      if (validatePhoneNumberRegex.test(profile.phone)) {
        data = {
          phone: profile.phone,
        };
      } else {
        setIsValidPhone(false);
        setIsValidPhone(validatePhoneNumberRegex.test(profile.phone));
      }
    } else {
      data = {
        [name]: profile[name],
      };
    }
    if (Object.keys(data).length) {
      if (isProfileEdit) {
        dispatch(updateProfileData({ ...data, image: profile.image }))
          .then(() => {
            Toastify({
              type: TOAST_TYPES.success,
              message: TOAST_MESSAGES.savedChanges,
            });
          })
          .catch(() => {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.networkCheck,
            });
          })
          .finally(() => changeIsEditing(name, false));
      } else {
        dispatch(
          updateAgentProfile(
            id,
            setFormData({ ...profile, ...data, image: profile.image })
          )
        )
          .then(() => {
            Toastify({
              type: TOAST_TYPES.success,
              message: TOAST_MESSAGES.savedChanges,
            });
          })
          .catch(() => {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.networkCheck,
            });
          })
          .finally(() => changeIsEditing(name, false));
      }
    }
  };

  const cancelProfileChange = (name) => {
    if (name === 'name') {
      setProfile((prev) => ({
        ...prev,
        name: profileData.name,
        last_name: profileData.last_name,
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        [name]: profileData[name],
      }));
    }
  };

  const openFileUploadWindow = () => {
    inputRef.current.click();
  };

  const changeProfilePhoto = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (fileObj) {
      const isChangingImage = true;
      const formData = new FormData();
      formData.append('image', fileObj);
      if (isProfileEdit) {
        dispatch(updateProfileData(formData))
          .then(() => {
            Toastify({
              type: TOAST_TYPES.success,
              message: TOAST_MESSAGES.imageChanged,
            });
          })
          .catch(() => {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.imageUpdateFailed,
            });
          });
      } else {
        Object.keys(profile).forEach((key) => {
          if (key !== 'image') {
            formData.append(key, profile[key] || '');
          }
        });
        dispatch(updateAgentProfile(id, formData, isChangingImage))
          .then(() => {
            Toastify({
              type: TOAST_TYPES.success,
              message: TOAST_MESSAGES.imageChanged,
            });
          })
          .catch(() => {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.imageUpdateFailed,
            });
          });
      }
    }
  };

  useEffect(() => {
    isProfileEdit ? dispatch(getProfileData()) : dispatch(getCompanyUser(id));
  }, []);

  useEffect(() => {
    setProfile(profileData);
  }, [profileData]);

  return (
    <div className="content">
      <NavigationControl isBack title="Profile Settings" />
      <div className="dashboardContent">
        <div className="card">
          <h3 className="heading3">Profile Settings</h3>
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="profileInfoCard">
                <div className="titleImg">
                  <div className="avatar">
                    {!isUploadingProfileImage ? (
                      <>
                        {profile.image ? (
                          <img
                            src={imageBaseUrl + profile.image}
                            alt="avatar"
                          />
                        ) : (
                          <AgentNoImage
                            name={profile.name}
                            surname={profile.last_name}
                          />
                        )}
                      </>
                    ) : (
                      <CircularProgress
                        type={CIRCULAR_PROGRESS_TYPES.white}
                        size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                      />
                    )}
                  </div>
                  <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type="file"
                    onChange={changeProfilePhoto}
                  />
                  <button onClick={openFileUploadWindow}>Update photo</button>
                </div>
                {isEditing.name ? (
                  <div className="info">
                    <div className="media-body">
                      <Input
                        isLabeShow={false}
                        autoComplete="off"
                        id="name"
                        value={profile.name}
                        placeholder="Enter name"
                        name="name"
                        onChange={(e) => handleChangeProfile(e, 'name')}
                      />
                      <Input
                        isLabeShow={false}
                        autoComplete="off"
                        id="last_name"
                        value={profile.last_name}
                        placeholder="Enter surname"
                        name="last_name"
                        onChange={(e) => handleChangeProfile(e, 'last_name')}
                      />
                      <Button
                        type={BUTTON_TYPES.main_save}
                        loading={isSavingProgress}
                        customClasses="btn cta-primary"
                        onClick={() => {
                          saveProfileChange('name');
                        }}
                        text="Save"
                      />
                    </div>
                    <u
                      className="ml-2"
                      onClick={() => {
                        changeIsEditing('name', false);
                        cancelProfileChange('name');
                      }}
                    >
                      Cancel
                    </u>
                  </div>
                ) : (
                  <div className="info">
                    <div className="media-body">
                      <h4>Name Surname</h4>
                      <p>{`${profile?.name ?? ''} ${
                        profile?.last_name ?? ''
                      }`}</p>
                    </div>
                    <u onClick={() => changeIsEditing('name', true)}>Edit</u>
                  </div>
                )}
                {isEditing.position ? (
                  <div className="info">
                    <div className="media-body">
                      <Select
                        id="position"
                        label="Designation"
                        name="position"
                        options={[
                          {
                            label: 'Sales Representative',
                            value: 'Sales Representative',
                          },
                          {
                            label: 'Broker',
                            value: 'Broker',
                          },
                        ]}
                        placeholder="Select designation"
                        value={profile.position}
                        onChange={(e) => handleChangeProfile(e, 'position')}
                      />
                      <Button
                        type={BUTTON_TYPES.main_save}
                        loading={isSavingProgress}
                        customClasses="btn cta-primary"
                        onClick={() => {
                          saveProfileChange('position');
                        }}
                        text="Save"
                      />
                    </div>
                    <u
                      className="ml-2 profile_edit_cancel"
                      onClick={() => {
                        changeIsEditing('position', false);
                        cancelProfileChange('position');
                      }}
                    >
                      Cancel
                    </u>
                  </div>
                ) : (
                  <div className="info">
                    <div className="media-body">
                      <h4>Designation</h4>
                      <p>{profile?.position || 'Not Provided'}</p>
                    </div>
                    <u
                      onClick={() => {
                        setProfile((prev) => ({
                          ...prev,
                          position: profile?.position,
                        }));
                        changeIsEditing('position', true);
                      }}
                    >
                      {profile?.position?.length ? 'Edit' : 'Add'}
                    </u>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <h4 className="heading4">Personal Information</h4>
              {isEditing.email ? (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Email</h4>
                    <Input
                      isLabeShow={false}
                      autoComplete="off"
                      id="email"
                      value={profile.email}
                      placeholder="Enter email"
                      name="email"
                      error={!isValidEmail}
                      onChange={(e) => handleChangeProfile(e, 'email')}
                      helperText="Email is not valid"
                    />
                    <Button
                      type={BUTTON_TYPES.main_save}
                      loading={isSavingProgress}
                      customClasses="btn cta-primary"
                      onClick={() => {
                        saveProfileChange('email');
                      }}
                      text="Save"
                    />
                  </div>
                  <u
                    onClick={() => {
                      changeIsEditing('email', false);
                      cancelProfileChange('email');
                    }}
                  >
                    Cancel
                  </u>
                </div>
              ) : (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Email</h4>
                    <p>{profile?.email}</p>
                  </div>
                  <u onClick={() => changeIsEditing('email', true)}>Edit</u>
                </div>
              )}
              <div className="editInfo">
                <div className="media-body">
                  <h4>Brokerage</h4>
                  <a
                    rel="noreferrer"
                    href={profileData?.site_url || 'https://property.ca'}
                    target="_blank"
                  >
                    property.ca
                  </a>
                </div>
              </div>
              {isEditing.phone ? (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Phone Number</h4>
                    <Input
                      isLabeShow={false}
                      autoComplete="off"
                      id="phone"
                      value={profile.phone}
                      placeholder="Enter phone"
                      name="phone"
                      error={!isValidPhone}
                      onChange={(e) => handleChangeProfile(e, 'phone')}
                      helperText="Phone is not valid"
                    />
                    <Button
                      type={BUTTON_TYPES.main_save}
                      loading={isSavingProgress}
                      customClasses="btn cta-primary"
                      onClick={() => {
                        saveProfileChange('phone');
                      }}
                      text="Save"
                    />
                  </div>
                  <u
                    onClick={() => {
                      changeIsEditing('phone', false);
                      cancelProfileChange('phone');
                    }}
                  >
                    Cancel
                  </u>
                </div>
              ) : (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Phone Number</h4>
                    <p>{profile?.phone ?? 'Not provided'}</p>
                  </div>
                  <u onClick={() => changeIsEditing('phone', true)}>
                    {profile?.phone?.length ? 'Edit' : 'Add'}
                  </u>
                </div>
              )}
              {isEditing.description ? (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Short Description</h4>
                    <div className="form-group">
                      <textarea
                        name="description"
                        id="description"
                        cols="30"
                        rows="10"
                        value={profile.description}
                        className="form-control"
                        placeholder="Type here..."
                        onChange={(e) => handleChangeProfile(e, 'description')}
                      />
                    </div>
                    <Button
                      type={BUTTON_TYPES.main_save}
                      loading={isSavingProgress}
                      customClasses="btn cta-primary"
                      onClick={() => {
                        saveProfileChange('description');
                      }}
                      text="Save"
                    />
                  </div>
                  <u
                    onClick={() => {
                      changeIsEditing('description', false);
                      cancelProfileChange('description');
                    }}
                  >
                    Cancel
                  </u>
                </div>
              ) : (
                <div className="editInfo">
                  <div className="media-body">
                    <h4>Short Description</h4>
                    <p>{profile?.description ?? 'Not provided'}</p>
                  </div>
                  <u onClick={() => changeIsEditing('description', true)}>
                    {profile?.description?.length ? 'Edit' : 'Add'}
                  </u>
                </div>
              )}
              {isProfileEdit && (
                <>
                  <h4 className="heading4 mt-4">{`Login ${
                    profile?.email ? `- ${profile?.email}` : ''
                  }`}</h4>
                  {isPasswordChange ? (
                    <div className="editInfo">
                      <form
                        className="media-body"
                        onSubmit={(e) => savePassword(e)}
                      >
                        <Input
                          autoComplete="off"
                          id="old_password"
                          label="Current Password"
                          name="old_password"
                          value={changePassword.old_password}
                          type="password"
                          onChange={(e) => handleChangePassword(e)}
                        />
                        <Input
                          autoComplete="off"
                          id="password"
                          label="New Password"
                          name="password"
                          value={changePassword.password}
                          type="password"
                          error={isNotValidPassword}
                          helperText="Password is not valid"
                          onChange={(e) => handleChangePassword(e)}
                        />
                        <Input
                          autoComplete="off"
                          id="password_confirmation"
                          label="Confirm Password"
                          name="password_confirmation"
                          type="password"
                          value={changePassword.password_confirmation}
                          onChange={(e) => handleChangePassword(e)}
                          error={isNotMatch}
                          helperText="Please make sure your password match"
                        />
                        <Button
                          type={BUTTON_TYPES.main_save}
                          loading={isSavingProgress}
                          customClasses="btn cta-primary"
                          text="Save"
                        />
                      </form>
                      <u
                        onClick={() => {
                          setChangePassword({
                            old_password: '',
                            password: '',
                            password_confirmation: '',
                          });
                          setIsPasswordChange(false);
                        }}
                      >
                        Cancel
                      </u>
                    </div>
                  ) : (
                    <div className="editInfo">
                      <div className="media-body">
                        <h4>Password</h4>
                      </div>
                      <u onClick={() => setIsPasswordChange(true)}>Update</u>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
