import React from 'react';

import { INTRO_FLOW_ITEMS } from './constants';

import { NavigationControl } from 'components';
import { IntroFlowItem } from 'pages/tours/create/components/IntroFlowItem';

export function Intro() {
  return (
    <div className="content">
      <NavigationControl isBack title="New Order" />
      <div className="dashboardMain">
        <div className="card introMain">
          <div className="caption">
            <h3>Select Your Option</h3>
          </div>
          <div className="intro_items_container">
            {INTRO_FLOW_ITEMS.map((item) => {
              return (
                <IntroFlowItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  example_text={item.example_text}
                  navigate_path={item.navigate_path}
                  isExistingFlow={item?.isExistingFlow}
                  isBlankTarget={item.isBlankTarget}
                  isAbsolutePath={item.isAbsolutePath}
                  see_templates_here={item.see_templates_here}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
