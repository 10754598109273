import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../copy-button/style.scss';

const base_url = process.env.REACT_APP_BASE_URL;

export const CopyButton = ({ urlType, urlLink, customClass = '' }) => {
  const { id } = useParams();
  const [copySuccess, setCopySuccess] = useState({ buttonId: null, url: '' });

  const copyLinkBtn = (buttonId, url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopySuccess({
      buttonId,
      url,
    });
  };

  useEffect(() => {
    const copiedButton = setInterval(
      () => setCopySuccess({ buttonId: null, url: '' }),
      3 * 1000
    );
    return () => {
      clearInterval(copiedButton);
    };
  }, [copySuccess]);

  return (
    <>
      <button
        className={`copLink-btn ${
          copySuccess.buttonId === urlType ? 'copied_Lnkbtn' : 'copLink-btn'
        } ${customClass}`}
        onClick={() => copyLinkBtn(urlType, urlLink)}
      >
        <div className="liner_border"></div>
        <span>
          {' '}
          {copySuccess.buttonId === urlType ? 'Copied' : 'Copy Link'}
        </span>
      </button>
    </>
  );
};

export default CopyButton;
