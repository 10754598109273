import WarningIcon from 'assets/images/warning_modal_icon.svg';

export const MODAL_TYPES = {
  warning: 'warning',
};

export const HEADER_TYPES = {
  light: 'light',
};

export const MODAL_TYPES_ICONS = {
  warning: WarningIcon,
};

export const FOOTER_ELEMENTS_DIRECTIONS = {
  column: 'column',
  row: 'row',
};

export const FOOTER_TYPES = {
  light: 'light',
};
