import { useState } from 'react';
import Slider from 'react-slick';

import { defaultSettings } from './constants';

const base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.split('storage').join('');

export const HoverSlider = ({
  swipeGallery,
  settings,
  showDownloadLink = false,
  isDownloadZone = false,
  imageItemKey = 'image',
  handleMouseLeave = () => {},
  handleClick = () => {},
}) => {
  let default_settings = settings;
  const isMobile = window.innerWidth < 767;

  const [isReady, setIsReady] = useState(false);

  if (!settings) {
    default_settings =
      swipeGallery?.length === 1 ? { slidesToShow: 1 } : defaultSettings;
  }

  return (
    <Slider className="hover-slider" {...default_settings}>
      {swipeGallery?.map((item, i, arr) => {
        const imageSrc = base_url_without_storage + item[imageItemKey];
        return (
          <div
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            className="hover_slide_item slide_item"
            key={i}
          >
            {isReady && (
              <div className="duration_item">{`${i + 1} / ${arr.length}`}</div>
            )}
            <img
              onLoad={() => setIsReady(true)}
              src={imageSrc}
              className="image_slide image_slide_swiper"
              alt="house"
              key={i}
            />
          </div>
        );
      })}
    </Slider>
  );
};
