const DownloadBtn = ({ title, handleDownload, linkKey }) => {
  return (
    <button
      onClick={() => handleDownload(linkKey)}
      className="btn cta-primary download_invoice_btn"
    >
      <i>
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99984 2.50001C4.68613 2.50001 1.99984 5.1863 1.99984 8.50001C1.99984 11.8137 4.68613 14.5 7.99984 14.5C11.3135 14.5 13.9998 11.8137 13.9998 8.50001C13.9998 5.1863 11.3135 2.50001 7.99984 2.50001ZM0.666504 8.50001C0.666504 4.44992 3.94975 1.16667 7.99984 1.16667C12.0499 1.16667 15.3332 4.44992 15.3332 8.50001C15.3332 12.5501 12.0499 15.8333 7.99984 15.8333C3.94975 15.8333 0.666504 12.5501 0.666504 8.50001Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.86177 8.02859C5.12212 7.76824 5.54423 7.76824 5.80458 8.02859L7.99984 10.2239L10.1951 8.02859C10.4554 7.76824 10.8776 7.76824 11.1379 8.02859C11.3983 8.28894 11.3983 8.71105 11.1379 8.9714L8.47124 11.6381C8.21089 11.8984 7.78878 11.8984 7.52843 11.6381L4.86177 8.9714C4.60142 8.71105 4.60142 8.28894 4.86177 8.02859Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00016 5.16667C8.36835 5.16667 8.66683 5.46515 8.66683 5.83334V11.1667C8.66683 11.5349 8.36835 11.8333 8.00016 11.8333C7.63197 11.8333 7.3335 11.5349 7.3335 11.1667V5.83334C7.3335 5.46515 7.63197 5.16667 8.00016 5.16667Z"
            fill="white"
          />
        </svg>
      </i>
      {title}
    </button>
  );
};

export default DownloadBtn;
