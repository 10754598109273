import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getExistingToursToSelect } from 'pages/tours/actions';

import { StepOne } from '../components/StepOne';

export const ExistingStepOne = () => {
  const existingToursToSelect = useSelector(
    (state) => state.tours.existingToursToSelect
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExistingToursToSelect());
  }, []);

  return (
    <StepOne
      existingToursToSelect={existingToursToSelect}
      isExistingFlow={true}
    />
  );
};
