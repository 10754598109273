import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-scroll';
import stickybits from 'stickybits';

import LeftSidebar from './left-sidebar';
import config from '../../config';
import Main from './main';
import Gallery from './gallery-section';
import Floor from './floor-section';
import FeatureSheet from '../download-page-sections/feature-sheet/index';
import Instagram from './instagram-section';
import Matterport from '../download-page-sections/matterport/index';
import VideoAndMatterport from '../video_and_matterport';
import VirtualStaging from '../virtual-staging/index';
import { leftSidebarData, leftSidebarDataMob } from './left-sidebar/items';
import { useService } from '../../context';
import DownloadProgress from '../download-progress/DownloadProgress';
import { actions as downloadZoneActions } from 'pages/download-zone/newStore';

// Styles
import '../download-page-sections/download-page-styles.scss';
import './styles.scss';

const DownloadSections = ({ open, content, el, id, loading }) => {
  const [active, setActive] = useState(null);
  const [data, setData] = useState();
  const [mobile, setMobile] = useState(false);
  const [APIData, setAPIData] = useState({});
  const [page, setPage] = useState('');

  const downloadedElements = useSelector(
    (state) => state.downloadZone.downloadedItems
  );

  const dispatch = useDispatch();

  const location = useLocation();

  const { selectedServiceId, setSelectedServiceId } = useService();

  const firstAddress = content?.address?.split(/[,]/)[0];

  stickybits('selector', { useStickyClasses: true });

  useEffect(() => {
    setMobile(window.innerWidth < 960);
  }, [window.innerWidth]);

  //   navigator.serviceWorker?.register('../../downloadWorker.js');

  useEffect(() => {
    if (window.innerWidth < 960) {
      setData(leftSidebarDataMob);
      setActive(leftSidebarDataMob[0]);
    } else {
      setData(leftSidebarData);
      setActive(leftSidebarData[0]);
    }
    setAPIData(content);
  }, [content]);

  useEffect(() => {
    if (selectedServiceId && data) {
      const el = data.find((el) => el.id === parseInt(selectedServiceId, 10));
      setActive(el);
    }
  }, [data]);

  useEffect(() => {
    if (!!content) {
      const { address, unit_number } = content;
      const payload = { address, unit_number };
      dispatch(downloadZoneActions.setAddress(payload));
    }
  }, [content]);

  useEffect(() => {
    const arr = location.pathname.split('/');
    setPage(arr[arr?.length - 1]);
  }, [location]);

  useEffect(() => {
    page === 'download-zone' &&
      (document.title = `${firstAddress} ${
        content?.unit_number ? content.unit_number : ''
      } | Download Zone `);
  }, [content, page]);

  return (
    <div
      className="all-content"
      id="some-stickybit-parent"
      style={{
        minHeight: '600px',
        display: 'flex',
        alignItems: 'baseline',
        position: 'relative',
      }}
    >
      {!loading && (
        <LeftSidebar
          id="some-stickybit-nav"
          setSelectedServiceId={setSelectedServiceId}
          data={data}
          APIData={APIData}
          active={active}
          activeClass="active"
          url={config.imagesBaseUrl}
        />
      )}
      {mobile ? (
        <div id="mob_version">
          <Main
            data={data}
            url={config.imagesBaseUrl}
            content={content}
            APIData={APIData}
          />
          <VideoAndMatterport APIData={APIData} />
          <Matterport APIData={APIData} />
          <Instagram APIData={APIData} />
          <Gallery open={open} content={content} APIData={APIData} />
          <Floor open={open} content={content} APIData={APIData} />
          <VirtualStaging open={open} APIData={APIData} />
          <FeatureSheet open={open} content={content} APIData={APIData} />
        </div>
      ) : (
        <div
          id="desk_version"
          className="down_zone_desk_container"
          style={{ overflowX: 'hidden' }}
        >
          <Main
            data={data}
            url={config.imagesBaseUrl}
            content={content}
            APIData={APIData}
          />
          <VideoAndMatterport APIData={APIData} />
          <Matterport APIData={APIData} />
          <Instagram APIData={APIData} />
          <Gallery open={open} content={content} APIData={APIData} />
          <Floor open={open} content={content} APIData={APIData} />
          <VirtualStaging open={open} APIData={APIData} />
          <FeatureSheet content={content} APIData={APIData} />
        </div>
      )}
      {!!downloadedElements?.length && (
        <div className="download_element_download_progress">
          <DownloadProgress downloadedElements={downloadedElements} />
        </div>
      )}
    </div>
  );
};

export default DownloadSections;
