import Tooltip from 'components/tooltip';
import { ServiceOptionsDropdown } from 'pages/tours/create/new-steps/components/service-options-dropdown';

// images
import InfoIcon from 'assets/images/info.svg';
import { useSelector } from 'react-redux';
import { disabledServicesSelector } from 'pages/tours/selectors';

export const ServiceLabel = (props) => {
  const {
    selectedServices,
    service,
    selectService,
    isOpenServiceOptionsId,
    handleOpenServicesOptionsDropdown,
    checkLabelsDropdownState,
    handleOptionSelect,
    disabled,
    description,
    serviceInTour,
    options = 'dashboard_pricing_options',
    withOptions = false,
    isPaid,
  } = props;

  const disabledServices = useSelector(disabledServicesSelector);
  const isDisableService = disabledServices.has(service?.id);

  const isSelected =
    selectedServices?.includes(service.id) ||
    selectedServices?.some((serv) => serv.id === service.id);

  return (
    <>
      <li
        className={`serviceLabel_item_container ${
          !isDisableService && disabled && 'disabled_service'
        } ${isPaid && 'disable_cursor'} ${
          isDisableService ? 'disable_by_services' : ''
        }`}
        onClick={() =>
          !disabled && !isPaid && !isDisableService && selectService(service)
        }
      >
        <div
          className={`ctaService ${isSelected ? 'active' : ''} ${
            isOpenServiceOptionsId === service.id && 'open_ctaService'
          }`}
        >
          <span>{`${
            service.price || serviceInTour?.price || service?.price_fixed
              ? `$${
                  service.price || serviceInTour?.price || service?.price_fixed
                } - `
              : ''
          } ${service.title || ''}`}</span>
          {(!!service.dashboard_pricing_options?.length ||
            !!service?.company_pricing_options?.length) &&
            withOptions && (
              <button
                className={`label_select_btn ${
                  isSelected ? 'white_label_select_btn' : ''
                }`}
                onClick={(e) =>
                  !disabled && handleOpenServicesOptionsDropdown(e, service.id)
                }
              >
                <img
                  src={checkLabelsDropdownState({
                    isSelected: isSelected,
                    isOpen: isOpenServiceOptionsId === service.id,
                  })}
                  alt="arrow"
                />
                {isOpenServiceOptionsId === service.id && (
                  <ServiceOptionsDropdown
                    isOpenServiceOptionsId={isOpenServiceOptionsId}
                    service={service}
                    handleOptionSelect={handleOptionSelect}
                    options={options}
                  />
                )}
              </button>
            )}
        </div>
        {(service.why_need_this || service.what_is_this) && !disabled && (
          <Tooltip
            why_need_this={service.why_need_this}
            what_is_this={service.what_is_this}
            description={description}
            tooltipIcon={InfoIcon}
          />
        )}
        <div className="disabled_tooltip_container">
          {disabled && <p>{'Already included in selected package'}</p>}
        </div>
      </li>
    </>
  );
};
