export const makeUrlFromIframe = ({ iframe, autoplay }) => {
  let url = null;
  if (iframe) {
    const arrayFromIframe = iframe.split(' ');
    const srcIndex = arrayFromIframe.findIndex((elem) => elem.includes('src='));
    if (srcIndex !== -1) {
      url = arrayFromIframe[srcIndex]
        .replaceAll('src=', '')
        .replaceAll("'", '')
        .replaceAll('"', '');
    }
    if (autoplay) {
      url += '&play=1';
    }
  }
  return url;
};

export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
