import { Link } from 'react-router-dom';

import { getDateWithSlashes } from 'helpers/momentHelpers';
import { customDateSort } from './helpers';

import { Download } from 'components/download';

const innerWidth = window.innerWidth;

export const PAYMENT_HISTORY_COLUMNS = [
  {
    name: 'Name',
    selector: (row) => {
      let rowName =
        row.tour_address || `Marketing tour ${row.marketing_tour_id}`;
      if (row.is_main_service === 0 && !row.marketing_tour_id) {
        rowName =
          `${row.tour_address} - ` +
          row.company_services?.reduce(
            (acc, item, idx, arr) =>
              acc + `${item}${idx !== (arr.length - 1) ? ', ' : ''}`,
            ''
          );
      }
      return row.tour_id ? (
        <Link
          to={`/tour-details/${row.tour_id}`}
          state={{ pathname: '/billing' }}
        >
          {rowName}
        </Link>
      ) : (
        rowName
      );
    },
    sortable: true,
    grow: 2,
  },
  {
    name: 'Order date',
    grow: innerWidth > 1600 ? 2 : 1,
    selector: (row) => getDateWithSlashes(row.created_at),
    sortable: true,
    sortFunction: customDateSort,
  },
  {
    name: 'Invoices',
    grow: innerWidth > 1600 ? 2 : 3,
    selector: (row) => (
      <Download
        address={row.tour_address || row.marketing_tour_id}
        payment_id={row.payment_id}
        isRefund={row.refund}
      />
    ),
  },
];

export const PAYMENT_HISTORY_MOCK_DATA = [
  {
    payment_id: 51,
    tour_address: null,
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2022-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 50,
    tour_address: null,
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-01T12:54:41.000000Z'),
  },
  {
    payment_id: 48,
    tour_address: null,
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-10T12:40:28.000000Z'),
  },
  {
    payment_id: 47,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2023-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 46,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-09T12:54:41.000000Z'),
  },
  {
    payment_id: 45,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-08T12:40:28.000000Z'),
  },
  {
    payment_id: 52,
    tour_address: '5 Red Street, Toronto, ON',
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2022-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 50,
    tour_address: null,
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-01T12:54:41.000000Z'),
  },
  {
    payment_id: 48,
    tour_address: '5 Blue Street, Toronto, ON',
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-10T12:40:28.000000Z'),
  },
  {
    payment_id: 47,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2023-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 46,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-09T12:54:41.000000Z'),
  },
  {
    payment_id: 45,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-08T12:40:28.000000Z'),
  },
  {
    payment_id: 53,
    tour_address: null,
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2022-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 50,
    tour_address: null,
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-01T12:54:41.000000Z'),
  },
  {
    payment_id: 48,
    tour_address: null,
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-10T12:40:28.000000Z'),
  },
  {
    payment_id: 47,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 92,
    created_at: getDateWithSlashes('2023-01-10T12:54:58.000000Z'),
  },
  {
    payment_id: 46,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 91,
    created_at: getDateWithSlashes('2023-01-09T12:54:41.000000Z'),
  },
  {
    payment_id: 45,
    tour_address: '5 Michael Power Place, Toronto, ON',
    marketing_tour_id: 90,
    created_at: getDateWithSlashes('2023-01-08T12:40:28.000000Z'),
  },
];

export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE = 1;
