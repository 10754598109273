import { actions } from './store';
import { authApi } from 'api';
import { adaptUserData } from 'pages/users/adapters';
import { SUBMIT_STATES } from './constants';
import { RESPONSE_STATUSES } from 'constants';

export const getUser = () => async (dispatch) => {
  await authApi
    .getUser()
    .then(({ data: { data } }) => {
      localStorage.setItem('user_role', data.role);
      dispatch(actions.setUser(adaptUserData(data)));
    })
    .catch(() => {
      dispatch(actions.setUser({}));
    });
};

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    return await authApi
      .login({ email, password })
      .then(({ data: { data } }) => {
        localStorage.setItem('access_token', data?.access_token);
        dispatch(getUser());
      });
  };

export const logout = () => async (dispatch) => {
  return await authApi
    .logout()
    .then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_role');
    })
    .then(() => {
      dispatch(getUser());
    })
    .catch((err) => {
      if (err.response.status === RESPONSE_STATUSES.unauthorized) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_role');
      }
    });
};

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    dispatch(actions.setSubmitState(SUBMIT_STATES.submitLoading));
    const forgotPasswordRes = await authApi.forgotPassword({ email });
    return forgotPasswordRes;
  };

export const resetPassword = (values) => async (dispatch) => {
  dispatch(actions.setSubmitState(SUBMIT_STATES.submitLoading));
  return await authApi
    .resetPassword(values)
    .then(({ data: { data } }) => {
      dispatch(actions.setSubmitState(SUBMIT_STATES.submitted));
    })
    .catch(() => dispatch(actions.setSubmitState(SUBMIT_STATES.submitFailed)));
};

export const signUp = (values) => async (dispatch) => {
  return await authApi.signUp(values).then(({ data: { data } }) => {
    localStorage.setItem('access_token', data?.access_token);
    dispatch(getUser());
  });
};
