export const TOUR_STATUSES = {
  new: 'REQUESTED',
  scheduled: 'SCHEDULED',
  uploaded: 'UPLOADED',
  edited: 'EDITED',
  completed: 'COMPLETED',
  delivered: 'DELIVERED',
  other: 'OTHER',
};

export const TOUR_STATUSES_USER_VISIBLE = {
  new: 'REQUESTED',
  scheduled: 'SCHEDULED',
  delivered: 'DELIVERED',
};

export const types = [
  {
    type: TOUR_STATUSES.other,
    visibleType: TOUR_STATUSES.other,
    title: 'Other services',
    visibleTitle: 'Other services',
  },
  {
    type: TOUR_STATUSES.new,
    visibleType: TOUR_STATUSES.new,
    title: 'New',
    visibleTitle: 'New',
  },
  {
    type: TOUR_STATUSES.delivered,
    visibleType: TOUR_STATUSES.delivered,
    title: 'Delivered',
    visibleTitle: 'Delivered',
  },
  {
    type: TOUR_STATUSES.scheduled,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Scheduled',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.uploaded,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Uploaded',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.edited,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Edited',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.completed,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Completed',
    visibleTitle: 'Scheduled',
  },
];

export const classes = {
  [TOUR_STATUSES.new]: 'badgeDanger',
  [TOUR_STATUSES.scheduled]: 'badgeWarning',
  [TOUR_STATUSES.pending]: 'badgeOutlineDanger',
  [TOUR_STATUSES.delivered]: 'badgeSuccess',
  [TOUR_STATUSES.completed]: 'badgeSuccess',
  [TOUR_STATUSES.edited]: 'badgeDarkWarning',
  [TOUR_STATUSES.uploaded]: 'badgeWarning',
  [TOUR_STATUSES.other]: 'badgeOutlineDanger',
};

export const RESPONSE_STATUSES = {
  unauthorized: 401,
  not_found: 404,
};

export const isMobile = window.innerWidth < 767;

export const isIOS =
  ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;

export const MEDIA_TYPES = {
  image: 'image',
  video: 'video',
  matterport: 'matterport',
  reel: 'reel',
  pdf: 'pdf',
};

export const TOAST_TYPES = {
  error: 'error',
  success: 'success',
};

export const TOAST_MESSAGES = {
  tryAgain: 'Something went wrong. Please try again',
  existingEmail: "The email has already been taken",
  notExistingEmail: 'The email is not registered.',
  connectionCheck: 'Please check your connection and try again.',
  invalidAuth: 'Invalid login or password',
  savedChanges: 'Your Changes are saved',
  passwordChanged: 'Your password has been changed',
  networkCheck: 'Please check your network',
  imageChanged: 'The image has been changed.',
  imageUpdateFailed:
    'Image was not updated, please check your connection or try another image.',
  wrongPassword: "Wrong old password",
  highlightsMessage: "You can select only 6 highlights",
  passwordUpdate: "Password has been successfully updated",
};

export const CONTROL_BAR_PAGES = {
  tourDetails: "tour-details",
  propertyWebsite: "property-website",
}

export const GOOGLE_DRIVE_LINK = "https://drive.google.com";

export const POSTAL_CODE_ERROR = "Write in the correct format";
export const POSTAL_CODE_HELPER_TEXT = "(in A1A 1A1 format)";

export const DEFAULT_DATE = 'Mon 13/06/2022, 10:00 AM';
export const SELECT_DATE = 'Select Date & Time';

export const COMPANY_TYPE = 'company-type';
export const DASHBOARD_COMPANY = 'dashboard';
