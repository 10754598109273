export const ORDER_SUMMARY = {
  more_services: 'More Services',
  enter_price: 'Enter the price',
  save: 'Save',
  cancel: 'Cancel',
  not_specified: 'Not specified',
  subtotal: 'Subtotal',
  hst: 'HST',
  hst_percent_text: "13%",
  total: 'Total',
  pay: 'Pay',
  customize_service: "Customize Service",
  package: "Package",
  price: "Price",
  default_price: 0,
  add_ons: "Add-ons",
  fixed_count: 2,
  hundred_percent: 100,
  hst_percent: 13,
  SINGLE_ELEMENT_COUNT: 1,
}