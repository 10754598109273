import { actions } from './store';
import { billingApi } from 'api';

export const getPaymentHistoryList = (params) => {
  return async (dispatch) => {
    dispatch(actions.setIsPaymentHistoryLoading(true));
    const { data } = await billingApi.getPaymentHistoryList(params);
    dispatch(actions.setPaymentHistory(data));
    dispatch(actions.setIsPaymentHistoryLoading(false));
    return data;
  };
};

export const goToDownload =
  ({ payment_id, auth_id, linkKey }) =>
  async () => {
    try {
      const res = await billingApi.getDownloadLink({ payment_id, auth_id });
      window.open(res?.data?.[linkKey]);
    } catch (error) {
      console.log(error);
    }
  };
