import { Link } from 'react-router-dom';

import { CONTROL_BAR_PAGES } from 'constants';

const ControlBar = ({ params, state, pageName }) => {
  return (
    <div className="controlBar">
      <ul className="tabsRounded">
        <li>
          <Link
            to={`/tour-details/${params?.id}`}
            state={pageName === CONTROL_BAR_PAGES.propertyWebsite && state}
            className={
              pageName === CONTROL_BAR_PAGES.tourDetails ? 'active' : ''
            }
          >
            Listing Details
          </Link>
        </li>
        <li>
          <Link
            to={`/property-website-edit/${params?.id}`}
            state={pageName === CONTROL_BAR_PAGES.tourDetails && state}
            className={
              pageName === CONTROL_BAR_PAGES.propertyWebsite ? 'active' : ''
            }
          >
            Property Website
          </Link>
        </li>
        <li>
          <Link className="disabled" to="analytics.html">
            Analytics
          </Link>
        </li>
        <li>
          <Link className="disabled" to="actyvity-log.html">
            Activity Log
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ControlBar;
