import { createSelector } from '@reduxjs/toolkit';

const usersRootSelector = createSelector(
    ({ users }) => users,
    (users) => users
);

export const usersListSelector = createSelector(
    usersRootSelector,
    ({ list }) => list
);

export const userSelector = createSelector(
    usersRootSelector,
    ({ user }) => user
);

export const agentToursSelector = createSelector(
    usersRootSelector,
    ({ agentToursList }) => agentToursList
);

export const profileDataSelector = createSelector(
    usersRootSelector,
    ({ profile }) => profile
);