import React from 'react';
import Quantity from '../../../components/quantity-text/index';
import AddressHolder from '../../address-holder/index';
import '../matterport/style.scss';
import Button from '../../button';
import { Videos } from '../../Videos/Videos';

const Matterport = ({ APIData }) => {
  const matterportText =
    'We provide you the separate link and embed code for your Matterport scan. ***Please note that services are already present on your Property Webpage***';

  return (
    <>
      {!!APIData?.matterport?.length && (
        <section className="matterport" id="16">
          <div className="content">
            <div className="main_information_of_matterport">
              <h2 className="title_of_matterport download_section_title">
                <span>3D Matterport</span>
              </h2>
              <div className="button_block">
                <Button sectionName="3d matterport" APIData={APIData} />
              </div>
              <div className="videos_address_holder">
                <AddressHolder APIData={APIData} />
              </div>
            </div>
            <div className="quantity_holder">
              <Quantity lengthOfItems={APIData?.matterport?.length} />
            </div>
            <div className="grid-container">
              {APIData?.matterport.map((elem, index) => (
                <div className="video-container" key={index}>
                  <Videos
                    APIData={APIData}
                    element={elem}
                    copiedUrl={elem}
                    video={elem?.iframe}
                    title={elem?.title}
                    downloadVideoButton={elem}
                    textOfVideos={matterportText}
                  />
                </div>
              ))}
            </div>
            <div className="multi-liner_of_matterport"></div>
          </div>
        </section>
      )}
    </>
  );
};

export default Matterport;
