export const AgentNoImage = (props) => {
  const { name, surname, noBorder = false } = props;

  const firstNameLetter = name?.split('')?.[0]?.toUpperCase();
  const lastNameLetter = surname?.split('')?.[0]?.toUpperCase();

  return (
    <div className={`agent_no_image_item ${noBorder && 'no_border'}`}>
      <p className="agent_no_image_text">
        {name && surname ? `${firstNameLetter}${lastNameLetter}` : null}
      </p>
    </div>
  );
};
