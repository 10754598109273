import moment from 'moment';
import { WEEK_DAYS } from './momentConstants';

export const utcMoment = (date) => moment(date).utc();

export const toDateString = (dateStr) => {
  return moment(dateStr).toDate();
};

export const isValidDate = (date) => {
  if (!date) {
    return false;
  }
  return moment(date).isValid();
};

export const dayMonthYearHoursMinutesAMSpacesFormatter = (date) =>
  moment(date).format('D MMM YYYY h:mm A');

export const dayMonthYearHoursMinutesAMCommasFormatter = (date) =>
  moment(date).format('D MMMM, YYYY, h:mm A');

export const dateWithDashesSpaceFullHours = (date) =>
  moment(date).format('YYYY-MM-D hh:mm:ss');

export const dateWithDashesSpaceFullHoursAMPM = (date) =>
  moment(date).format('YYYY-MM-D hh:mm:ss A');

export const dateWithDashesSpaceFullHours24 = (date) =>
  moment(date).format('YYYY-MM-D HH:mm:ss');

export const dateWithDashesTimeFullHours24 = (date) =>
  moment(date).format('YYYY-MM-DTHH:mm:ss');

export const dateWithDashesSpace = (date) =>
  moment(date).format('YYYY-MM-DD hh:mm');

export const dateWithDashesSpaceAM = (date) =>
  moment(date).format('YYYY-MM-DD hh:mm A');

export const mothDayCommaTimeAAFormatter = (date) =>
  moment(date).format('MMM D, h:mm A');

export const getWeekDay = (date) => {
  const dayNumber = moment(date).isoWeekday();
  return WEEK_DAYS[dayNumber];
};

export const getDateWithSlashes = (date) => moment(date).format('DD/MM/YYYY');

export const getDateAndTimeWithSlashesAndDots = (date) =>
  moment(date).format('DD/MM/YYYY hh:mm:ss');

export const getWeekDayByMoment = (date) => moment(date).format('dddd');

export const dayMonthYearSpaces = (date) => moment(date).format('DD MMM YYYY');

export const getTimeAMPM = (date) => moment(date).format('hh:mm A');

export const getUTCCreatedDateFormatted = (date) =>
  moment(date).utc().format('lll');
