import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { usersListSelector } from './selectors';
import { getCompanyUsers } from './actions';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';

import { NavigationControl, SearchBar, Pagination } from 'components';
import UserItem from './components/userItem';

const pageTitle = 'Agents';

export function Users() {
  const usersList = useSelector(usersListSelector);
  const currPage = useSelector((state) => state.tours.currPage);
  const user = useSelector((state) => state.auth.user);
  const [currentPage, setCurrentPage] = useState(currPage);

  const isCompanyAdmin = isBrokerageAdmin(user);

  return (
    <div className="content">
      <NavigationControl title={pageTitle} isUsersPage={true} />
      <SearchBar
        action={getCompanyUsers}
        currentPage={currentPage}
        title={pageTitle}
        isUserPage
      />
      <div className="dashboardMain">
        <div className="row">
          {usersList?.data?.map((user, index) => (
            <UserItem isCompanyAdmin={isCompanyAdmin} key={index} user={user} />
          ))}
        </div>
      </div>
      <div className="pagination-container">
        {!!usersList?.data?.length && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={usersList.meta.total}
            pageSize={usersList.meta.per_page}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}
