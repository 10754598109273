import axios from 'axios';

import { RESPONSE_STATUSES } from 'constants';

const getConfig = async (params, headerProps) => {
  const access_token = localStorage.getItem('access_token');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      ...headerProps,
    },
    params,
  };
};

const base_url = process.env.REACT_APP_API_URL;
const getPath = (url) => `${base_url}${url}`;

export const apiManager = {
  get: async (directory, parameters, customHeaders) => {
    const res = axios.get(
      getPath(directory),
      await getConfig(parameters, customHeaders)
    );
    res.catch((err) => {
      if (err.response.status === RESPONSE_STATUSES.unauthorized) {
        localStorage.removeItem('access_token');
      }
    });
    return res;
  },
  post: async (directory, body, customHeaders) => {
    const res = axios.post(
      getPath(directory),
      body,
      await getConfig(null, customHeaders)
    );
    res.catch((err) => {
      if (err.response.status === RESPONSE_STATUSES.unauthorized) {
        localStorage.removeItem('access_token');
      }
    });
    return res;
  },
  put: async (directory, body) =>
    axios.put(getPath(directory), body, await getConfig(null)),
  delete: async (directory) =>
    axios.delete(getPath(directory), await getConfig(null)),
  patch: async (directory, body) =>
    axios.patch(getPath(directory), body, await getConfig(null)),
};
