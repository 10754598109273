import { actions } from './store';
import { toursApi } from 'api';

export const getTours = ({ queryStr }) => {
  return async (dispatch) => {
    const { data } = await toursApi.getTours({
      queryStr,
    });
    dispatch(actions.setToursList(data));
  };
};

export const getTourById = ({ tourId }) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await toursApi.getTourById({ tourId });
    dispatch(actions.setTourDetails(data));
  };
};

export const getPricingCompany = (params) => async (dispatch) => {
  const {
    data: { data },
  } = await toursApi.getPricingCompany(params);
  dispatch(actions.setPricingCompanyList(data));
};

export const getPackages = (price) => async (dispatch) => {
  const { data } = await toursApi.getPackages(price);
  dispatch(actions.setPackages(data));
};

export const getServices =
  ({ price, selected_ids }) =>
  async (dispatch) => {
    const { data } = await toursApi.getServices(price);
    dispatch(actions.setServices({ data, selected_ids }));
  };

export const getCompanyServices = () => async (dispatch) => {
  const { data } = await toursApi.getCompanyServices();
  dispatch(actions.setCompanyServices(data));
};

export const orderNewTour = (tour) => async () => {
  return await toursApi.createTour(tour);
};

export const goToPayment =
  ({ id, is_main_service }) =>
  async () => {
    return await toursApi.goToPayment({ id, is_main_service });
  };

export const payForCompanyServices =
  ({ id }) =>
  async () => {
    return await toursApi.payForCompanyServices({ id });
  };

export const goToMarketingTourPayment =
  ({ id }) =>
  async () => {
    return await toursApi.goToMarketingTourPayment({ id });
  };

export const updateTour =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateTour({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const updateCompanyServicesPrice =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateCompanyServicesPrice({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const updateTourServices =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateTourServices({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const getCompanyUsers = () => async (dispatch) => {
  const { data } = await toursApi.getCompanyUsers();
  dispatch(actions.setCompanyUsers(data));
};

export const getExistingToursToSelect = () => async (dispatch) => {
  const { data } = await toursApi.getExistingToursToSelect();
  dispatch(actions.setExistingToursToSelect(data));
};
