import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationControl } from 'components';

// Images
import FrownIcon from 'assets/images/frown.svg';

export function PaymentDeclined() {

  return (
    <div className="content">
      <NavigationControl isBack title="New Order" />
      <div className="dashboardMain paymentMessage">
        <div className="card">
          <div className="messageThanks">
            <i>
              <img src={FrownIcon} alt="frown" />
            </i>
            <h4>Sorry. Your payment declined.</h4>
            <p>
              Causes may include no network connection, an inactive account,
              insufficient funds, or too frequent activity. Please try a
              different card or restore internet connection.
            </p>
            <div className="btnControl">
              <Link to="/tours" className="btn cta-outline-primary">
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
