/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';

import { useService } from '../../../context';
import Button from '../../button/index';
import DownloadAlert from '../../download-alert/download-alert';
import {
  cancelSelection,
  selectAll,
  checkImage,
} from '../../../global/image-check-control';
import checkData from '../../../global/data-converter-for-download';
import Quantity from '../../quantity-text';
import AddressHolder from '../../address-holder';
import DownloadButtonPopup from '../../download-button-popup/DownloadButtonPopup';
import PhotoSelectButton from '../photo-select-button/PhotoSelectButton';

// Styles
import '../gallery-section/style.scss';

function Gallery({ open, APIData }) {
  const [checkedInput, setCheckedInput] = useState({});
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [links, setLinks] = useState([]);
  const [secondLink, setSecondLink] = useState([]);
  const [thirdLink, setThirdLink] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [data, setData] = useState([]);
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [touchMove, setTouchMove] = useState(false);

  const downloadBtnRef = useRef(null);

  const { download } = useService();

  const firstAddress = APIData?.address?.split(/[,]/)[0];

  useEffect(() => {
    setThirdLink([...links, ...secondLink]);
  }, [links, secondLink]);

  useEffect(() => {
    setSelectedItemCount(Object.entries(checkedInput).length);
  }, [checkedInput]);

  useEffect(() => {
    setData(checkData(APIData?.folder_completed_photo, false, 'Gallery'));
  }, [APIData]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    }
  });

  return (
    <>
      {!!APIData?.folder_completed_photo?.MLS?.length && (
        <div className="gallery_section" id="10">
          {isMobileView && (
            <div className="gallery_section_select_button_wrapper">
              <PhotoSelectButton
                isSelectedMode={isSelectedMode}
                setIsSelectedMode={setIsSelectedMode}
              />
            </div>
          )}
          <div className=" main_info">
            <h2 className="title download_section_title">Gallery</h2>
            <div className="delivery_update_button">
              <Button sectionName="Gallery" APIData={APIData} />
            </div>
          </div>
          <div className="subtitles">
            <div className="subtitles">
              <AddressHolder APIData={APIData} />
              <Quantity lengthOfItems={data?.[0]?.files?.length} />
            </div>
          </div>
          <div className="images_section">
            <div className="screen scrollbar scrollbar-default">
              {!!data?.length &&
                data[0]?.files?.map((el, i) => {
                  return (
                    <div className="image_section_item" id="sec_1" key={i}>
                      <img
                        src={el.thumbnailLink}
                        id="img_1"
                        className="galery_image"
                      />
                      <div
                        className="hover_section"
                        onTouchStart={() => setTouchMove(false)}
                        onTouchMove={() => setTouchMove(true)}
                        onTouchEnd={(e) =>
                          !touchMove && isSelectedMode
                            ? checkImage(
                                e,
                                el,
                                data?.[1]?.files,
                                links,
                                setLinks,
                                secondLink,
                                setSecondLink,
                                checkedInput,
                                setCheckedInput,
                                selectedItemCount
                              )
                            : !touchMove &&
                              open(
                                data?.[0]?.files,
                                i,
                                'gallery',
                                data?.[0]?.files
                              )
                        }
                        onClick={() =>
                          !isMobileView
                            ? open(
                                data?.[0]?.files,
                                i,
                                'gallery',
                                data?.[0]?.files
                              )
                            : null
                        }
                      >
                        <div
                          className={`before_checkbox_section ${
                            checkedInput[el.id]
                              ? 'show_checkbox'
                              : 'hide_checkbox'
                          }`}
                          onClick={(e) =>
                            checkImage(
                              e,
                              el,
                              data?.[1]?.files,
                              links,
                              setLinks,
                              secondLink,
                              setSecondLink,
                              checkedInput,
                              setCheckedInput,
                              selectedItemCount
                            )
                          }
                        >
                          <input
                            className="checkmark_checkbox"
                            type="checkbox"
                            name={el.id}
                            id={el.id}
                            checked={
                              checkedInput[el.id] ? checkedInput[el.id] : false
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                        <div className="image_name">
                          {el.name.split('.').slice(0, -1).join('.')}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div ref={downloadBtnRef} className="gallery_button_section">
            <div className="btn-wrapper">
              {!!links.length && (
                <button
                  onClick={() =>
                    cancelSelection(
                      setLinks,
                      setSecondLink,
                      setThirdLink,
                      setCheckedInput
                    )
                  }
                >
                  CANCEL SELECTION
                </button>
              )}
              <button
                className="select_button_gallery_section"
                onClick={() =>
                  selectAll(
                    data?.[0]?.files,
                    data?.[1]?.files,
                    setLinks,
                    setSecondLink,
                    setCheckedInput
                  )
                }
              >
                SELECT ALL
              </button>
              <DownloadButtonPopup
                isDownloaded={isDownloaded}
                links={links}
                setIsDownloaded={setIsDownloaded}
                secondLink={secondLink}
                thirdLink={thirdLink}
                data={data}
                sectionNameFirst={`${firstAddress} ${
                  APIData?.unit_number
                    ? `, unit number - ${APIData?.unit_number} `
                    : ''
                }_ MLS photos`}
                sectionNameSecond={`${firstAddress} ${
                  APIData?.unit_number
                    ? `, unit number - ${APIData?.unit_number} `
                    : ''
                }_ HD photos`}
                sectionNameThird={`${firstAddress} ${
                  APIData?.unit_number
                    ? `, unit number - ${APIData?.unit_number} `
                    : ''
                }_ HD and MLS photos`}
                downloadTypeFirst="DOWNLOAD MLS WEB"
                downloadTypeSecond="DOWNLOAD FOR PRINT"
                downloadTypeThird="DOWNLOAD BOTH"
                folderNameFirst="Mls Web"
                folderNameSecond="HD Print"
                section="Gallery"
                filesTypeCount="2"
                setIsNotificationShown={setIsNotificationShown}
                bothVisibility={false}
              />
            </div>
            <span className={selectedItemCount ? 'select_item' : 'hide'}>
              Selected {links.length} items
            </span>
          </div>
          {isDownloaded && <DownloadAlert />}
          <div className="gallery_liner"></div>
          {isNotificationShown && !links.length && (
            <div
              className="notification_element_for_download_button"
              id="notification_element_for_download_button"
            >
              Please select items for download
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Gallery;
