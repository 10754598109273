export const getHeaderSections = ({
  highLights,
  matterPort,
  houseDaterList,
  presentation,
  video,
  gallery,
  tourDetailsList,
  tourDetails,
  neighborhoodDisplay,
  floorPlan,
  agentInfos,
  isUnbranded = false,
}) => {
  return [
    { id: 'home', name: 'HOME', value: true, display: true },
    {
      id: 'matterport',
      name: 'MATTERPORT',
      value: matterPort && matterPort.length > 0,
      display: matterPort && matterPort.length > 0,
    },
    {
      id: 'open_house',
      name: 'OPEN HOUSE',
      value:
        houseDaterList?.housedaterlist?.[0]?.start ||
        houseDaterList?.housedaterlist?.[0]?.end,
      display: houseDaterList?.display,
    },
    {
      id: 'open_house',
      name: "PRESENTATION DATE",
      value: presentation?.presentation_date,
      display: presentation?.display,
    },
    {
      id: 'video',
      name: 'VIDEO',
      value: video && video?.length > 0,
      display: video && video?.length > 0,
    },
    {
      id: 'gallery',
      name: 'GALLERY',
      value: gallery && gallery.length > 0,
      display: gallery && gallery.length > 0,
    },
    {
      id: 'details',
      name: 'DETAILS',
      value: tourDetailsList && tourDetailsList.length > 0,
      display: tourDetails?.display,
    },
    {
      id: 'map',
      name: 'MAP',
      value: true,
      display: neighborhoodDisplay,
    },
    {
      id: 'floor_plan',
      name: 'FLOOR PLAN',
      value: floorPlan && floorPlan?.length > 0,
      display: floorPlan && floorPlan?.length > 0,
    },
    {
      id: 'agent',
      name: 'AGENT',
      value: agentInfos && agentInfos.length > 0,
      display: agentInfos?.[0]?.display && !isUnbranded,
    },
  ];
};

export const divideArray = (array) => {
  const dividedArray = [];
  let childArray = [];
  for (let i = 0; i < array?.length; ) {
    if (array[i + 1] !== undefined) {
      childArray.push(array[i], array[i + 1]);
    } else {
      childArray.push(array[i]);
    }
    dividedArray.push(childArray);
    i = i + 2;
    childArray = [];
  }
  return dividedArray;
};
