export const ServiceOptionsDropdown = (props) => {
  const {
    isOpenServiceOptionsId,
    service,
    handleOptionSelect,
    options = 'dashboard_pricing_options',
  } = props;

  return (
    <div className="dropdown_container">
      {isOpenServiceOptionsId === service.id &&
        service[options].map((option) => {
          return (
            <div
              className="label_option"
              value={option.id}
              key={option.id}
              onClick={(e) =>
                handleOptionSelect({
                  serviceId: service.id,
                  optionId: option.id,
                })
              }
            >
              {option.title}
            </div>
          );
        })}
    </div>
  );
};
