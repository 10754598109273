import React from 'react';
import { Link } from 'react-router-dom';

// Images
import Logo from 'assets/images/logo.svg';
import Propertylogo from 'assets/images/propertylogo.svg'

export default function AuthBackground () {
    return (
        <div className="col-md-6">
        <div className="brandBanner">
          <Link to="#">
            <img
              src={Logo}
              alt="logo"
              className="d-none d-md-inline-block"
            />
            <img
              src={Propertylogo}
              alt="Property logo"
              className="d-inline-block d-md-none"
            />
          </Link>
        </div>
      </div>
    )
}