import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userSelector, agentToursSelector } from '../selectors';
import { getCompanyUser, getTourByAgent } from '../actions';

import {
  MediaTour,
  NavigationControl,
  Pagination,
  SearchBar,
} from 'components';
import { AgentNoImage } from 'components/agentNoImage';

import { GOOGLE_DRIVE_LINK } from 'constants';

// Images
import Avatar from 'assets/images/avatar.png';
import ImageIcon from 'assets/images/Image.png';

const types = [
  {
    type: 'new',
    title: 'New',
  },
  {
    type: 'delivered',
    title: 'Delivered',
  },
  {
    type: 'pending',
    title: 'Other services pending',
  },
];

const tourTemplate = {
  name: 'Julia Drobinina',
  avatar: Avatar,
  img: ImageIcon,
  address: '489 Clinton Street, Toronto, ON, Canada',
  registerDate: '30 June 2022, 10:00 AM',
  orderDate: '13 July 2022 9:02 PM',
};
const base_url = process.env.REACT_APP_IMAGE_BASE_URL;

export function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    name,
    last_name,
    phone,
    email,
    position,
    site_url,
    address,
    description,
    image,
  } = useSelector(userSelector);
  const agentTours = useSelector(agentToursSelector);
  const [userDescription, setUserDescription] = useState(description);
  const currPage = useSelector((state) => state.tours.currPage);
  const [currentPage, setCurrentPage] = useState(currPage);

  const isGoogleDrive = image?.includes(GOOGLE_DRIVE_LINK);

  useEffect(() => {
    dispatch(getCompanyUser(id));
    dispatch(getTourByAgent({ id }));
  }, []);

  return (
    <div className="content">
      <NavigationControl
        isBack
        isEdit
        title={name && last_name ? `${name} ${last_name}` : null}
      />
      <div className="dashboardContent bg-white">
        <div className="row">
          <div className="col-xl-6">
            <div className="cardProfile">
              <div className="row">
                <div className="col-lg-5">
                  <div className="user">
                    <div className="img-title profile_img">
                      {image ? (
                        <img
                          src={isGoogleDrive ? image : base_url + image}
                          alt="avatar"
                        />
                      ) : (
                        <AgentNoImage name={name} surname={last_name} />
                      )}
                    </div>
                    <div className="name">
                      {name && last_name ? `${name} ${last_name}` : null}
                    </div>
                    <div className="desg">{position}</div>
                  </div>
                  <div className="info">
                    <span>Phone</span>
                    <div className="detail">{phone}</div>
                  </div>
                  <hr />
                  <div className="info">
                    <span>Email</span>
                    <div className="detail">{email}</div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="info">
                    <span>Website</span>
                    <div className="detail">{site_url || 'Not provided'}</div>
                  </div>
                  <hr />
                  <div className="info">
                    <span>Office Address</span>
                    <div className="detail">{address || 'Not provided'}</div>
                  </div>
                  <hr />
                  <div className="info">
                    <span>Description</span>
                    <div className="detail">
                      {userDescription} <p>See more</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 d-none">
            <div className="row">
              <div className="col-md-6">
                <div className="cardProperty">
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="1">
                        <path
                          d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                          fill="#07364B"
                        />
                      </g>
                    </svg>
                  </div>
                  <p>
                    Number of property for
                    <strong>last year</strong>
                  </p>
                  <div className="counter">123</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cardProperty">
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="1">
                        <path
                          d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                          fill="#07364B"
                        />
                      </g>
                    </svg>
                  </div>
                  <p>
                    Number of property for
                    <strong>last month</strong>
                  </p>
                  <div className="counter">2</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cardProperty">
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7998 10.9C9.52982 10.31 8.79982 9.7 8.79982 8.75C8.79982 7.66 9.80982 6.9 11.4998 6.9C13.2798 6.9 13.9398 7.75 13.9998 9H16.2098C16.1398 7.28 15.0898 5.7 12.9998 5.19V3H9.99982V5.16C8.05982 5.58 6.49982 6.84 6.49982 8.77C6.49982 11.08 8.40982 12.23 11.1998 12.9C13.6998 13.5 14.1998 14.38 14.1998 15.31C14.1998 16 13.7098 17.1 11.4998 17.1C9.43982 17.1 8.62982 16.18 8.51982 15H6.31982C6.43982 17.19 8.07982 18.42 9.99982 18.83V21H12.9998V18.85C14.9498 18.48 16.4998 17.35 16.4998 15.3C16.4998 12.46 14.0698 11.49 11.7998 10.9Z"
                        fill="#07364B"
                      />
                    </svg>
                  </div>
                  <p>
                    Amount of services for
                    <strong>last year</strong>
                  </p>
                  <div className="counter">$126,519.99</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cardProperty">
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7998 10.9C9.52982 10.31 8.79982 9.7 8.79982 8.75C8.79982 7.66 9.80982 6.9 11.4998 6.9C13.2798 6.9 13.9398 7.75 13.9998 9H16.2098C16.1398 7.28 15.0898 5.7 12.9998 5.19V3H9.99982V5.16C8.05982 5.58 6.49982 6.84 6.49982 8.77C6.49982 11.08 8.40982 12.23 11.1998 12.9C13.6998 13.5 14.1998 14.38 14.1998 15.31C14.1998 16 13.7098 17.1 11.4998 17.1C9.43982 17.1 8.62982 16.18 8.51982 15H6.31982C6.43982 17.19 8.07982 18.42 9.99982 18.83V21H12.9998V18.85C14.9498 18.48 16.4998 17.35 16.4998 15.3C16.4998 12.46 14.0698 11.49 11.7998 10.9Z"
                        fill="#07364B"
                      />
                    </svg>
                  </div>
                  <p>
                    Amount of services for
                    <strong>last month</strong>
                  </p>
                  <div className="counter">$1,214.90</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchBar
        title={'Listings'}
        action={getTourByAgent}
        currentPage={currentPage}
        id={id}
      />
      <div className="dashboardContent bg-white">
        {agentTours?.data?.map((tour, index) => (
          <MediaTour
            tourTemplate={tourTemplate}
            key={tour.id}
            tour={tour}
            types={types}
          />
        ))}
      </div>
      <div className="pagination-container">
        {!!agentTours.data?.length && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={agentTours.meta?.total}
            pageSize={agentTours.meta?.per_page}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}
