import { useRef } from 'react';

import { isDisplay } from 'helpers/checkDisplay';
import { useOnClickOutside } from 'hooks';

// styles
import './styles.scss';

export const MobileHeader = (props) => {
  const {
    open,
    headerSections,
    closeIcon,
    close,
    setActiveSection,
    headerShadow,
  } = props;
  const navRef = useRef(null);
  useOnClickOutside(navRef, close);

  const handleCloseAndSetActiveSection = (elem) => {
    setActiveSection(elem.id);
    close();
  };

  return (
    <nav
      ref={navRef}
      className={`mobile_header_container ${
        !open && 'mobile_header_container_closed'
      } ${headerShadow && 'header_shadow'}`}
    >
      <span onClick={close} className="navbar-toggle-icon navbar_close_icon">
        <img src={closeIcon} alt="" />
        CLOSE
      </span>
      <ul className="nav_ul">
        {headerSections?.map((elem, index) => {
          return (
            elem.value &&
            isDisplay(elem.display) && (
              <li
                className="nav_item"
                key={index}
                onClick={() => handleCloseAndSetActiveSection(elem)}
              >
                <a
                  className={`nav-link property_website_header_item`}
                  href={`#${elem.id.toLowerCase()}`}
                >
                  {elem.name}
                </a>
              </li>
            )
          );
        })}
      </ul>
    </nav>
  );
};
