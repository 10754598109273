export const filterQueryStringHelper = (filtersObj) => {
    let resQueryStr = '';
  
    filtersObj.statuses.forEach((filter, idx) => {
      resQueryStr += `&filters[statuses][${idx}]=${filter}`;
    });
  
    filtersObj.agents.forEach((agent, idx) => {
      resQueryStr += `&filters[agents][${idx}]=${agent}`;
    });
  
    return resQueryStr;
  };