const base_url = process.env.REACT_APP_BASE_URL;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiBaseUrl: base_url,
  imagesBaseUrl: `${base_url}/`,
  defaultLanguage: 'en',
  googleClientId:
    '774796358759-crmq4di0lp4762ncrm08i5q8igi6og6q.apps.googleusercontent.com',
  imageUrl: 'https://drive.google.com/uc?id=',
  //TODO - must be removed after a while
  tempUrl: 'https://' + window.location.host + '/',
  stagingUrl: `${base_url}/`,
  google_map_api_key: 'AIzaSyBRKXhvI9V9ekPxA5poYrZUZMdKqAMzNh0',
};
