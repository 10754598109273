import React from 'react';
import { Link } from 'react-router-dom';

import { MediaTour, NavigationControl } from 'components';

// Images
import SearchIcon from 'assets/images/search.svg';
import FilterList from 'assets/images/filter-list.svg';
import Avatar from 'assets/images/avatar.png';
import ImageIcon from 'assets/images/Image.png';

const tour = {
  name: 'Julia Drobinina',
  avatar: Avatar,
  img: ImageIcon,
  address: '489 Clinton Street, Toronto, ON, Canada',
  registerDate: '30 June 2022, 10:00 AM',
  orderDate: '13 July 2022 9:02 PM',
};

export function Analytics() {
  return (
    <div className="content">
      <NavigationControl title="Select your tour" />
      <div className="controlBar">
        <ul className="filterLinks">
          <li>
            <Link to="#" className="cta">
              Filter listings
              <i>
                <img src={FilterList} alt="filter icon" />
              </i>
            </Link>
          </li>
          <li>
            <Link to="#" className="cta">
              Sort listings
              <i>
                <img src={FilterList} alt="sort icon" />
              </i>
            </Link>
          </li>
        </ul>
        <div className="searchField">
          <input
            type="text"
            className="form-control"
            placeholder="Try ‘Property Address’"
          />
          <i>
            <img src={SearchIcon} alt="search" />
          </i>
        </div>
      </div>
      <div className="dashboardMain">
        {new Array(10).fill(null).map((_, index) => (
          <MediaTour isAnalytics key={index} tour={tour} />
        ))}
      </div>
    </div>
  );
}
