import React from 'react';

export default function Select({
  id,
  value,
  name,
  placeholder,
  error,
  helperText,
  customClasses,
  label = '',
  type = 'text',
  options = [],
  onChange = () => {},
  required = false,
  hideLabel = false,
}) {
  return (
    <div className={`form-group ${customClasses}`}>
      {!hideLabel && (
        <label>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <select
        id={id}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
        placeholder={placeholder}
        className="form-select"
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options?.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error && <span className="error-text">{helperText}</span>}
    </div>
  );
}
