export const InputDropdown = (props) => {
  const { tours, handleSelectExistingTour } = props;

  return (
    <>
      {!!tours?.length && (
        <div className="input_dropdown_container">
          {tours.map((tour) => {
            return (
              <div
                onClick={() => handleSelectExistingTour(tour)}
                className="input_dropdown_item"
                key={tour.id}
              >
                {`${tour.address}${
                  tour.unit_number ? ', ' + tour.unit_number : ''
                }`}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
