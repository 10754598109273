import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import {
  deleteAgent,
  getAllHighlights,
  getPropertyWebsiteData,
  saveOrUpdateAgentInfo,
  saveOrUpdateHighlights,
  saveOrUpdateHouseDater,
  saveOrUpdateNeighborhood,
  saveOrUpdatePresentation,
  saveOrUpdateTourDetails,
  updateSalePrice,
} from './actions';
import { numberWithCommas } from 'helpers/numberWithCommas';
import { isValidDate, toDateString } from 'helpers/momentHelpers';
import { Toastify } from 'hooks';
import { types, CONTROL_BAR_PAGES } from 'constants';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import { MAIN_COMPANY } from 'constants/mainCompanyInfo';
import { checkIsPassedTime } from 'helpers/checkIsDatePassed';

import { ControlBar, NavigationControl } from 'components';
import { CustomDatePickerInput } from 'components/custom-date-picker-input';

// Images
import Check from 'assets/images/check.svg';
import Calendar from 'assets/images/calendar-today.svg';
import UploadCloud from 'assets/images/upload-cloud.svg';
import MinusIcon from 'assets/images/minus.svg';
import { PROPERTY_WEBSITE } from 'constants/propertyWebsite';

const base_url = process.env.REACT_APP_IMAGE_BASE_URL;
const idEquivalenceCheckerCallback = (item, id) =>
  item.id === id || item._id === id;
const allowedHighlightsLength = 6;

export function PropertyWebsite() {
  const websiteData = useSelector((state) => state.website.websiteData);
  const allHighlights = useSelector((state) => state.website.allHighlights);
  const tourData = useSelector((state) => state.tours.tourDetails);

  const [housedaterState, setHousedaterState] = useState({
    alternate_text: websiteData?.housedater?.alternate_text || '',
    display: websiteData?.housedater?.display || '',
    housedaterlist: websiteData?.housedater?.housedaterlist || [
      { start: null, end: null },
    ],
  });
  const [presentation, setPresentation] = useState(websiteData?.presentation);
  const [isSalePriceReadOnly, setIsSalePriceReadOnly] = useState(true);
  const [addingSalePrice, setAddingSalePrice] = useState(
    websiteData?.sale_price || null
  );
  const [activeHighlightsIds, setActiveHighlightsIds] = useState([]);
  const [highlightsVals, setHighlightsVals] = useState({});
  const [neighborhood, setNeighborhood] = useState({});
  const [neighborhoodHighlights, setNeighborhoodHighlights] = useState([]);
  const [tourDetails, setTourDetails] = useState({});
  const [tourDetailsListHighlights, setTourDetailsListHighlights] = useState(
    []
  );
  const [presentationDate, setPresentationDate] = useState(
    presentation?.presentation_date || null
  );
  const [presentationText, setPresentationText] = useState(
    presentation?.alternate_text || ''
  );
  const [agentInfo, setAgentInfo] = useState({});
  const [agentsInfos, setAgentsInfos] = useState([]);
  const [addingImageUrlObj, setAddingImageUrlObj] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [changedHighlights, setChangedHighlights] = useState([]);

  const { state } = useLocation();

  const params = useParams();

  const salePriceInputRef = useRef(null);

  const dispatch = useDispatch();

  const savedSuccessFullyToaster = () =>
    Toastify({
      type: TOAST_TYPES.success,
      message: TOAST_MESSAGES.savedChanges,
    });

  useEffect(() => {
    dispatch(getPropertyWebsiteData({ tourId: params?.id }));
    dispatch(getAllHighlights());
  }, [params?.id]);

  useEffect(() => {
    if (websiteData?.housedater?.housedaterlist?.length) {
      setHousedaterState(websiteData?.housedater);
    } else {
      setHousedaterState({
        ...websiteData?.housedater,
        housedaterlist: [{ start: null, end: null }],
      });
    }
    setAddingSalePrice(websiteData?.sale_price);
    setPresentation(websiteData?.presentation);
    const defHighlights = {};
    websiteData?.hightlights?.forEach((high) => {
      defHighlights[high.id] = {
        detail: high.pivot.detail,
      };
    });
    setHighlightsVals(defHighlights);
    setPresentationText(websiteData?.presentation?.alternate_text);
    setPresentationDate(websiteData?.presentation?.presentation_date);
    setTourDetails(websiteData?.tourdetails);
    setTourDetailsListHighlights(websiteData?.tourdetails?.tourdetailslist);
    setNeighborhood(websiteData?.neighborhood);
    setNeighborhoodHighlights(websiteData?.neighborhood?.neighborhoodlist);
    setAgentInfo(websiteData?.agentinfo);
    setAgentsInfos(websiteData?.agentinfos);
  }, [websiteData]);

  useEffect(() => {
    if (!housedaterState?.housedaterlist?.length) {
      setHousedaterState({
        ...housedaterState,
        housedaterlist: [{ start: null, end: null }],
      });
    }
  }, [housedaterState]);

  const handleSaveHouseDater = () => {
    const formattedHousedaterlist = housedaterState.housedaterlist.map(
      (obj) => {
        return {
          start: moment(obj.start).format('YYYY-MM-D hh:mm:ss'),
          end: obj.end,
        };
      }
    );
    if (formattedHousedaterlist.length === 0) {
      formattedHousedaterlist.push({ start: null, end: null });
    }
    dispatch(
      saveOrUpdateHouseDater({
        tourId: params?.id,
        params: {
          display: housedaterState.display,
          alternate_text: housedaterState.alternate_text,
          dates: formattedHousedaterlist,
        },
      })
    ).then(() => savedSuccessFullyToaster());
  };

  const handleHousedaterChange = (e) => {
    setHousedaterState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const housedaterStartDate = (date, idx) => {
    const formattedDate = moment(date).format('D MMMM YYYY hh:mm A');
    const resDates = [...housedaterState?.housedaterlist];
    resDates.splice(idx, 1, { ...resDates[idx], start: formattedDate });
    setHousedaterState((prev) => ({
      ...prev,
      housedaterlist: resDates,
    }));
  };

  const housedaterEndDate = (date, idx) => {
    const formattedDate = moment(date).format('hh:mm A');
    const resDates = [...housedaterState?.housedaterlist];
    resDates.splice(idx, 1, { ...resDates[idx], end: formattedDate });
    setHousedaterState((prev) => ({
      ...prev,
      housedaterlist: resDates,
    }));
  };

  const handleAddNewDate = () => {
    if (housedaterState?.housedaterlist?.length < 4) {
      const resDates = [
        ...housedaterState?.housedaterlist,
        { start: null, end: null },
      ];
      setHousedaterState((prev) => ({ ...prev, housedaterlist: resDates }));
    }
  };

  const handleDeleteHousedater = (idx) => {
    const resDates = [
      ...housedaterState?.housedaterlist?.slice(0, idx),
      ...housedaterState?.housedaterlist?.slice(idx + 1),
    ];
    setHousedaterState((prev) => ({ ...prev, housedaterlist: resDates }));
  };

  const handleToggleDisplay = () => {
    dispatch(
      saveOrUpdateHouseDater({
        tourId: params?.id,
        params: {
          ...housedaterState,
          display:
            housedaterState?.display === 'display' ? 'alternate' : 'display',
        },
      })
    );
  };

  const handleClickEditSalePrice = () => {
    setIsSalePriceReadOnly(false);
    salePriceInputRef.current.focus();
  };

  const handleSaveSalePrice = () => {
    setIsSalePriceReadOnly(true);
    dispatch(
      updateSalePrice({
        tourId: params?.id,
        params: { sale_price: Number(addingSalePrice) },
      })
    ).then(() => savedSuccessFullyToaster());
  };

  const handleChangeSalePrice = (e) => {
    setAddingSalePrice(e.target.value);
  };

  const handleToggleActiveHighlight = (id) => {
    const isActive = activeHighlightsIds.includes(id);
    if (isActive) {
      const resHighlightsIds = activeHighlightsIds.filter(
        (actId) => actId !== id
      );
      setActiveHighlightsIds(resHighlightsIds);
    } else {
      const resHighlightsIds = [...activeHighlightsIds, id];
      setActiveHighlightsIds(resHighlightsIds);
    }
  };

  const handleHighLightChange = (e, id) => {
    if (e.target.checked) {
      handleDisplayHighlight(id);
    } else {
      handleHideHighlight(id);
    }
  };

  const handleChangeHighlight = (e, id) => {
    const valsObj = {
      ...highlightsVals,
      [id]: { detail: e.target.value },
    };
    setHighlightsVals(valsObj);
  };

  const handleSavePropertyHighlights = () => {
    dispatch(
      saveOrUpdateHighlights({
        tourId: params?.id,
        params: { highlights: highlightsVals },
      })
    ).then(() => {
      setActiveHighlightsIds([]);
      savedSuccessFullyToaster();
    });
  };

  const updateHighlights = ({ id, displayedHighlights }) => {
    if (
      changedHighlights.length === allowedHighlightsLength &&
      !changedHighlights.includes(id)
    ) {
      return Toastify({
        type: TOAST_TYPES.error,
        message: TOAST_MESSAGES.highlightsMessage,
      });
    }

    setChangedHighlights((prev) => {
      if (prev.includes(id)) return prev.filter((value) => value !== id);
      return [...prev, id];
    });

    setHighlightsVals(displayedHighlights);
  };

  const handleDisplayHighlight = (id) => {
    const displayedHighlights = { ...highlightsVals, [id]: id };
    updateHighlights({ id, displayedHighlights });
  };

  const handleHideHighlight = (id) => {
    const displayedHighlights = { ...highlightsVals };
    delete displayedHighlights[id];
    updateHighlights({ id, displayedHighlights });
  };

  const handleChangePresentationDate = (date) => {
    setPresentationDate(date);
  };

  const handleTogglePresentationDisplay = () => {
    if (presentation?.display === 'display') {
      dispatch(
        saveOrUpdatePresentation({
          tourId: params?.id,
          params: {
            ...presentation,
            display: 'alternate',
            alternate_text: presentation?.alternate_text || null,
            presentation_date: presentation?.presentation_date || null,
          },
        })
      );
    } else {
      dispatch(
        saveOrUpdatePresentation({
          tourId: params?.id,
          params: {
            ...presentation,
            display: 'display',
            alternate_text: presentation?.alternate_text || null,
            presentation_date: presentation?.presentation_date || null,
          },
        })
      );
    }
  };

  const handleSavePresentation = () => {
    dispatch(
      saveOrUpdatePresentation({
        tourId: params?.id,
        params: {
          ...presentation,
          display: 'display',
          presentation_date:
            moment(presentationDate).format('YYYY-MM-D hh:mm:ss'),
          alternate_text: presentationText,
        },
      })
    ).then(() => savedSuccessFullyToaster());
  };

  const handleToggleDetailsDisplay = () => {
    if (tourDetails?.display === 'display') {
      dispatch(
        saveOrUpdateTourDetails({
          tourId: params?.id,
          params: { ...tourDetails, display: 'hide' },
        })
      );
    } else {
      dispatch(
        saveOrUpdateTourDetails({
          tourId: params?.id,
          params: { ...tourDetails, display: 'display' },
        })
      );
    }
  };

  const handleChangeDetailsDescription = (e) => {
    setTourDetails({ ...tourDetails, description: e.target.value });
  };

  const handleAddDetailsHighlight = () => {
    setTourDetailsListHighlights([
      ...tourDetailsListHighlights,
      { id: Date.now(), highlight: '' },
    ]);
  };

  const handleDeleteDetailsHighlight = (id) => {
    const resHighlights = tourDetailsListHighlights.filter(
      (high) => high.id !== id
    );
    setTourDetailsListHighlights(resHighlights);
  };

  const handleChangeDetailsHighlight = (e, id) => {
    const addingHighlightIndex = tourDetailsListHighlights.findIndex(
      (highlight) => highlight.id === id
    );
    const resTourDetailsHighlights = [
      ...tourDetailsListHighlights.slice(0, addingHighlightIndex),
      { id: id, highlight: e.target.value },
      ...tourDetailsListHighlights.slice(addingHighlightIndex + 1),
    ];
    setTourDetailsListHighlights(resTourDetailsHighlights);
  };

  const handleSaveTourDetails = () => {
    const tourDetailsHighlightsList = tourDetailsListHighlights?.length
      ? tourDetailsListHighlights
      : { highlight: null };
    dispatch(
      saveOrUpdateTourDetails({
        tourId: params?.id,
        params: { ...tourDetails, highlights: tourDetailsHighlightsList },
      })
    ).then(() => savedSuccessFullyToaster());
  };

  const handleToggleNeighborhoodDisplay = () => {
    if (neighborhood?.display === 'display') {
      dispatch(
        saveOrUpdateNeighborhood({
          tourId: params?.id,
          params: { ...neighborhood, display: 'hide' },
        })
      );
    } else {
      dispatch(
        saveOrUpdateNeighborhood({
          tourId: params?.id,
          params: { ...neighborhood, display: 'display' },
        })
      );
    }
  };

  const handleChangeNeighborhoodDescription = (e) => {
    setNeighborhood({ ...neighborhood, description: e.target.value });
  };

  const handleChangeNeighborhoodHighlight = (e, id) => {
    const addingHighlightIndex = neighborhoodHighlights.findIndex(
      (highlight) => highlight.id === id
    );
    const resNeighborhoodHighlights = [
      ...neighborhoodHighlights.slice(0, addingHighlightIndex),
      { id: id, highlight: e.target.value },
      ...neighborhoodHighlights.slice(addingHighlightIndex + 1),
    ];
    setNeighborhoodHighlights(resNeighborhoodHighlights);
  };

  const handleAddNeighborhoodHighlight = () => {
    setNeighborhoodHighlights([
      ...neighborhoodHighlights,
      { id: Date.now(), highlight: '' },
    ]);
  };

  const handleDeleteNeighborhoodHighlight = (id) => {
    const resHighlights = neighborhoodHighlights.filter(
      (high) => high.id !== id
    );
    setNeighborhoodHighlights(resHighlights);
  };

  const handleSaveNeighborhood = () => {
    const neighborhoodHighlightsList = neighborhoodHighlights?.length
      ? neighborhoodHighlights
      : { highlight: null };
    dispatch(
      saveOrUpdateNeighborhood({
        tourId: params?.id,
        params: { ...neighborhood, highlights: neighborhoodHighlightsList },
      })
    ).then(() => savedSuccessFullyToaster());
  };

  const handleChangeAgentInput = ({ e, id }) => {
    const changingAgentIndex = agentsInfos.findIndex((info) =>
      idEquivalenceCheckerCallback(info, id)
    );
    const changingAgent = agentsInfos.find((info) =>
      idEquivalenceCheckerCallback(info, id)
    );
    const resAgentsInfos = [
      ...agentsInfos.slice(0, changingAgentIndex),
      { ...changingAgent, [e.target.name]: e.target.value },
      ...agentsInfos.slice(changingAgentIndex + 1),
    ];
    setAgentsInfos(resAgentsInfos);
  };

  const handleAddAgent = () => {
    setAgentsInfos([
      ...agentsInfos,
      {
        _id: Date.now(),
        display: 'display',
        address: MAIN_COMPANY.address,
        website: MAIN_COMPANY.website,
      },
    ]);
  };

  const handleDeleteAgent = ({ realId, localId }) => {
    if (!!realId) {
      dispatch(deleteAgent({ agentId: realId, tourId: params?.id }));
    } else {
      const resAgentsInfos = agentsInfos.filter(
        (agent) => agent._id !== localId
      );
      setAgentsInfos(resAgentsInfos);
    }
  };

  const handleChangeAvatar = ({ e, id }) => {
    const changingAgentIndex = agentsInfos.findIndex((info) =>
      idEquivalenceCheckerCallback(info, id)
    );
    const changingAgent = agentsInfos.find((info) =>
      idEquivalenceCheckerCallback(info, id)
    );
    const fd = new FormData();
    fd.append('photo', e.target.files[0], e.target.files[0].name);
    const addingUrl = URL.createObjectURL(e.target.files[0]);
    setAddingImageUrlObj({ ...addingImageUrlObj, [id]: addingUrl });
    const resAgentsInfos = [
      ...agentsInfos.slice(0, changingAgentIndex),
      { ...changingAgent, photo: fd.get('photo') },
      ...agentsInfos.slice(changingAgentIndex + 1),
    ];
    setAgentsInfos(resAgentsInfos);
  };

  const handleClickOnChooseImg = ({ id }) => {
    document.getElementById(id).click();
  };

  const handleToggleAgentInfoDisplay = () => {
    const form_data = new FormData();
    for (let key in agentInfo) {
      if (key === 'display') {
        if (agentInfo[key] === 'display') {
          form_data.append('display', 'hide');
        } else {
          form_data.append('display', 'display');
        }
      } else {
        form_data.append(key, agentInfo[key] || '');
        if (key === 'id') {
          form_data.append('agent_info_id', agentInfo[key]);
        }
      }
    }
    if (!agentInfo) {
      form_data.append('display', 'display');
    }
    dispatch(
      saveOrUpdateAgentInfo({
        tourId: params?.id,
        params: form_data,
        customHeaders: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
    );
  };

  const handleSaveAgentsInfos = () => {
    let savedAgentsCount = 0;
    agentsInfos.forEach((agent) => {
      const form_data = new FormData();
      for (let key in agent) {
        form_data.append(key, agent[key] || '');
        if (key === 'id') {
          form_data.append('agent_info_id', agent[key]);
        }
      }
      dispatch(
        saveOrUpdateAgentInfo({
          tourId: params?.id,
          params: form_data,
          customHeaders: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        })
      ).then(() => {
        savedAgentsCount += 1;
        if (savedAgentsCount === agentsInfos.length) {
          savedSuccessFullyToaster();
        }
      });
    });
  };

  return (
    <div className="content">
      <NavigationControl
        isStatus
        isBack
        tourStatus={websiteData?.status}
        statuses={types}
        title={websiteData?.address || ''}
        noButtons={true}
        childNumber={tourData.tour_child_numbers}
      />
      <ControlBar
        params={params}
        state={state}
        pageName={CONTROL_BAR_PAGES.propertyWebsite}
      />
      <div className="dashboardMain">
        <div className="card">
          <h3 className="heading3">{PROPERTY_WEBSITE.property_website}</h3>
          <div className="row">
            <div className="col-xxl-7">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card-detail">
                    <h3>{PROPERTY_WEBSITE.property_sale_price}</h3>
                    {PROPERTY_WEBSITE.dollar_symbol}
                    <input
                      name="sale_price"
                      ref={salePriceInputRef}
                      className={`${
                        isSalePriceReadOnly ? 'readOnly-input' : 'sale_price'
                      }`}
                      readOnly={isSalePriceReadOnly}
                      value={`${
                        isSalePriceReadOnly
                          ? numberWithCommas(addingSalePrice || '')
                          : Number(addingSalePrice)
                      }`}
                      onChange={handleChangeSalePrice}
                    />
                    <p>
                      <i>{PROPERTY_WEBSITE.price_example}</i>
                    </p>
                    {isSalePriceReadOnly ? (
                      <button
                        className="edit-btn"
                        onClick={handleClickEditSalePrice}
                      >
                        {PROPERTY_WEBSITE.edit}
                      </button>
                    ) : (
                      <button
                        className="edit-btn"
                        onClick={handleSaveSalePrice}
                      >
                        {PROPERTY_WEBSITE.save}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="cardWebsite">
                <div className="headerTitle">
                  <h4 className="heading4">{PROPERTY_WEBSITE.property_highlights}</h4>
                  <button
                    onClick={() => handleSavePropertyHighlights()}
                    className="ms-auto ctaSave"
                  >
                    {PROPERTY_WEBSITE.save}
                  </button>
                </div>
                <div className="cardBody">
                  <div className="propertyHighlights">
                    <div className="row">
                      {allHighlights &&
                        allHighlights.map((highlight) => {
                          const isActive = !!highlightsVals[highlight.id];
                          return (
                            <div key={highlight.id} className="col-lg-4">
                              <div
                                className={`ctaHighlight ${
                                  isActive ? 'active' : ''
                                }`}
                              >
                                <div className="highlight_first_row">
                                  <div className="highlight-title">
                                    {highlight.title}
                                  </div>
                                  <div
                                    onClick={(e) => e.stopPropagation()}
                                    className="display-toggle"
                                  >
                                    <form
                                      className={`${
                                        isActive && 'active-toggle-form'
                                      }`}
                                    >
                                      <label
                                        className="switch"
                                        htmlFor={highlight.id}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className=""
                                          id={highlight.id}
                                          checked={highlightsVals.hasOwnProperty(
                                            highlight.id
                                          )}
                                          onChange={(e) =>
                                            handleHighLightChange(
                                              e,
                                              highlight.id
                                            )
                                          }
                                        />
                                        <span
                                          className={`slider ${
                                            highlightsVals.hasOwnProperty(
                                              highlight.id
                                            ) && 'slider_enabled'
                                          }`}
                                        ></span>
                                      </label>
                                    </form>
                                  </div>
                                </div>
                                <input
                                  name={highlight.title}
                                  onClick={(e) => e.stopPropagation()}
                                  className="cardBody"
                                  value={
                                    highlightsVals[highlight.id]?.detail || ''
                                  }
                                  onChange={(e) =>
                                    handleChangeHighlight(e, highlight.id)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardWebsite">
                <div className="headerTitle">
                  <h4 className="heading4">
                    {PROPERTY_WEBSITE.open_house_dates}
                    <button
                      onClick={handleToggleDisplay}
                      className={`${
                        housedaterState?.display === 'display' && 'active'
                      }`}
                    >
                      <img src={Check} alt="check" />
                    </button>
                  </h4>
                  {housedaterState?.display === 'display' && (
                    <button
                      onClick={handleAddNewDate}
                      className="btn cta-outline-primary add_btn"
                    >
                      {PROPERTY_WEBSITE.add_new_date}
                    </button>
                  )}
                </div>
                <div className="cardBody">
                  {housedaterState?.display === 'display' && (
                    <div className="row">
                      <div className="col-lg-12">
                        {!!housedaterState?.housedaterlist?.length &&
                          housedaterState?.housedaterlist?.map(
                            (rangeObj, idx) => {
                              const start =
                                rangeObj?.start === null
                                  ? null
                                  : toDateString(rangeObj.start);
                              const end =
                                rangeObj?.end === null
                                  ? null
                                  : toDateString(
                                      rangeObj.start
                                        ?.split(' ')
                                        ?.slice(0, 3)
                                        ?.join(' ') +
                                        ' ' +
                                        rangeObj.end
                                    );
                              return (
                                <div key={idx}>
                                  <div className="d-flex">
                                    <h4 className="headingDate">
                                      {PROPERTY_WEBSITE.date} {idx + 1}
                                    </h4>
                                    {housedaterState?.housedaterlist?.length >=
                                      1 &&
                                      housedaterState?.housedaterlist?.[0]
                                        ?.start && (
                                        <div
                                          className="deleteDate"
                                          onClick={() =>
                                            handleDeleteHousedater(idx)
                                          }
                                        />
                                      )}
                                  </div>
                                  <div
                                    className="date-form-group-container d-flex flex-xs-column"
                                    key={idx}
                                  >
                                    <div className="form-group date-form start">
                                      <DatePicker
                                        selected={start}
                                        onChange={(date) =>
                                          housedaterStartDate(date, idx)
                                        }
                                        showTimeSelect
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        timeInputLabel="time"
                                        dateFormat="E, d/MM/yyyy h:mm aa"
                                        minDate={new Date()}
                                        filterTime={(date) =>
                                          checkIsPassedTime(date)
                                        }
                                        customInput={
                                          <CustomDatePickerInput
                                            labelText={PROPERTY_WEBSITE.start_date_time}
                                            inputIcon={Calendar}
                                            paddingClass="form_padding"
                                          />
                                        }
                                      />
                                    </div>
                                    <div className="form-group date-form ms-lg-5 ms-sm-0 ">
                                      <DatePicker
                                        selected={end}
                                        onChange={(date) =>
                                          housedaterEndDate(date, idx)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={PROPERTY_WEBSITE.time}
                                        dateFormat="h:mm aa"
                                        minDate={new Date()}
                                        filterTime={(date) =>
                                          checkIsPassedTime(date)
                                        }
                                        customInput={
                                          <CustomDatePickerInput
                                            labelText={PROPERTY_WEBSITE.end_time}
                                            inputIcon={Calendar}
                                            placeholderText={PROPERTY_WEBSITE.end_time_placeholder}
                                            withDelete={true}
                                            onDelete={handleDeleteHousedater}
                                            idx={idx}
                                            isDisable={
                                              housedaterState?.housedaterlist?.[
                                                idx
                                              ].start === null
                                            }
                                            showDelete={
                                              housedaterState?.housedaterlist
                                                ?.length === 1 &&
                                              housedaterState?.housedaterlist[0]
                                                .start === null
                                            }
                                          />
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="labelHeader">
                            <label htmlFor="">{PROPERTY_WEBSITE.alternate_text}</label>{' '}
                            <button
                              onClick={() => handleSaveHouseDater()}
                              className="ms-auto ctaSave"
                            >
                              {PROPERTY_WEBSITE.save}
                            </button>
                          </div>

                          <textarea
                            name="alternate_text"
                            id=""
                            cols="30"
                            rows="5"
                            className="form-control"
                            value={housedaterState?.alternate_text || ''}
                            onChange={handleHousedaterChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="cardWebsite">
                <div className="headerTitle">
                  <h4 className="heading4">
                    {PROPERTY_WEBSITE.offer_presentation_date}
                    <button
                      onClick={handleTogglePresentationDisplay}
                      className={`${
                        presentation?.display === 'display' && 'active'
                      }`}
                    >
                      <img src={Check} alt="check" />
                    </button>
                  </h4>
                </div>
                {presentation?.display === PROPERTY_WEBSITE.display && (
                  <div className="form-group presentation-container">
                    <DatePicker
                      selected={
                        !!presentationDate
                          ? new Date(presentationDate)
                          : isValidDate(
                              toDateString(
                                presentation?.presentation_date || null
                              )
                            )
                          ? toDateString(
                              presentation?.presentation_date || null
                            )
                          : null
                      }
                      onChange={(date) => handleChangePresentationDate(date)}
                      showTimeSelect
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      timeInputLabel="time"
                      dateFormat="d MMMM yyyy h:mm a"
                      minDate={new Date()}
                      filterTime={(date) => checkIsPassedTime(date)}
                      customInput={
                        <CustomDatePickerInput
                          labelText={
                            <div className="datepicker-label-container">
                              {PROPERTY_WEBSITE.offer_presentation_date}{' '}
                              <button
                                onClick={handleSavePresentation}
                                className="ms-auto ctaSave"
                              >
                                {PROPERTY_WEBSITE.save}
                              </button>
                            </div>
                          }
                          inputIcon={Calendar}
                        />
                      }
                    />
                    <div className="labelHeader">
                      <label htmlFor="">{PROPERTY_WEBSITE.alternate_text}</label>{' '}
                    </div>
                    <textarea
                      name="presentationText"
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                      value={
                        presentationText || presentation?.alternate_text || ''
                      }
                      onChange={(e) => setPresentationText(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="cardWebsite">
                <div className="headerTitle">
                  <h4 className="heading4">
                    {PROPERTY_WEBSITE.property_details}
                    <button
                      onClick={handleToggleDetailsDisplay}
                      className={`${
                        tourDetails?.display === 'display' && 'active'
                      }`}
                    >
                      <img src={Check} alt="check" />
                    </button>
                  </h4>
                </div>
                {tourDetails?.display === PROPERTY_WEBSITE.display && (
                  <div className="form-group">
                    <div className="labelHeader">
                      <label htmlFor="">{PROPERTY_WEBSITE.property_description}</label>{' '}
                      <button
                        onClick={handleSaveTourDetails}
                        className="ms-auto ctaSave"
                      >
                        {PROPERTY_WEBSITE.save}
                      </button>
                    </div>

                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                      onChange={handleChangeDetailsDescription}
                      defaultValue={tourDetails?.description || ''}
                    />
                  </div>
                )}
              </div>
              {tourDetails?.display === 'display' && (
                <div className="cardWebsite">
                  <div className="headerTitle">
                    <h4 className="heading4">{PROPERTY_WEBSITE.highlights}</h4>
                    <button
                      onClick={handleAddDetailsHighlight}
                      className="btn cta-outline-primary add_btn"
                    >
                      {PROPERTY_WEBSITE.add_highlight}
                    </button>
                  </div>

                  <div className="cardBody">
                    <div className="row">
                      {tourDetailsListHighlights.map((detail, i) => (
                        <div key={detail?.id} className="col-lg-6">
                          <div className="form-group highlight-item-container">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${PROPERTY_WEBSITE.highlight} ${i + 1}`}
                              value={detail.highlight}
                              onChange={(e) =>
                                handleChangeDetailsHighlight(e, detail.id)
                              }
                            />
                            <button
                              onClick={() =>
                                handleDeleteDetailsHighlight(detail?.id)
                              }
                              className="cta-remove"
                            >
                              <img src={MinusIcon} alt="remove" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="cardWebsite">
                <div className="headerTitle">
                  <h4 className="heading4">
                    {PROPERTY_WEBSITE.neighborhood_info}
                    <button
                      onClick={handleToggleNeighborhoodDisplay}
                      className={`${
                        neighborhood?.display === 'display' && 'active'
                      }`}
                    >
                      <img src={Check} alt="check" />
                    </button>
                  </h4>
                </div>
                {neighborhood?.display === PROPERTY_WEBSITE.display && (
                  <div className="form-group">
                    <div className="labelHeader">
                      <label htmlFor="">{PROPERTY_WEBSITE.property_description}</label>{' '}
                      <button
                        onClick={handleSaveNeighborhood}
                        className="ms-auto ctaSave"
                      >
                        {PROPERTY_WEBSITE.save}
                      </button>
                    </div>

                    <textarea
                      name="neighborhood"
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control"
                      value={neighborhood?.description || ''}
                      onChange={handleChangeNeighborhoodDescription}
                    />
                  </div>
                )}
              </div>
              {neighborhood?.display === PROPERTY_WEBSITE.display && (
                <div className="cardWebsite">
                  <div className="headerTitle">
                    <h4 className="heading4">{PROPERTY_WEBSITE.highlights}</h4>
                    <button
                      onClick={handleAddNeighborhoodHighlight}
                      className="btn cta-outline-primary add_btn"
                    >
                      {PROPERTY_WEBSITE.add_highlight}
                    </button>
                  </div>

                  <div className="cardBody">
                    <div className="row">
                      {neighborhoodHighlights?.map((detail, i) => {
                        return (
                          <div key={detail?.id} className="col-lg-6">
                            <div className="form-group highlight-item-container">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`${PROPERTY_WEBSITE.highlight} ${i + 1}`}
                                value={detail.highlight}
                                onChange={(e) =>
                                  handleChangeNeighborhoodHighlight(
                                    e,
                                    detail.id
                                  )
                                }
                              />
                              <button
                                onClick={() =>
                                  handleDeleteNeighborhoodHighlight(detail.id)
                                }
                                className="cta-remove"
                              >
                                <img src={MinusIcon} alt="remove" />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              <div className="cardWebsite">
                <div className="headerTitle">
                  <div className="headerTitle-first-row">
                    <h4 className="heading4">
                      {PROPERTY_WEBSITE.agent_info}
                      <button
                        onClick={handleToggleAgentInfoDisplay}
                        className={`${
                          agentInfo?.display === 'display' && 'active'
                        }`}
                      >
                        <img src={Check} alt="check" />
                      </button>
                    </h4>
                    {agentInfo?.display === PROPERTY_WEBSITE.display && (
                      <button
                        onClick={() => handleAddAgent()}
                        className="btn cta-outline-primary add_btn"
                      >
                        {PROPERTY_WEBSITE.add_agent}
                      </button>
                    )}
                  </div>
                </div>
                {agentInfo?.display === PROPERTY_WEBSITE.display && (
                  <>
                    <div className="head-buttons-container">
                      <button
                        onClick={() => handleSaveAgentsInfos()}
                        className="ms-auto ctaSave"
                      >
                        {PROPERTY_WEBSITE.save}
                      </button>
                    </div>
                    <div className="cardBody">
                      {agentsInfos?.map((info) => {
                        return (
                          <div key={info.id || info._id} className="agentCard">
                            {agentsInfos?.length > 1 && (
                              <button
                                onClick={() =>
                                  handleDeleteAgent({
                                    realId: info.id,
                                    localId: info._id,
                                  })
                                }
                                className="cta-remove"
                              >
                                <img src={MinusIcon} alt="remove" />
                              </button>
                            )}
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="avatar">
                                  <input
                                    hidden
                                    id={info.id || info._id}
                                    type="file"
                                    onChange={(e) =>
                                      handleChangeAvatar({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                  {addingImageUrlObj[info.id || info._id] ||
                                  info.photo ? (
                                    <img
                                      src={
                                        addingImageUrlObj[
                                          info.id || info._id
                                        ] || `${base_url + info?.photo}`
                                      }
                                      alt="avatar"
                                      title={PROPERTY_WEBSITE.click_upload_image}
                                      className="w-100 user-avatar-img"
                                      onClick={() =>
                                        handleClickOnChooseImg({
                                          id: info.id || info._id,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div
                                      onClick={() =>
                                        handleClickOnChooseImg({
                                          id: info.id || info._id,
                                        })
                                      }
                                      className="uploadImg"
                                    >
                                      <label htmlFor="file" className="upload">
                                        <i>
                                          <img
                                            src={UploadCloud}
                                            alt="upload"
                                            className="w-100 user-avatar-img"
                                          />
                                        </i>
                                        <span>{PROPERTY_WEBSITE.upload_image}</span>
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label htmlFor="">{PROPERTY_WEBSITE.first_name}</label>
                                      <input
                                        type="text"
                                        name="first_name"
                                        className="form-control"
                                        placeholder={PROPERTY_WEBSITE.first_name}
                                        value={info?.first_name || ''}
                                        onChange={(e) =>
                                          handleChangeAgentInput({
                                            e,
                                            id: info.id || info._id,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label htmlFor="">{PROPERTY_WEBSITE.last_name}</label>
                                      <input
                                        type="text"
                                        name="last_name"
                                        className="form-control"
                                        placeholder={PROPERTY_WEBSITE.last_name}
                                        value={info?.last_name || ''}
                                        onChange={(e) =>
                                          handleChangeAgentInput({
                                            e,
                                            id: info.id || info._id,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        {PROPERTY_WEBSITE.short_Description}
                                      </label>
                                      <textarea
                                        name="description"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        className="form-control"
                                        placeholder={PROPERTY_WEBSITE.agent_description}
                                        value={info?.description || ''}
                                        onChange={(e) =>
                                          handleChangeAgentInput({
                                            e,
                                            id: info.id || info._id,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label htmlFor="">{PROPERTY_WEBSITE.address}</label>
                                  <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    placeholder="Enter address"
                                    value={info?.address || ''}
                                    onChange={(e) =>
                                      handleChangeAgentInput({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label htmlFor="">{PROPERTY_WEBSITE.phone}</label>
                                  <input
                                    type="text"
                                    name="telephone"
                                    className="form-control"
                                    placeholder={PROPERTY_WEBSITE.enter_phone_number}
                                    value={info?.telephone || ''}
                                    onChange={(e) =>
                                      handleChangeAgentInput({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label htmlFor="">{PROPERTY_WEBSITE.website}</label>
                                  <input
                                    type="text"
                                    name="website"
                                    className="form-control"
                                    placeholder={PROPERTY_WEBSITE.enter_website}
                                    value={info?.website || ''}
                                    onChange={(e) =>
                                      handleChangeAgentInput({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label htmlFor="">{PROPERTY_WEBSITE.email}</label>
                                  <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    placeholder={PROPERTY_WEBSITE.enter_email}
                                    value={info?.email || ''}
                                    onChange={(e) =>
                                      handleChangeAgentInput({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label htmlFor="">{PROPERTY_WEBSITE.title}</label>
                                  <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    placeholder={PROPERTY_WEBSITE.enter_title}
                                    value={info?.title || ''}
                                    onChange={(e) =>
                                      handleChangeAgentInput({
                                        e,
                                        id: info.id || info._id,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-xxl-5">
              <div className="previewVideo">
                <div className="video" />
                <Link
                  to={`/property-website/${params.id}`}
                  target={'_blank'}
                  className="btn cta-outline-primary"
                >
                  {PROPERTY_WEBSITE.see_live_preview}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
