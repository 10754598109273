import React, { useState, useRef } from 'react';

import { TOUR_STATUSES, TOUR_STATUSES_USER_VISIBLE } from 'constants';
import { useOnClickOutside } from 'hooks';

// Images
import CloseIcon from 'assets/images/close.svg';
import SearchIcon from 'assets/images/search.svg';
import { Polygon } from 'assets/images';

export default function FilterDropdown(props) {
  const { changeFilter, close, filters, setFilters } = props;
  const [addingAgent, setAddingAgent] = useState('');
  const ref = useRef();

  const filterNotVisibleStatuses = (status) => {
    if (
      status === TOUR_STATUSES.edited ||
      status === TOUR_STATUSES.uploaded ||
      status === TOUR_STATUSES.completed
    ) {
      return false;
    }
    return true;
  };

  const visibleFilterStatuses = filters?.statuses?.filter(
    filterNotVisibleStatuses
  );

  useOnClickOutside(ref, close);

  const visibleTourStatuses = Object.entries(TOUR_STATUSES_USER_VISIBLE);

  const handleAddStatus = (keyValPair) => {
    if (!filters?.statuses?.includes(keyValPair[1])) {
      let selectedStatuses = [...filters?.statuses, keyValPair[1]];
      if (keyValPair[1] === TOUR_STATUSES_USER_VISIBLE.scheduled) {
        selectedStatuses = [
          ...selectedStatuses,
          TOUR_STATUSES.completed,
          TOUR_STATUSES.uploaded,
          TOUR_STATUSES.edited,
        ];
      }
      setFilters((prev) => ({ ...prev, statuses: selectedStatuses }));
      const data = {
        ...filters,
        statuses: selectedStatuses,
      };
      changeFilter(data);
    }
  };

  const handleRemoveStatus = (currentStatus) => {
    let selectedStatuses = filters?.statuses?.filter(
      (status) => status !== currentStatus
    );
    if (currentStatus === TOUR_STATUSES.scheduled) {
      selectedStatuses = selectedStatuses?.filter(filterNotVisibleStatuses);
    }
    setFilters((prev) => ({ ...prev, statuses: selectedStatuses }));
    const data = {
      ...filters,
      statuses: selectedStatuses,
    };
    changeFilter(data);
  };

  const handleAddAgent = (agent) => {
    if (!filters?.agents?.includes(agent)) {
      const selectedAgents = [...filters?.agents, agent];
      setFilters((prev) => ({ ...prev, agents: selectedAgents }));
      const data = {
        ...filters,
        agents: selectedAgents,
      };
      changeFilter(data);
      setAddingAgent('');
    }
  };

  const handleRemoveAgent = (agent) => {
    const selectedAgents = filters?.agents?.filter(
      (ag) => ag.toLowerCase() !== agent.toLowerCase()
    );
    setFilters((prev) => ({ ...prev, agents: selectedAgents }));
    const data = {
      ...filters,
      agents: selectedAgents,
    };
    changeFilter(data);
  };

  return (
    <div className="dropdown-menu dropdownMenu" id="drop-menu" ref={ref}>
      <Polygon className="popup-icon" />
      <h4>Selected filters</h4>
      <ul className="filterSuggest">
        {visibleFilterStatuses?.map((currentStatus, idx) => {
          return (
            <li key={idx}>
              <span className={`badges badge-${currentStatus.toLowerCase()}`}>
                {currentStatus === 'REQUESTED'
                  ? 'New'
                  : currentStatus.toLowerCase()}
                <div
                  onClick={() => handleRemoveStatus(currentStatus)}
                  className="close-icon-container"
                >
                  <img src={CloseIcon} alt="close" />
                </div>
              </span>
            </li>
          );
        })}
        {filters?.agents?.map((currentAgent, idx) => {
          return (
            <li key={idx}>
              <span className={`badges badge-${currentAgent.toLowerCase()}`}>
                {currentAgent}
                <div
                  onClick={() => handleRemoveAgent(currentAgent)}
                  className="close-icon-container"
                >
                  <img src={CloseIcon} alt="close" />
                </div>
              </span>
            </li>
          );
        })}
      </ul>
      <h4>By categories</h4>
      <ul className="filterSuggest">
        {visibleTourStatuses.map((keyValPair, idx) => {
          return (
            <li key={idx}>
              <span
                onClick={() => handleAddStatus(keyValPair)}
                className={`badges selectable-badge badge-${keyValPair[1].toLowerCase()}`}
              >
                {keyValPair[0]}
              </span>
            </li>
          );
        })}
      </ul>
      <h4>By agents</h4>
      <div className="relative">
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddAgent(addingAgent);
            }
          }}
          onChange={(e) => setAddingAgent(e.target.value)}
          value={addingAgent}
          type="text"
          className="form-control"
          placeholder="Try ‘Property Address’"
        />
        <i>
          <img src={SearchIcon} alt="search" />
        </i>
      </div>
    </div>
  );
}
