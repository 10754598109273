import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteCompanyUser } from '../actions';
import { usersListSelector } from '../selectors';

import { ConfirmAlert } from 'components';
import { AgentNoImage } from 'components/agentNoImage';

// Images
import EditIcon from 'assets/images/edit.svg';
import TrashIcon from 'assets/images/trash-2.svg';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export default function UserItem(props) {
  const { user, isCompanyAdmin } = props;
  const dispatch = useDispatch();
  const usersList = useSelector(usersListSelector);

  const { email, id, image, last_name, name, phone, position } = user;

  const deleteUser = () => {
    dispatch(deleteCompanyUser(id, usersList));
  };

  const openDeleteAlert = () => {
    ConfirmAlert({
      title: `Confirm to delete ${name ?? ''} ${last_name ?? ''} `,
      confirm: deleteUser,
    });
  };

  return (
    <div className="col-xxl-3 col-xl-4 col-sm-6  mt-3">
      <div className="user-card">
        <div className="userHeader">
          <div className="avatar">
            {image ? (
              <img src={imageBaseUrl + image} alt="avatar" />
            ) : (
              <AgentNoImage name={user?.name} surname={user?.last_name} />
            )}
          </div>
          <div className="name">{`${name} ${last_name}`}</div>
          <div className="desg">{position}</div>
        </div>
        <div className="media-body">
          <div className="info">
            <div className="label">Email</div>
            <div className="dec ellipsis">{email}</div>
          </div>
          <hr />
          <div className="info">
            <div className="label">Phone</div>
            <div className="dec">{phone}</div>
          </div>
        </div>
        <div className="cardFooter">
          {isCompanyAdmin && (
            <ul>
              <li>
                <Link to={`/user/${id}`} className="btn cta-outline-primary">
                  View profile
                </Link>
              </li>
              <li>
                <Link to={`/user/${id}/edit`} className="cta-rounded">
                  <img src={EditIcon} alt="edit" />
                </Link>
              </li>
              <li>
                <button
                  onClick={isCompanyAdmin && openDeleteAlert}
                  className="cta-rounded"
                >
                  <img src={TrashIcon} alt="trash" />
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
