import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  EXAMPLE_TITLE,
  SEE_LESS,
  SEE_MORE,
  SEE_MORE_LENGTH,
} from 'pages/intro/constants';

// images
import IntroBuildingsIcon from 'assets/icons/intro_buildings_icon.svg';
import IntroPlusIcon from 'assets/icons/intro_plus_icon.svg';

export const IntroFlowItem = (props) => {
  const {
    title,
    description,
    example_text,
    navigate_path,
    isExistingFlow,
    isBlankTarget,
    isAbsolutePath,
    see_templates_here,
  } = props;

  const [isOpenedSeeMore, setIsOpenedSeeMore] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (isAbsolutePath && isBlankTarget) {
      window.open(navigate_path);
    } else {
      navigate(navigate_path, { state: { isExistingFlow } });
    }
  };

  return (
    <div className="item_container">
      <div className="header_container" onClick={handleNavigate}>
        <img alt="building" src={IntroBuildingsIcon} />
        <h3 className="intro_item_title">{title}</h3>
        <img className="intro_plus_icon" alt="plus" src={IntroPlusIcon} />
      </div>
      <div className="intro_item_description_container">
        <p className="intro_item_description_text">{description}</p>
      </div>
      <div className="intro_item_example_container">
        <h5 className="intro_item_example_title">{EXAMPLE_TITLE}</h5>
        <p
          className={`intro_item_example_text ${
            !isOpenedSeeMore && 'two_lines_text_ellipsis'
          }`}
        >
          {isAbsolutePath ? (
            <a
              href={see_templates_here}
              target="_blank"
              className="see_templates"
            >
              {example_text}
            </a>
          ) : (
            example_text
          )}
        </p>
      </div>
      {example_text?.length > SEE_MORE_LENGTH && (
        <>
          {isOpenedSeeMore ? (
            <button
              onClick={() => setIsOpenedSeeMore(false)}
              className="see_more_btn"
            >
              {SEE_LESS}
            </button>
          ) : (
            <button
              onClick={() => setIsOpenedSeeMore(true)}
              className="see_more_btn two_lines_text_ellipsis"
            >
              {SEE_MORE}
            </button>
          )}
        </>
      )}
    </div>
  );
};
