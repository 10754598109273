// Images
import leftArrow from 'assets/images/leftArrowSmall.svg';

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className="next_arrow_container">
      <img src={leftArrow} alt="next" />
    </div>
  );
};
