import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'components';

export default function GooglePlacesAutocomplete({
  value,
  onChange,
  onSelect,
  error,
  helperText,
  label,
}) {
  const searchOptions = {
    componentRestrictions: { country: 'CA' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['address'],
  };
  const formattedValue = value?.endsWith('Canada')
    ? value?.split(',')?.slice(0, -3)?.join(',')
    : value;
  return (
    <div>
      <PlacesAutocomplete
        value={formattedValue}
        onChange={onChange}
        onSelect={onSelect}
        searchOptions={searchOptions}
        error={error}
        helperText={helperText}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input
              customClasses={'address_sec_form_group'}
              {...getInputProps({
                id: 'address',
                label: `${!!label ? label : 'Property Address'}`,
                name: 'address',
                placeholder: 'Enter Address',
              })}
              error={error}
              helperText={helperText}
            />
            <div
              className={`${
                suggestions?.length && 'places-dropdown-container'
              }`}
            >
              {loading ? '...loading' : null}
              {suggestions.map((suggestion, idx) => {
                const style = {
                  backgroundColor: suggestion.active ? '#07364b' : '#fff',
                  color: suggestion.active ? '#fff' : '',
                };
                return (
                  <div key={idx}>
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                        className: 'address-item',
                      })}
                    >
                      {suggestion.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
