export const sqFt = [
  {
    title: '1000',
    value: 1000,
  },
  {
    title: '1000- 1500',
    value: 1500,
  },
  {
    title: '1500- 2000',
    value: 2000,
  },
  {
    title: '2000- 3000',
    value: 3000,
  },
  {
    title: '3000- 4500',
    value: 4500,
  },
];

export const ADD_FOR_ONLY  = "Add for only";
export const DOLLAR_SYMBOL = "$";
export const CUSTOMIZE = "Customize";
export const ADD_ONE_TO_INDEX = 1;
