import React from 'react';

import Calendar from 'assets/images/calendar-today.svg';

export default function ActivityItem({ activity }) {
  const { avatar, name, log, date } = activity;
  return (
    <tr>
      <td>
        <div className="user">
          <div className="avatar">
            <img src={avatar} alt="user avatar" className="img-fluid" />
          </div>
          <div className="media-body">{name}</div>
        </div>
      </td>
      <td>
        {log.type} <strong>{log.to}</strong> from <strong>{log.from}</strong>
      </td>
      <td>
        <div className="date">
          <i>
            <img src={Calendar} alt="calendar" />
          </i>
          <span>{date}</span>
        </div>
      </td>
    </tr>
  );
}
