import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ourServices: [],
};

export const { reducer, actions } = createSlice({
  name: 'our-work',
  initialState,
  reducers: {
    setOurServices: (state, { payload }) => {
      state.ourServices = payload;
    },
  },
});
