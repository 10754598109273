import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import { getHeaderSections, divideArray } from './helpers.js';
import { makeUrlFromIframe } from 'helpers';
import { deliveryPageApi } from 'api';
import { iframeQueryMaker } from 'helpers/iframeQueryMaker';
import { getWeekDay, mothDayCommaTimeAAFormatter } from 'helpers/momentHelpers';
import { isDisplay } from 'helpers/checkDisplay.js';
import { numberWithCommas } from 'helpers/numberWithCommas.js';
import { isMobile } from 'constants.js';
import { MAINSLIDER_TYPES } from './constants.js';

import { MainSlider } from 'components/mainSlider/index.js';
import { MobileHeader } from './components/MobileHeader/index.js';
import { AgentNoImage } from 'components/agentNoImage/index.js';

// styles
import '../../scss/app.scss';
import './style.scss';

// images
import map_pin from './img/map-pin.svg';
import menu_icon from './img/menu-icon.svg';
import menu_icon_dark from './img/menu-icon-dark.svg';
import doller_icon from './img/doller-icon.svg';
import play_circle from './img/play-circle.svg';
import clock from './img/clock.svg';
import close_icon from './img/close-icon.svg';
import close_nav from 'assets/images/close-nav.svg';
import defaultImage from 'assets/images/defaultImage.jpg';
import { DELIVERY_PAGE } from 'constants/propertyWebsite.js';

const base_url = process.env.REACT_APP_IMAGE_BASE_URL;
const base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL?.split('storage').join('');

const google_key = process.env.REACT_APP_GOOGLE_API_KEY;

export const DeliverPage = () => {
  const [activeSection, setActiveSection] = useState('Home');
  const [address, setAddress] = useState('');
  const [unitNumber, setUnitNumber] = useState(null);
  const [tourChildNumber, setTourChildNumber] = useState(null);
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [gallery, setGallery] = useState([]);
  const [swipeGallery, setSwipeGallery] = useState([]);
  const [floorPlansSortedGallery, setFloorPlansSortedGallery] = useState([]);
  const [visibleGallery, setVisibleGallery] = useState([]);
  const [mainSliderImage, setMainSliderImage] = useState('');
  const [mainslider, setMainSlider] = useState({});
  const [highLights, setHighLights] = useState([]);
  const [matterPort, setMatterPort] = useState([]);
  const [isMyVideoPlay, setIsMyVideoPlay] = useState(false);
  const [houseDaterList, setHouseDaterList] = useState({});
  const [agentInfos, setAgentInfos] = useState([]);
  const [neighborhood, setNeighborhood] = useState('');
  const [neighborhoodList, setNeighborhoodList] = useState([]);
  const [neighborhoodDisplay, setNeighborhoodDisplay] = useState('');
  const [tourDetailsPhoto, setTourDetailsPhoto] = useState('');
  const [tourDetailsList, setTourDetailsList] = useState([]);
  const [tourDetails, setTourDetails] = useState({});
  const [video, setVideo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [isFloorPlansModalOpen, setIsFloorPlansModalOpen] = useState(false);
  const [isAnyModalOpen, setIsAnyModalOpen] = useState(false);
  const [videoIframe, setVideoIframe] = useState(null);
  const [floorPlan, setFloorPlan] = useState([]);
  const [activePlan, setActivePlan] = useState({});
  const [url, setUrl] = useState('');
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [presentation, setPresentation] = useState({});
  const [headerShadow, setHeaderShadow] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isOpenDesktopHeader, setIsOpenDesktopHeader] = useState(true);
  const [isMatterportPlay, setIsMatterportPlay] = useState(false);
  const [mainSliderCurrentVideoIframe, setMainSliderCurrentVideoIframe] =
    useState(null);
  const [isCoverVideoPlay, setIsCoverVideoPlay] = useState(false);

  const location = useLocation();
  const params = useParams();

  const isUnbranded =
    location.pathname.endsWith('unbranded') ||
    location.hash.endsWith('unbranded');

  const propertyWebsiteSection = document.querySelector(
    '#property_website_header'
  );
  const propertyWebsiteHeaderItem = document.querySelectorAll(
    '.property_website_header_item'
  );

  const headerSections = getHeaderSections({
    highLights,
    matterPort,
    houseDaterList,
    presentation,
    video: isUnbranded ? mainslider?.unbranded : mainslider?.branded,
    gallery,
    tourDetailsList,
    tourDetails,
    neighborhoodDisplay,
    floorPlan,
    agentInfos,
    isUnbranded,
  });

  const getUrlFromIframe = (iframe) => {
    let url = null;
    if (iframe) {
      const arrayFromIframe = iframe.split(' ');
      const srcIndex = arrayFromIframe.findIndex((elem) =>
        elem.includes('src=')
      );
      if (srcIndex !== -1) {
        url = arrayFromIframe[srcIndex]
          .replaceAll('src=', '')
          .replaceAll("'", '')
          .replaceAll('"', '');
      }
    }
    setUrl(url);
    return url;
  };

  const openModal = (videoIframe, elem) => {
    if (videoIframe === 0) {
      getUrlFromIframe(elem.iframe);
    }
    if (videoIframe === 1) {
      getUrlFromIframe(elem.link);
    }
    setVideoIframe(videoIframe);
    const bodyElement = document.querySelector('body');
    if (bodyElement.style.overflow === 'hidden') {
      bodyElement.style.overflow = 'auto';
    } else {
      bodyElement.style.overflow = 'hidden';
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleOpenMyVideo = (videoIframe, elem) => {
    if (videoIframe === 0) {
      getUrlFromIframe(elem.iframe);
    }
    if (videoIframe === 1) {
      getUrlFromIframe(elem.link);
    }
    setVideoIframe(videoIframe);
    setIsMyVideoPlay(true);
  };

  const closeGalleryModal = () => {
    const bodyElement = document.querySelector('body');
    bodyElement.style.overflow = 'auto';
    setIsGalleryModalOpen(!isGalleryModalOpen);
  };

  const closePlansModal = () => {
    const bodyElement = document.querySelector('body');
    bodyElement.style.overflow = 'auto';
    setIsFloorPlansModalOpen(!isFloorPlansModalOpen);
  };

  const handleOpenFloorPlansModalOnSpecificIndex = (index) => {
    const sortedGallery = [
      ...floorPlan.slice(index),
      ...floorPlan.slice(0, index),
    ];
    setFloorPlansSortedGallery(sortedGallery);
    setIsFloorPlansModalOpen(!isFloorPlansModalOpen);
    const bodyElement = document.querySelector('body');
    bodyElement.style.overflow = 'hidden';
  };

  const scrollObserver = () => {
    if (propertyWebsiteSection && propertyWebsiteHeaderItem) {
      if (window.scrollY > 1) {
        setHeaderShadow(true);
      } else {
        setHeaderShadow(false);
      }
    }
  };

  const documentLoaded = () => {
    setTimeout(() => {
      setIsDocumentLoaded(!isDocumentLoaded);
    }, `500`);
  };

  const handleLoadMore = () => {
    const resVisibleGallery = [
      ...visibleGallery,
      ...gallery.slice(visibleGallery.length, visibleGallery.length + 12),
    ];
    setVisibleGallery(resVisibleGallery);
  };

  const handleOpenGalleryModalOnSpecificIndex = (index) => {
    const sortedGallery = [...gallery.slice(index), ...gallery.slice(0, index)];
    setSwipeGallery(sortedGallery);
    setIsGalleryModalOpen(!isGalleryModalOpen);
    const bodyElement = document.querySelector('body');
    bodyElement.style.overflow = 'hidden';
  };

  const handleChangeActivePlan = (plan) => {
    setActivePlan(plan);
  };

  const handleOpenMobileNavbar = () => {
    setIsMobileNavOpen(true);
  };

  const handleCloseMobileNavbar = () => {
    setIsMobileNavOpen(false);
  };

  const handleOpenMatterport = (videoIframe, elem) => {
    if (videoIframe === 0) {
      getUrlFromIframe(elem.iframe);
    }
    if (videoIframe === 1) {
      getUrlFromIframe(elem.link);
    }
    setVideoIframe(videoIframe);
    setIsMatterportPlay(true);
  };

  const handlePlayCoverVideo = () => {
    setIsCoverVideoPlay(true);
  };

  const handleStopCoverVideo = () => {
    setIsCoverVideoPlay(false);
  };

  useEffect(() => {
    const deliveryPageData = deliveryPageApi
      .getDeliveryPageData(params.id)
      .then((res) => {
        if (res?.data?.tour?.d3) {
          setMatterPort(JSON.parse(res?.data?.tour?.d3));
        }
        if (res?.data?.tour?.extras) {
          setVideo(JSON.parse(res?.data?.tour?.video?.extras));
        }
        if (isUnbranded) {
          setMainSliderCurrentVideoIframe(
            res?.data?.tour?.mainslider?.[0]?.unbranded
          );
        } else {
          setMainSliderCurrentVideoIframe(
            res?.data?.tour?.mainslider?.[0]?.branded
          );
        }
        setPresentation(res?.data?.tour?.presentation);
        setVisibleGallery(res?.data?.tour?.gallery.slice(0, 12));
        setAddress(res?.data?.tour?.address);
        setUnitNumber(res?.data?.tour?.unit_number);
        setTourChildNumber(res?.data?.tour?.tour_child_numbers);
        setCity(res?.data?.tour?.city);
        setProvince(res?.data?.tour?.province);
        setSalePrice(res?.data?.tour?.sale_price);
        setGallery(res?.data?.tour?.gallery);
        setMainSliderImage(res?.data?.tour?.mainslider?.[0]?.image);
        setMainSlider(res?.data?.tour?.mainslider?.[0]);
        setHighLights(res?.data?.tour?.hightlights);
        setHouseDaterList(res?.data?.tour?.housedater);
        setAgentInfos(res?.data?.tour?.agentinfos);
        setNeighborhood(res?.data?.tour?.neighborhood?.description);
        setNeighborhoodDisplay(res?.data?.tour?.neighborhood?.display);
        setNeighborhoodList(res?.data?.tour?.neighborhood?.neighborhoodlist);
        setTourDetailsPhoto(res?.data?.tour?.tourdetails?.photo);
        setFloorPlan(res?.data?.tour?.floorplan);
        setActivePlan(res?.data?.tour?.floorplan[0]);
        setTourDetailsList(res?.data?.tour?.tourdetails?.tourdetailslist);
        setTourDetails(res?.data?.tour?.tourdetails);
        setNeighborhoodDisplay(res.data?.tour?.neighborhood?.display);
        setTourDetails(res?.data?.tour?.tourdetails);
      });
  }, []);

  useEffect(() => {
    scrollObserver();
    window.addEventListener('scroll', scrollObserver);
    return () => {
      window.removeEventListener('scroll', scrollObserver);
    };
  }, [propertyWebsiteSection, propertyWebsiteHeaderItem]);

  useEffect(() => {
    window.addEventListener('load', documentLoaded);
    return () => {
      window.removeEventListener('load', documentLoaded);
    };
  }, []);

  useEffect(() => {
    let desktopCloseTimeoutId;
    const openDesktopHeaderObserver = (e) => {
      if (window.scrollY > 1 && !isMobile) {
        if (
          e.type === 'scroll' ||
          (e.screenY > 200 && e.type === 'mousemove')
        ) {
          setIsOpenDesktopHeader(true);
          clearTimeout(desktopCloseTimeoutId);
          desktopCloseTimeoutId = setTimeout(() => {
            setIsOpenDesktopHeader(false);
          }, 1000);
        } else if (e.screenY < 200) {
          setIsOpenDesktopHeader(true);
          clearTimeout(desktopCloseTimeoutId);
        }
      } else {
        setIsOpenDesktopHeader(true);
        clearTimeout(desktopCloseTimeoutId);
      }
    };
    if (
      !isFloorPlansModalOpen &&
      !isModalOpen &&
      !isGalleryModalOpen &&
      window.outerWidth > 767
    ) {
      window.addEventListener('scroll', openDesktopHeaderObserver);
      window.addEventListener('mousemove', openDesktopHeaderObserver);
    }
    return () => {
      window.removeEventListener('scroll', openDesktopHeaderObserver);
      window.addEventListener('mousemove', openDesktopHeaderObserver);
      clearTimeout(desktopCloseTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (isUnbranded) {
      setMainSliderCurrentVideoIframe(mainslider?.unbranded);
    } else {
      setMainSliderCurrentVideoIframe(mainslider?.branded);
    }
  }, [isUnbranded]);

  useEffect(() => {
    if (isFloorPlansModalOpen || isGalleryModalOpen || isModalOpen) {
      setIsAnyModalOpen(true);
    } else {
      setIsAnyModalOpen(false);
    }
  }, [isFloorPlansModalOpen, isGalleryModalOpen, isModalOpen]);

  return (
    <>
      <MobileHeader
        close={handleCloseMobileNavbar}
        closeIcon={close_nav}
        headerSections={headerSections}
        open={isMobileNavOpen}
        setActiveSection={setActiveSection}
      />
      <div
        className={`wrapper ${isAnyModalOpen && 'disabled_touch'} ${
          isCoverVideoPlay && 'transparent_container'
        }`}
        id="home"
      >
        <header
          className={`property_website_header ${
            headerShadow && 'header_shadow mobile_styles'
          } ${
            (!isOpenDesktopHeader || (isModalOpen && !isMobile)) &&
            'close_header'
          }`}
          id="property_website_header"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-md">
                    <button
                      onClick={handleOpenMobileNavbar}
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      {!isMobileNavOpen && (
                        <span className="navbar-toggle-icon">
                          <img
                            src={headerShadow ? menu_icon_dark : menu_icon}
                            alt="menu"
                          />
                          {DELIVERY_PAGE.menu}
                        </span>
                      )}
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav m-auto">
                        {headerSections?.map((elem, index) => {
                          return (
                            elem.value &&
                            isDisplay(elem.display) && (
                              <li
                                className="nav-item"
                                key={index}
                                onClick={() => setActiveSection(elem.id)}
                              >
                                <a
                                  className={`nav-link ${
                                    elem.name.toLowerCase() ===
                                    activeSection.toLowerCase()
                                      ? 'active'
                                      : ''
                                  } property_website_header_item`}
                                  href={`#${elem.id.toLowerCase()}`}
                                >
                                  {elem.name}
                                </a>
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="banner-sec">
          {!isCoverVideoPlay && (
            <div
              className="website_cover_image_holder"
              data-img={base_url + mainSliderImage?.replace('storage/', '')}
              style={{
                backgroundImage: `url(${
                  base_url + mainSliderImage?.replace('storage/', '')
                })`,
              }}
            >
              {mainslider?.type === MAINSLIDER_TYPES.video && (
                <div
                  onClick={handlePlayCoverVideo}
                  className="play-btn cover_video_play_btn"
                >
                  <img
                    src={play_circle}
                    alt="play circle"
                    className="img-fluid"
                  />
                </div>
              )}
            </div>
          )}
          {isCoverVideoPlay && (
            <div className="banner_video_holder">
              <div onClick={handleStopCoverVideo} className="close_container">
                {DELIVERY_PAGE.close_video}
              </div>
              <video
                id="parallax_video"
                className="parallax_video_item"
                controls={false}
                playsInline
                autoPlay
                muted
                loop
                src={mainslider?.video}
              ></video>
            </div>
          )}
          {(address || salePrice) && (
            <div className="banner-inner">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="text-box">
                      <ul>
                        {address && isMobile ? (
                          <li>
                            <span className="mobile_address_icon">
                              <img src={map_pin} alt="" />{' '}
                            </span>
                            <div className="mobile_address_row">
                              <p>{address?.split(',')?.[0]}</p>
                              <p>{`${address.split(',').slice(1).join(',')}${
                                unitNumber ? `, ${unitNumber}` : ''
                              }${
                                tourChildNumber
                                  ? ' (' + tourChildNumber + ')'
                                  : ''
                              }`}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <span className="mobile_address_icon">
                              <img src={map_pin} alt="" />{' '}
                            </span>
                            <p>{`${address}${
                              unitNumber ? `, ${unitNumber}` : ''
                            }${
                              tourChildNumber
                                ? ' (' + tourChildNumber + ')'
                                : ''
                            }`}</p>
                          </li>
                        )}
                        {salePrice && (
                          <li>
                            <span>
                              <img src={doller_icon} alt="" />{' '}
                            </span>
                            <p>{`Offered at $${numberWithCommas(
                              salePrice
                            )}`}</p>
                          </li>
                        )}
                      </ul>
                      <a hidden className="btn-default" href="#">
                        {DELIVERY_PAGE.request_showing}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="content_wrapper">
          {highLights && highLights.length > 0 && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section className="highlights-sec" id="highlights">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="title">
                        <h2>{DELIVERY_PAGE.highlights}</h2>
                      </div>
                      <div className="highlights-inner">
                        <ul className="highlights-ul website_highlights_ul">
                          {highLights?.map((elem, index) => {
                            return (
                              <li key={index} className="highlights_li">
                                <span className="highlights_item">
                                  <img
                                    src={
                                      base_url_without_storage +
                                      elem?.dashboard_icon?.replace(
                                        'storage/',
                                        ''
                                      )
                                    }
                                    alt="icon"
                                    className="highlights-inner_icon highlights_img"
                                  />
                                </span>
                                <h4 className="high_lights_title">
                                  {elem.title.toLowerCase()}
                                </h4>
                                <strong>{elem.pivot.detail}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {matterPort && matterPort.length > 0 && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section className="matterport-sec" id="3d_tour">
                <div className="container matterport_container" id="matterport">
                  <div className="row">
                    <div className="col-12">
                      <div className="title">
                        <h2>{DELIVERY_PAGE.matterport}</h2>
                      </div>
                      {matterPort.map((elem, index) => {
                        return (
                          <div
                            className="matterport-inner matterport_inner"
                            key={index}
                          >
                            {isMobile ? (
                              <iframe
                                width="100%"
                                src={makeUrlFromIframe({ iframe: elem.iframe })}
                                frameBorder="0"
                                allowFullScreen={true}
                                allow="xr-spatial-tracking"
                                title="matterport"
                                className="matterport_in_page"
                              ></iframe>
                            ) : (
                              <>
                                {!isModalOpen && (
                                  <>
                                    <div
                                      className="matterport_absolute_div"
                                      onClick={() => openModal(0, elem)}
                                    ></div>
                                    <div
                                      className="play-btn matterport_play_btn"
                                      onClick={() => openModal(0, elem)}
                                    >
                                      <img
                                        src={play_circle}
                                        alt="play circle"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </>
                                )}
                                {!!matterPort && (
                                  <iframe
                                    width="100%"
                                    src={makeUrlFromIframe({
                                      iframe: elem.iframe,
                                    })}
                                    frameBorder="0"
                                    allowFullScreen
                                    allow="xr-spatial-tracking"
                                    title="matterport"
                                    className="matterport_in_page"
                                  ></iframe>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {(houseDaterList?.housedaterlist?.[0]?.start ||
            houseDaterList?.housedaterlist?.[0]?.end) &&
            isDisplay(houseDaterList?.display) && (
              <ScrollAnimation
                duration={1}
                animateIn="fadeInUp"
                animateOnce={true}
              >
                <section className="open-house-sec" id="open_house">
                  <div className="container">
                    <div className="row">
                      <div className="housedater_parts_container">
                        <div className="title">
                          <h2>{DELIVERY_PAGE.open_house_on}</h2>
                        </div>
                        <div className="open-house-inner">
                          <ul>
                            <li>
                              <img src={clock} alt="" className="img-fluid" />
                              {getWeekDay(
                                houseDaterList?.housedaterlist?.[0]?.start
                              )}
                              ,{' '}
                              {mothDayCommaTimeAAFormatter(
                                houseDaterList?.housedaterlist?.[0]?.start
                              )}{' '}
                              - {houseDaterList?.housedaterlist?.[0]?.end}
                            </li>
                          </ul>
                        </div>
                        <div className="open-house-inner-description">
                          {houseDaterList?.alternate_text}
                        </div>

                        <a hidden className="btn-default m-auto" href="#">
                          {DELIVERY_PAGE.request_showing}
                        </a>
                      </div>
                    </div>
                  </div>
                  {(presentation?.alternate_text ||
                    presentation?.presentation_date) &&
                    presentation?.display === 'display' && (
                      <div className="container">
                        <div className="row">
                          <div className="housedater_parts_container">
                            <div className="title">
                              <h2>{DELIVERY_PAGE.presentation_date}</h2>
                            </div>
                            <div className="open-house-inner">
                              <ul>
                                <li>
                                  <img
                                    src={clock}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  {getWeekDay(presentation?.presentation_date)},{' '}
                                  {presentation?.presentation_date}
                                </li>
                              </ul>
                            </div>
                            <div className="open-house-inner-description">
                              {presentation?.alternate_text}
                            </div>
                            <a hidden className="btn-default m-auto" href="#">
                              {DELIVERY_PAGE.request_showing}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                </section>
              </ScrollAnimation>
            )}

          {(!!mainslider?.unbranded || !!mainslider?.branded) && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section
                className={`matterport-sec matterport2-sec website_video_sec ${!gallery.length && "margin_bottom"}`}
                id="video"
              >
                {
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="title">
                          <h2>{DELIVERY_PAGE.video}</h2>
                        </div>
                        {!isMobile ? (
                          <div className="matterport-inner">
                            <img
                              src={
                                mainSliderImage
                                  ? base_url +
                                    mainSliderImage?.replace('storage/', '')
                                  : defaultImage
                              }
                              alt="home"
                              className="img-fluid"
                            />
                            <div
                              className="play-btn"
                              onClick={() =>
                                openModal(1, {
                                  link: mainSliderCurrentVideoIframe,
                                })
                              }
                            >
                              <img
                                src={play_circle}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        ) : (
                          <iframe
                            title="video iframe"
                            src={makeUrlFromIframe({
                              iframe: mainSliderCurrentVideoIframe,
                            })}
                            frameBorder="0"
                            allowFullScreen
                            allow="xr-spatial-tracking"
                            className="mobile_video_iframe"
                            id="videoIframe"
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </section>
            </ScrollAnimation>
          )}

          {gallery && gallery.length > 0 && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section className="gallery-sec" id="gallery">
                <div className="container-fluid">
                  <div>
                    <div className="col-12">
                      <div className="title">
                        <h2>{DELIVERY_PAGE.gallery}</h2>
                      </div>
                      <div className="gallery-inner">
                        <ul id="lightgallery">
                          {visibleGallery?.map((photo, index) => {
                            return (
                              <li
                                className="gallery-item"
                                key={index}
                                data-responsive={base_url + photo.image}
                                data-src={
                                  base_url +
                                  photo.image?.replace('storage/', '')
                                }
                                onClick={() =>
                                  handleOpenGalleryModalOnSpecificIndex(index)
                                }
                              >
                                <img
                                  src={
                                    base_url +
                                    photo.image?.replace('storage/', '')
                                  }
                                  alt="gallery"
                                  className="img-fluid gallery_img"
                                />
                                <div className="gallery_cover_div"></div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  hidden={visibleGallery.length >= gallery.length}
                  className="load-more-container"
                >
                  <button onClick={handleLoadMore} className="load-more-btn">
                    {DELIVERY_PAGE.load_more}
                  </button>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {tourDetailsList &&
            (tourDetailsList.length > 0 || tourDetails?.description) &&
            tourDetails?.display === DELIVERY_PAGE.display && (
              <ScrollAnimation
                duration={1}
                animateIn="fadeInUp"
                animateOnce={true}
              >
                <section
                  className="property-detail-sec details_section_part"
                  id="details"
                >
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-box">
                          <h2>{DELIVERY_PAGE.property_details}</h2>
                          {!!tourDetails?.description && <h3>{DELIVERY_PAGE.what_to_know}</h3>}
                          <p>{tourDetails?.description}</p>
                          {!!tourDetailsList.length && <h4>{DELIVERY_PAGE.key_highlights}</h4>}
                          <div className="media">
                            <ul className="highlights-ul detail_highlights_ul">
                              {tourDetailsList.map((elem, index) => {
                                return (
                                  <li className="highlight-item" key={index}>
                                    {elem.highlight}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="image-holder">
                          <img
                            src={
                              base_url +
                              tourDetailsPhoto?.replace('storage/', '')
                            }
                            alt=""
                            className="img-fluid w-100 property_details_photo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrollAnimation>
            )}
          {neighborhoodDisplay === DELIVERY_PAGE.display && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section
                className="property-detail-sec neighborhood-info-sec"
                id="map"
              >
                <div className="container-fluid p-0">
                  <div className="row g-0">
                    <div className="col-md-6">
                      <div className="text-box">
                        <h2>{DELIVERY_PAGE.neighborhood_info}</h2>
                        <h3>{DELIVERY_PAGE.what_to_know}</h3>
                        <p>{neighborhood}</p>
                        {!!neighborhoodList?.length && (
                          <>
                            <h4>{DELIVERY_PAGE.key_highlights}</h4>
                            <div className="media">
                              {divideArray(neighborhoodList).map(
                                (elem, index) => {
                                  return (
                                    <ul key={index}>
                                      <li>{elem[0]?.highlight}</li>
                                      <li>{elem[1]?.highlight}</li>
                                    </ul>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="image-holder">
                        <iframe
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBRKXhvI9V9ekPxA5poYrZUZMdKqAMzNh0&q=${iframeQueryMaker(
                            address,
                            city,
                            province
                          )}`}
                          width="600"
                          height="450"
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Neighborhood Map"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {floorPlan && floorPlan.length > 0 && (
            <ScrollAnimation
              duration={1}
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <section className="gallery-sec floor-plan-sec" id="floor_plan">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="title">
                        <h2>{DELIVERY_PAGE.floor_plans}</h2>
                      </div>
                      <div className="gallery-inner">
                        <div className="floor_plans_header">
                          <ul
                            className={`plans_header_ul ${
                              floorPlan.length < 3 && 'centered_heading'
                            }`}
                          >
                            {floorPlan?.map((plan) => {
                              return (
                                <li
                                  className={`plan_header_item ${
                                    activePlan.id === plan.id &&
                                    'plan_header_item_active'
                                  }`}
                                  onClick={() => handleChangeActivePlan(plan)}
                                  key={plan?.id}
                                >
                                  {plan.text}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <ul className="floor-plans-ul" id="lightgallery">
                          {floorPlan?.map((plan, index) => {
                            return (
                              activePlan?.id === plan?.id && (
                                <li
                                  onClick={() =>
                                    handleOpenFloorPlansModalOnSpecificIndex(
                                      index
                                    )
                                  }
                                  className="floor-plan-item"
                                  key={index}
                                  data-responsive={
                                    base_url +
                                    plan?.image?.replace('storage/', '')
                                  }
                                  data-src={
                                    base_url +
                                    plan?.image?.replace('storage/', '')
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        base_url +
                                        plan.image?.replace('storage/', '')
                                      }
                                      alt="gallery"
                                      className="img-fluid floor-plan-img"
                                    />
                                  </div>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ScrollAnimation>
          )}
          {isFloorPlansModalOpen && (
            <MainSlider
              close_icon={close_icon}
              swipeGallery={floorPlansSortedGallery}
              closeGalleryModal={closePlansModal}
            />
          )}
          {agentInfos &&
            agentInfos.length > 0 &&
            agentInfos?.[0]?.display === DELIVERY_PAGE.display &&
            !isUnbranded && (
              <ScrollAnimation
                duration={1}
                animateIn="fadeInUp"
                animateOnce={true}
              >
                <section className="testimonial-sec" id="agent">
                  <div className="container">
                    <div className="testimonial_row">
                      <div className="testimonial_col-12">
                        <div className="title">
                          <h2>{`LISTING AGENT${
                            agentInfos?.length === 1 ? '' : 'S'
                          }`}</h2>
                        </div>
                        <div className="testimonial-inner">
                          <div
                            className={`row ${
                              agentInfos?.length === 1 && 'row_centered'
                            }`}
                          >
                            {agentInfos?.map((elem, index) => {
                              return (
                                <div className="agent-item" key={index}>
                                  <div className="testimonial-box">
                                    <div className="image-holder">
                                      {elem.photo ? (
                                        <img
                                          src={
                                            base_url +
                                            elem?.photo?.replace('storage/', '')
                                          }
                                          alt=""
                                          className="img-fluid agent_image_pw_section"
                                        />
                                      ) : (
                                        <AgentNoImage
                                          name={elem.first_name}
                                          surname={elem.last_name}
                                        />
                                      )}
                                    </div>
                                    <div className="text-box">
                                      <h4>{`${elem.first_name.toUpperCase()} ${elem.last_name.toUpperCase()}`}</h4>
                                      <span>{DELIVERY_PAGE.agent}</span>
                                      {elem.telephone && (
                                        <p>
                                          <strong>{DELIVERY_PAGE.short_phone} </strong>
                                          <a
                                            className="agent-info-text"
                                            href={`tel:${elem.telephone}`}
                                          >
                                            {' ' + elem.telephone}
                                          </a>
                                        </p>
                                      )}
                                      {elem.email && (
                                        <p>
                                          <strong>{DELIVERY_PAGE.short_email} </strong>
                                          <a
                                            className="agent-info-text"
                                            href={`mailto:${elem.email}`}
                                          >
                                            {elem.email}
                                          </a>
                                        </p>
                                      )}
                                      {elem.title && (
                                        <p>
                                          <strong>{DELIVERY_PAGE.short_designation} </strong>
                                          <span className="agent-info-text disable_click">
                                            {elem.title}
                                          </span>
                                        </p>
                                      )}
                                      {elem.website && (
                                        <p>
                                          <strong>{DELIVERY_PAGE.short_website} </strong>
                                          <a
                                            className="agent-info-text"
                                            href={' ' + elem.website}
                                          >
                                            {elem.website}
                                          </a>
                                        </p>
                                      )}
                                      {elem.address && (
                                        <p className="agent_info_address">
                                          <strong>{DELIVERY_PAGE.short_address} </strong>
                                          <div className="agent-info-text">
                                            {' ' + elem.address.split(',')}
                                          </div>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrollAnimation>
            )}
          {isModalOpen && (
            <section className="modal_section">
              <div className="video-modal_section">
                <div className="fade_section" id="exampleModal1_section">
                  <div className="modal-dialog_section modal-dialog-centered_section">
                    <div className="modal-content_section">
                      <div className="modal-body_section">
                        <div className="close-btn_section" onClick={openModal}>
                          <img src={close_icon} alt="close" />
                        </div>
                        {videoIframe === 1 && (
                          <iframe
                            title="video iframe"
                            src={url}
                            frameBorder="0"
                            allowFullScreen
                            allow="xr-spatial-tracking"
                            className="video_iframe"
                            id="videoIframe"
                          ></iframe>
                        )}
                        {videoIframe === 0 && (
                          <div className="modal_matterport_container">
                            <iframe
                              title="matterport iframe"
                              src={url}
                              frameBorder="0"
                              allowFullScreen
                              allow="xr-spatial-tracking"
                              className="matterport_iframe"
                              id="matterportIframe"
                            ></iframe>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {isGalleryModalOpen && (
            <MainSlider
              close_icon={close_icon}
              swipeGallery={swipeGallery}
              closeGalleryModal={closeGalleryModal}
            />
          )}
          <footer className="footer">
            <div className="container footer-inner-container">
              <div className="row">
                <div className="col-12">
                  <div className="media">
                    <p>{DELIVERY_PAGE.copyright}</p>
                    <span>
                      {DELIVERY_PAGE.powered_by} <a href="#"> {DELIVERY_PAGE.pv_apps} </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </>
  );
};
