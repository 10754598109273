import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';

import { SearchComponent } from 'components/mainTable/searchComponent';
import { CircularProgress } from 'components/circularProgress';
import {
  CIRCULAR_PROGRESS_COLOR,
  CIRCULAR_PROGRESS_SIZE_NAMES,
} from 'components/circularProgress/constants';

export function MainTable(props) {
  const {
    columns,
    data,
    hasPagination,
    hasSearch,
    title,
    hasLoading,
    isPending,
    total,
    setCurrentPage,
    setLimit
  } = props;
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems =
    data &&
    data.filter((item) =>
      item.tour_address
        ? item.tour_address.toLowerCase().includes(filterText.toLowerCase())
        : item.payment_id &&
          String(item.payment_id).includes(filterText.toLowerCase())
    );

  const handleChangePage = (page) => {
    setCurrentPage(page);
  }

  const handlePerRowsChange = (newPerPage) => {
    setLimit(newPerPage);
  }
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    };

    return (
      <SearchComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination={hasPagination}
      paginationTotalRows={total}
      paginationServer
      subHeader={hasSearch}
      subHeaderComponent={subHeaderComponentMemo}
      title={title}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handleChangePage}
      progressPending={hasLoading && isPending}
      progressComponent={
        hasLoading && (
          <CircularProgress
            progressColor={CIRCULAR_PROGRESS_COLOR.primary}
            size={CIRCULAR_PROGRESS_SIZE_NAMES.medium}
          />
        )
      }
    />
  );
}
