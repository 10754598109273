import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { reducer as authReducer } from 'pages/auth';
import { reducer as usersReducer } from 'pages/users';
import { reducer as toursReducer } from 'pages/tours';
import { reducer as websiteReducer } from 'pages/property-website';
import { reducer as downloadZoneReducer } from 'pages/download-zone/newStore';
import { reducer as ourWorkReducer } from 'pages/our-work/store';
import { reducer as billingReducer } from 'pages/billing';

const reducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  tours: toursReducer,
  website: websiteReducer,
  downloadZone: downloadZoneReducer,
  ourWork: ourWorkReducer,
  billing: billingReducer,
});

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
});
