import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOAST_MESSAGES } from 'constants';
import { TOAST_TYPES } from 'constants';
import { Toastify } from 'hooks';
import { goToPayment } from 'pages/tours/actions';
import { salesOptionsSelector, selectedSqFtSelector } from 'pages/tours/selectors';

import { Button } from 'components/button';
import { ORDER_SUMMARY } from 'constants/orderSummary';
import { ADD_ONE_TO_INDEX } from 'pages/tours/constants';

export const OrderSummary = ({
  title,
  packages,
  filteredServices,
  selectedPackage,
  packageServices,
  isDetailsPage,
  isPaidMainServices,
  tourId,
}) => {
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const selectedSqFt = useSelector(selectedSqFtSelector);
  const salesOptions = useSelector(salesOptionsSelector);
  const selectedPackageServices = selectedPackage?.dashboard_services
    ? selectedPackage?.dashboard_services
    : selectedPackage?.services;

  const dispatch = useDispatch();

  const selectedPackageServiceIds = () => {
    const ids = [];
    selectedPackageServices?.forEach(({ id }) => {
      ids.push(id);
    });
    return ids;
  };

  const handlePay = () => {
    setIsLoadingPay(true);
    dispatch(goToPayment({ id: tourId, is_main_service: 1 }))
      .then((res) => window.open(res.data.url, '_self'))
      .catch(() => {
        Toastify({
          type: TOAST_TYPES.error,
          message: TOAST_MESSAGES.tryAgain,
        });
      })
      .finally(() => setIsLoadingPay(false));
  };

  const getSalesOptionsPrice = useMemo(() => {
    const subtotal = salesOptions?.reduce(
      (previousValue, option) =>
      option?.price ? Number(previousValue) + Number(option?.price) : Number(previousValue),
      0
    );
    return subtotal;
  }, [salesOptions])

  const getPackagePrice = (price = 0) => {
    return Number(price) + getSalesOptionsPrice;
  }

  const getSubtotal = useMemo(() => {
    const tempSelPack = packages?.find((el) => el.id === selectedPackage?.id);
    const prices = tempSelPack?.price ? [tempSelPack?.price] : [];

    filteredServices?.forEach((service) => {
      if (
        !selectedPackageServiceIds()?.includes(service.id) ||
        !salesOptions?.find((elem) => elem?.id !== service.id)
      ) {
        prices.push(service.price);
      }
    });

    salesOptions?.forEach((option) => {
      prices.push(option?.price)
    });

    const subtotal = prices.reduce(
      (previousValue, currentValue) =>
        currentValue ? Number(previousValue) + Number(currentValue) : Number(previousValue),
      0
    );
    return subtotal;
  }, [
    selectedPackage,
    filteredServices,
    selectedSqFt,
    packages,
    selectedPackageServiceIds,
    salesOptions
  ]);

  const getHST = useMemo(() => {
    return (getSubtotal * ORDER_SUMMARY.hst_percent) / ORDER_SUMMARY.hundred_percent;
  }, [getSubtotal]);

  const getTotal = useMemo(() => {
    return (
      getSubtotal + (getSubtotal * ORDER_SUMMARY.hst_percent) /
      ORDER_SUMMARY.hundred_percent).toFixed(ORDER_SUMMARY.fixed_count);
  }, [getHST]);

  const selectedPackageServicesList = selectedPackageServices ? selectedPackageServices : [];
  const salesOptionsServicesList = salesOptions ? salesOptions : [];
  const servicesList =
    [...selectedPackageServicesList, ...salesOptionsServicesList].map((elem) => elem?.title);

  return (
    <>
      <div className="cardCharge">
        <h3>{title}</h3>
        <div className="titleCharge">
          <h4>{ORDER_SUMMARY.package}</h4>
          <h4>{ORDER_SUMMARY.price}</h4>
        </div>
        <ul>
          {packages
            ?.filter((el) => el.id === selectedPackage?.id)
            .map((el, i) => (
              <li key={i}>
                <span className="package_name">
                  {el.title}
                </span>{' '}
                ${getPackagePrice(el?.price)}
              </li>
            ))}
        </ul>
        <ul>
          <li className="services_list">
            {servicesList?.map((el, index) => (
              <div className="service_element" key={index}>
                <span key={index} className="package_name">
                  {el}
                </span>
                {servicesList?.length !== (index + ADD_ONE_TO_INDEX) && (
                  <div className="service_border"></div>
                )}
              </div>
            ))}
          </li>
        </ul>
        <div className="titleCharge">
          <h4>{ORDER_SUMMARY.add_ons}</h4>
        </div>
        <ul>
          {filteredServices
            ?.filter((el) => !selectedPackageServiceIds().includes(el.id))
            .map((service, i) => (
              <li key={i}>
                <span>
                  {service?.title?.charAt(0)?.toUpperCase() +
                    service?.title?.slice(1)}
                </span>{' '}
                {service.price ? `$${service.price}` : ORDER_SUMMARY.not_specified}
              </li>
            ))}
        </ul>
        <div className="totalCard">
          <div className="amount">
            <p className="subTotal">
              <span>{ORDER_SUMMARY.subtotal}</span> <strong>${getSubtotal}</strong>
            </p>
            <p>
              <span>{ORDER_SUMMARY.hst}</span> <strong>{ORDER_SUMMARY.hst_percent_text} (${getHST})</strong>
            </p>
          </div>
          <div className="total">
            <p>
              <span>{ORDER_SUMMARY.total}</span>{' '}
              <strong>${Number.parseFloat(getTotal || 0).toFixed(2)}</strong>
            </p>
          </div>
        </div>
      </div>
      {!isPaidMainServices && isDetailsPage && (
        <Button
          hidden={true}
          disabled={isLoadingPay}
          onClick={handlePay}
          customClasses="btn cta-primary later_charge_pay_btn"
          loading={isLoadingPay}
          text={`${ORDER_SUMMARY.pay} $${Number.parseFloat(getTotal || ORDER_SUMMARY.default_price).toFixed(ORDER_SUMMARY.fixed_count)}`}
        />
      )}
    </>
  );
};
