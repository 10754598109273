import { Button } from 'components/button';

export const Footer = (props) => {
  const {
    footerElementsDirection,
    footerType,
    button_1_phone,
    button_1_type,
    button_1_text,
    button_2_phone,
    button_2_type,
    button_2_text,
    title = false,
    close,
  } = props;
  return (
    <div
      className={`modal_footer_container ${footerElementsDirection} ${footerType}`}
    >
      {!!title && <h3>{title}</h3>}
      <Button
        phone_button={button_1_phone}
        type={button_1_type}
        text={button_1_text}
      />
      <Button
        phone_button={button_2_phone}
        type={button_2_type}
        text={button_2_text}
        onClick={close}
      />
    </div>
  );
};
