import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import stickybits from 'stickybits';
import AddressBar from './left-side-addressbar';
import Button from '../../button/index';
import './style.scss';
import update_checker from '../../../global/update_checker';
import sectionNameConverter from '../../../global/section-name-converter';
import sectionsId from '../../../global/sections-id';

const LeftSidebar = ({ data = [], url, active, APIData }) => {
  stickybits('selector', { useStickyClasses: true });
  stickybits('selector', { stickyClass: 'wrapper_left_sidebar' });
  const [section, setSection] = useState('');
  const [updatedData, setUpdatedData] = useState({});
  const [filteredUpdatedData, setFilteredUpdatedData] = useState([]);
  const [availableTours, setAvailableTours] = useState([]);

  const handleFilter = (key) => {
    if (key === 'property_web') {
      return true;
    }
    if (key === 'instagram' && APIData[key]?.length) {
      return true;
    }
    if (
      key === 'folder_completed_photo' &&
      APIData?.[key]?.['MLS']?.length === 0
    ) {
      return false;
    }
    if (
      (Array.isArray(APIData[key]) && APIData[key].length) ||
      (key === 'extras' && !!APIData[key]) ||
      (key === 'extras' &&
        (!!APIData?.videos?.vimeo_branded ||
          !!APIData?.videos?.vimeo_unbranded))
    ) {
      return true;
    }
    if (typeof APIData[key] === 'object' && APIData[key] !== null) {
      const fields = Object.values(APIData[key]);
      const notEmptyFields = fields.filter((field) => field.length);
      return notEmptyFields.length;
    }

    return false;
  };

  const getUpdatedSectionsAsArray = (updateInfo) => {
    updateInfo.sectionsWithLastDate.map((el) => {
      setFilteredUpdatedData((prev) => [
        ...prev,
        ...sectionsId.filter(
          (elem) => sectionNameConverter(elem.title) === el.name.toUpperCase()
        ),
      ]);
    });
  };

  const changeArrayToUniq = (value, index, selfArray) => {
    return selfArray.indexOf(value) === index;
  };

  const checkData = (array, name, condition) => {
    if (condition) {
      array.push(name);
    }
  };

  const sectionsArray = [
    {
      name: 'TWILIGHT PHOTOGRAPHY',
      condition:
        APIData?.folder_completed_photo?.['MLS']?.length > 0 ||
        APIData?.folder_completed_photo?.['PRINT']?.length > 0,
    },
    {
      name: 'AGENT VIDEO INTRO',
      condition: APIData?.extras?.length > 0,
    },
    {
      name: 'FLOOR PLANS',
      condition:
        APIData?.floor_plans?.withSqFt?.[0]?.files?.length > 0 ||
        APIData?.floor_plans?.withSqFt?.[1]?.files?.length > 0 ||
        APIData?.floor_plans?.withoutSqFt?.[0]?.files?.length > 0 ||
        APIData?.floor_plans?.withoutSqFt?.[1]?.files?.length > 0,
    },
    {
      name: 'INSTAGRAM (Social Media) Teaser',
      condition: APIData?.instagram?.length > 0,
    },
    {
      name: '3D MATTERPORT TOUR',
      condition:
        APIData?.matterport?.[0]?.iframe?.length > 0 ||
        APIData?.matterport?.[1]?.iframe?.length > 0,
    },
    {
      name: 'AGENT VIDEO INTRO',
      condition:
        APIData?.videos?.vimeo_branded?.length > 0 ||
        APIData?.videos?.vimeo_unbranded?.length > 0,
    },
    {
      name: 'VIRTUAL STAGING / per photo',
      condition: APIData?.virtual_staging?.length > 0,
    },
  ];

  const orderedServiceFilter = (localData, serviceData, packageData) => {
    const newArray = [];
    newArray.push(localData[0]);
    const serviceAndPackageData = [];

    sectionsArray.forEach((element) => {
      checkData(serviceAndPackageData, element.name, element.condition);
    });

    serviceData?.forEach((element) => {
      serviceAndPackageData.push(element.name);
    });
    packageData?.forEach((element) => {
      serviceAndPackageData.push(element.title);
    });

    const serviceAndPackageDataUniqItems =
      serviceAndPackageData.filter(changeArrayToUniq);

    localData.map((localElement) => {
      serviceAndPackageDataUniqItems.map((serverElement) => {
        if (
          serverElement
            .toLowerCase()
            .includes(localElement.services.toLowerCase())
        ) {
          newArray.push(localElement);
        }
      });
    });
    return newArray.filter(changeArrayToUniq);
  };

  useEffect(() => {
    setSection(
      APIData?.download_center_sections?.filter(
        (elem) => elem.name === 'sectionName'
      )
    );
  }, []);

  useEffect(() => {
    const updateInfo = update_checker(APIData);
    if (!!updateInfo) {
      getUpdatedSectionsAsArray(updateInfo);
    }
    setUpdatedData(updateInfo);
  }, [APIData]);

  useEffect(() => {
    (APIData?.services || APIData?.packages) &&
      setAvailableTours(
        orderedServiceFilter(
          data,
          APIData?.services,
          APIData?.packages?.services
        )
      );
  }, [APIData]);

  return (
    <div id="sticky" className="wrapper_left_sidebar js-is-sticky stickybit">
      <div className="delivered-button">
        {(!!APIData?.download_center_sections?.length ||
          !!APIData?.deliver_date) && (
          <div className="div-for-btn">
            <Button
              sectionName="heading"
              APIData={APIData}
              sidebar={true}
              updatedData={updatedData}
            />
          </div>
        )}
      </div>
      <span className="left_border" />
      <span className="right_border" />
      {!!data.length && (
        <div
          className={`sidebarOfDownloadZone ${
            !!section && section?.length !== 0 ? 'withChangedLabel' : ''
          }`}
        >
          <AddressBar APIData={APIData} />
          {availableTours.map((el) => {
            return (
              <Link
                key={el.key}
                className={`item ${
                  !!el.visible && handleFilter(el.key)
                    ? 'left_sidebar_active_section'
                    : 'left_sidebar_not_active_section'
                } `}
                activeClass="active"
                to={
                  !!el.visible && handleFilter(el.key) ? el.id.toString() : ''
                }
                spy={true}
                offset={parseInt(el.offSet)}
              >
                <span className="active_arrow" />
                <div className="imgs">
                  <div
                    className={`${
                      filteredUpdatedData.find((elem) => elem.id === el.id) &&
                      !updatedData.isDeliveryLatest
                        ? 'left_sidebar_updated_section'
                        : ''
                    }`}
                  ></div>
                  <img src={el.active_icon} alt="" />
                </div>
                <div className="text">
                  <span className="reg">{el.title}</span>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LeftSidebar;
