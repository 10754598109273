export const HorizontalScrollableNavbar = (props) => {
  const { height, items, selectedId, setSelectedId } = props;

  return (
    <div className="horizontal_nav_container" style={{ height }}>
      {items?.map((item) => {
        return (
          <div
            onClick={() => setSelectedId(item.id)}
            key={item.id}
            className={`item ${selectedId === item.id && 'active'}`}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};
