import moment from 'moment';

export const customDateSort = (rowA, rowB) => {
  const firstElmDate = moment(rowA.created_at);
  const secondElmDate = moment(rowB.created_at);

  if (firstElmDate > secondElmDate) {
    return 1;
  }

  if (secondElmDate > firstElmDate) {
    return -1;
  }

  return 0;
};
