import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useOnClickOutside } from 'hooks';
import { getPropertyWebsiteHighlights } from './actions';
import { MEDIA_TYPES } from 'constants';
import { makeUrlFromIframe } from 'helpers';
import { isMobile } from 'constants';

import { NavigationControl } from 'components';
import { HorizontalScrollableNavbar } from 'components/horizontalScrollableNavbar';
import { VideoItem } from 'components/videoItem';
import { ImageItem } from 'components/imageItem';
import { ReelItem } from 'components/reelItem';
import { MainSlider } from 'components/mainSlider';
import { PdfItem } from 'components/pdfItem';
import { MatterportItem } from 'components/matterportItem';

// Images
import close_icon from 'assets/images/close-icon.svg';

const pageTitle = 'Our Work';
const base_url = process.env.REACT_APP_BASE_URL;
const image_base_url = process.env.REACT_APP_IMAGE_BASE_URL;
const image_base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.replace('/storage', '/');

export const OurWork = () => {
  const services = useSelector((state) => state.ourWork.ourServices);

  const [selectedId, setSelectedId] = useState(null);
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [imageItems, setImageItems] = useState([]);

  const videoRef = useRef(null);
  const pdfRef = useRef(null);
  const matterportRef = useRef(null);

  const dispatch = useDispatch();

  const { state } = useLocation();

  useOnClickOutside(videoRef, () => setIsOpenLightbox(false));
  useOnClickOutside(pdfRef, () => setIsOpenLightbox(false));
  useOnClickOutside(matterportRef, () => setIsOpenLightbox(false));

  const selectedService = services?.find((item) => item?.id === selectedId);

  const handleOpenLightBox = (id) => {
    setIsOpenLightbox(true);
    const activeObj = selectedService?.medias?.find((item) => item?.id === id);
    setMediaType(activeObj?.media_type);
    setActiveItem(activeObj);
  };

  const handleOpenImageSlide = (index, id) => {
    const activeObj = selectedService?.medias?.find((item) => item?.id === id);
    setMediaType(activeObj?.media_type);
    setActiveItem(activeObj);
    const imagesArray = activeObj.images.map((img) => ({ image: img }));
    setImageItems(imagesArray);
    setIsOpenLightbox(true);
  };

  const handleCloseLightbox = () => {
    setIsOpenLightbox(false);
  };

  useEffect(() => {
    dispatch(getPropertyWebsiteHighlights());
  }, []);

  useEffect(() => {
    setSelectedId(services?.[0]?.id);
  }, [services]);

  return (
    <div
      className={`content our_work_content ${
        isOpenLightbox && 'disabled_touch_events'
      }`}
    >
      <NavigationControl
        backBtnText={!!state?.forwarded && 'Back to my order'}
        isBack={!!state?.forwarded}
        title={pageTitle}
        isRightBackBtn={true}
      />
      <HorizontalScrollableNavbar
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        items={services}
      />
      <div className="our_work_content_wrapper">
        <div className="section_head_title">{selectedService?.title}</div>
        <div className="section_head_description">
          {selectedService?.popup_description || selectedService?.description}
        </div>
        <div className={`items_container ${isMobile && 'spaced_evenly'}`}>
          {selectedService?.medias?.map((item, index, arr) => {
            if (item?.media_type === MEDIA_TYPES.video) {
              return (
                <VideoItem
                  key={item?.id}
                  id={item?.id}
                  coverImage={item?.video_cover_image}
                  duration={item?.duration}
                  description={item?.title}
                  onClickItem={handleOpenLightBox}
                />
              );
            } else if (item?.media_type === MEDIA_TYPES.image) {
              return (
                <ImageItem
                  key={item?.id}
                  id={item?.id}
                  imagePath={item?.gallery_cover_image}
                  numberInArray={1}
                  itemsCount={item.images.length}
                  imageItems={item.images}
                  imageItemKey={'image'}
                  description={item?.title}
                  onClickItem={handleOpenImageSlide}
                />
              );
            } else if (item?.media_type === MEDIA_TYPES.reel) {
              return (
                <ReelItem
                  key={item?.id}
                  id={item?.id}
                  coverImage={item?.reel_cover_image}
                  description={item?.title}
                  onClickItem={handleOpenLightBox}
                />
              );
            } else if (item?.media_type === MEDIA_TYPES.pdf) {
              const splittedName = item?.pdf?.split('/');
              const fileName = splittedName[splittedName.length - 1];
              return (
                <PdfItem
                  key={item?.id}
                  id={item?.id}
                  fileName={fileName}
                  coverImage={item?.pdf_cover_image}
                  description={item?.title}
                  path={`${base_url}/${item?.pdf}`}
                  onClickItem={handleOpenLightBox}
                />
              );
            } else if (item?.media_type === MEDIA_TYPES.matterport) {
              return (
                <MatterportItem
                  key={item?.id}
                  id={item?.id}
                  coverImage={item?.matterport_cover_image}
                  description={item?.title}
                  onClickItem={handleOpenLightBox}
                />
              );
            }
          })}
        </div>
      </div>
      {isOpenLightbox && activeItem.media_type === MEDIA_TYPES.video && (
        <section className="modal_section our_work_modal">
          <div className="close_btn_container">
            <div className="close-btn_section" onClick={handleCloseLightbox}>
              <img src={close_icon} alt="close" />
            </div>
          </div>
          <video
            ref={videoRef}
            className="lightbox_video"
            controls
            playsInline
            autoPlay
            src={`${activeItem?.video_vimeo}`}
          ></video>
        </section>
      )}
      {isOpenLightbox && activeItem.media_type === MEDIA_TYPES.image && (
        <MainSlider
          close_icon={close_icon}
          swipeGallery={imageItems}
          closeGalleryModal={handleCloseLightbox}
          imageItemKey={'image'}
        />
      )}
      {isOpenLightbox && activeItem.media_type === MEDIA_TYPES.reel && (
        <section className="modal_section our_work_modal reel_modal vertical_dimension_lightbox">
          <div className="close_btn_container">
            <div className="close-btn_section" onClick={handleCloseLightbox}>
              <img src={close_icon} alt="close" />
            </div>
          </div>
          <video
            ref={videoRef}
            className="lightbox_video"
            controls
            playsInline
            autoPlay
            src={`${activeItem?.reel_vimeo}`}
          ></video>
        </section>
      )}
      {isOpenLightbox && activeItem.media_type === MEDIA_TYPES.pdf && (
        <section className="modal_section gallery_modal pdf_modal">
          <iframe
            ref={pdfRef}
            type="application/pdf"
            scrolling="auto"
            title="pdf frame"
            src={`${base_url}/${activeItem?.pdf}`}
            width="80%"
            height="80%"
            className="pdf_iframe"
          >
            <p>
              Your browser does not support PDFs.
              <a href="https://example.com/test.pdf">Download the PDF</a>.
            </p>
          </iframe>
        </section>
      )}
      {isOpenLightbox && activeItem.media_type === MEDIA_TYPES.matterport && (
        <section className="modal_section gallery_modal matterport_modal vertical_dimension_lightbox">
          <div className="close_btn_container">
            <div className="close-btn_section" onClick={handleCloseLightbox}>
              <img src={close_icon} alt="close" />
            </div>
          </div>
          <div className="our_work_matterport_container">
            <iframe
              ref={matterportRef}
              title="matterport frame"
              src={makeUrlFromIframe({
                iframe: activeItem?.matterport,
                autoplay: true,
              })}
              className="our_work_matterport_iframe"
            ></iframe>
          </div>
        </section>
      )}
    </div>
  );
};
