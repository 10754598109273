import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import VideoModal from '../../video-modal/video-modal';
import CopyButton from '../../button-types/copy-button';
import Devices from './devices/Devices';

// Styles
import './style.scss';

const base_url = process.env.REACT_APP_BASE_URL;

const Content = ({ APIData }) => {
  const { id } = useParams();
  const [showModalVideo, setShowModalVideo] = useState(false);

  const handleWatchVideo = () => {
    setShowModalVideo(true);
  };
  const brandedUrl = window.location.origin + '/property-website/' + id;
  const unBrandedUrl =
    window.location.origin + '/property-website/' + id + '/unbranded';
  const customUrl = APIData?.custom_url;
  const brandedWebsiteText =
    'Below you will find a link to your Branded Property Website , all of the media produced in your order is tastefully showcased on a modern ONE page responsive web page.';
  const unbrandedWebsiteText =
    'Below you will find a link to your UNBranded Property Website , all of the media produced in your order is tastefully showcased on a modern one page responsive website[Landing Page]. Please use the Unbranded URL MLS.';
  const customURLText =
    'We are very excited to offer our VIRTUAL STAGING service To help our clients adapt to the fast-changing environment of today’s Real Estate Market and bring the best possible.';
  const customWebsiteText =
    'You can insert Listing Specific Info to your Property Website by clicking to “GO TO EDITOR” & logging in to your account. Add info like highlights, property details, open house/offer presentation dates.. & much more. Call/Txt us with any questions.';

  return (
    <>
      <section className="first-section" id="9">
        <div className="devices_image">
          <Devices galleryPhoto={APIData?.folder_completed_photo?.MLS} />
        </div>
        <div className="right">
          <div className="website_section">
            <div className="website_content">
              <h1>Branded Website</h1>
              <span>{brandedWebsiteText}</span>
            </div>
            <div className="btn-wrapper">
              <CopyButton urlType={'branded'} urlLink={brandedUrl} />
              <button className="btn_buton down_zone_main_btn">
                <Link to={`/property-website/${id}/branded`} target={'_blank'}>
                  Go To Website
                </Link>
              </button>
            </div>
          </div>
          <span className="property_liner"></span>
          <div className="website_section">
            <div className="website_content">
              <h1>Unbranded Website</h1>
              <span>{unbrandedWebsiteText}</span>
            </div>
            <div className="btn-wrapper">
              <CopyButton urlType={'unbranded'} urlLink={unBrandedUrl} />
              <button className="btn_buton down_zone_main_btn">
                <Link
                  to={`/property-website/${id}/unbranded`}
                  target={'_blank'}
                >
                  Go To Website
                </Link>
              </button>
            </div>
          </div>

          <span className="property_liner"></span>
          <div className="website_section">
            {APIData?.custom_url && (
              <div className="website_section custom_url">
                <div className="website_content">
                  <h1>Custom URL</h1>
                  <span>{customURLText}</span>
                </div>

                <div className="btn_wrapper_custom_url">
                  <div className="custom_url_button">
                    <div className="liner_border"></div>
                    <span className="url-span"> {APIData?.custom_url} </span>
                  </div>
                  <div className="customUrl_wrapper">
                    <CopyButton
                      urlType="custom_url"
                      urlLink={customUrl}
                      APIData={APIData}
                      customClass="custom_url_btn"
                    />
                  </div>
                </div>
                <span className="property_liner" id="custom-url-liner"></span>
              </div>
            )}
            {/* <div className="website_section">
              <div className="website_content">
                <h1>Customize Website</h1>
                <span>{customWebsiteText}</span>
              </div>
              <div className="btn-wrapper">
                {APIData?.settings?.enable_tutorial && (
                  <button
                    className="btn_buton_customize"
                    onClick={handleWatchVideo}
                  >
                    WATCH TUTORIAL
                  </button>
                )}
                <button
                  className="btn_buton_customize"
                  onClick={() =>
                    (window.location.href = "/tours/" + id + "/edit")
                  }
                >
                  GO TO EDITOR
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="liner-first-section" />
      </section>
      {showModalVideo && (
        <VideoModal
          close={setShowModalVideo}
          show={showModalVideo}
          value={APIData?.settings?.tutorial_link}
        />
      )}
    </>
  );
};

export default Content;
