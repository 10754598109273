import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { getTourById } from 'pages/tours/actions';

import { NavigationControl } from 'components';

// Images
import SmileIcon from 'assets/images/smile.svg';

export function PaymentAccepted() {
  const tourDetails = useSelector((state) => state.tours.tourDetails);

  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const tour_id = searchParams.get('tour_id');
  const is_marketing = Boolean(searchParams.get('is_marketing'));

  useEffect(() => {
    if (!!state && !state?.isMarketingTour) {
      dispatch(getTourById({ tourId: tour_id || state?.id }));
    }
  }, []);

  return (
    <div className="content">
      <NavigationControl isBack title={tourDetails.address} acceptPayment />
      <div className="dashboardMain paymentMessage">
        <div className="card">
          <div className="messageThanks">
            <i>
              <img src={SmileIcon} alt="smile" />
            </i>
            <h4>Thank you for your order.</h4>
            <p>An email was sent containing the details of your order.</p>
          </div>
          {!state?.isMarketingTour && !is_marketing && (
            <div className="message">
              <h4>What’s Next?.</h4>
              <p>
                {`We will contact you in order to confirm your requested photoshoot
              ${
                tourDetails.date ? moment(tourDetails.date).format('lll') : ''
              }.`}
              </p>
              <p>
                Once your shoot date/time is confirmed you will receive a
                Scheduling Confirmation Email.
              </p>
              <div className="btnControl">
                <Link
                  to={`/tour-details/${tour_id || state?.id}`}
                  state={{ tour_id: tour_id || state?.id }}
                  className="btn cta-primary"
                >
                  See Listing Details
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
