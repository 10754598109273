import React, { useEffect } from 'react';

export const HoverMessage = (props) => {
  const { myRef, coords, setCoords, checked, setChecked, isVisible, message } =
    props;

  let timeout;

  const handlerMoveMouse = (event) => {
    const rect = myRef.current.getBoundingClientRect();
    setChecked(false);
    clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      setCoords({
        x: event.x - rect.x,
        y: event.y - rect.y,
      });
      setChecked(true);
    }, 300);
  };

  useEffect(() => {
    const _myRef = myRef.current;
    _myRef.addEventListener('mousemove', handlerMoveMouse);
    return () => {
      _myRef.removeEventListener('mousemove', handlerMoveMouse);
    };
  }, [handlerMoveMouse]);

  return (
    <div
      className={`open_an_alert ${checked ? isVisible : ''}`}
      style={{
        left: coords.x,
        top: coords.y,
        width: '200px',
      }}
    >
      <p>{message}</p>
    </div>
  );
};
