// Images
import PlayMatterport from 'assets/images/play_matterport.svg';

const image_base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.replace('/storage', '/');

export const MatterportItem = (props) => {
  const { id, coverImage, description, onClickItem = () => {} } = props;

  return (
    <div className="matterport_item">
      <div onClick={() => onClickItem(id)} className="img_part">
        <img
          className="cover_img"
          alt="cover"
          src={`${image_base_url_without_storage}${coverImage}`}
        />
        <div className="play_img_container">
          <img className="play_img" alt="play" src={PlayMatterport} />
        </div>
      </div>
      <div className="description_part">
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
