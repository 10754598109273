import { useState } from 'react';

import { isMobile } from 'constants';

import { HoverSlider } from 'components/hoverSlider';

const image_base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.replace('/storage', '');

export const ImageItem = (props) => {
  const {
    id,
    imagePath,
    numberInArray,
    itemsCount,
    description,
    imageItems,
    imageItemKey,
    onClickItem = () => {},
  } = props;
  const [hovered, setHovered] = useState(false);

  const swipeGallery = imageItems.map((imagePath) => ({
    [imageItemKey]: imagePath,
  }));

  return (
    <div className="image_item">
      <div
        onMouseEnter={() => (!isMobile ? setHovered(true) : null)}
        onMouseLeave={() => (!isMobile ? setHovered(false) : null)}
        className="img_part"
      >
        {hovered && !isMobile ? (
          <HoverSlider
            swipeGallery={swipeGallery}
            imageItemKey={imageItemKey}
            handleClick={() => onClickItem(numberInArray - 1, id)}
          />
        ) : (
          <>
            <img
              onClick={() => onClickItem(numberInArray - 1, id)}
              className="cover_img"
              alt="cover"
              src={`${image_base_url_without_storage}${imagePath}`}
            />
            <div className="duration_item">{`${numberInArray} / ${itemsCount}`}</div>
          </>
        )}
      </div>
      <div className="description_part">
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
