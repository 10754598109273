import { createSlice } from '@reduxjs/toolkit';
import { adaptUserData } from './adapters';

const initialState = {
  list: [],
  user: {},
  createLoading: false,
  agentToursList: [],
  profile: {},
  isSavingProgress: false,
  isUploadingProfileImage: false,
};

export const { reducer, actions } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: (state, { payload }) => {
      state.list = payload;
    },
    setUser: (state, { payload }) => {
      state.user = adaptUserData(payload);
    },
    setCreateLoading: (state, { payload }) => {
      state.createLoading = payload;
    },
    setTourByAgent: (state, { payload }) => {
      state.agentToursList = payload;
    },
    setProfileData: (state, { payload }) => {
      state.profile = payload;
    },
    setIsSavingProgress: (state, { payload }) => {
      state.isSavingProgress = payload;
    },
    setIsUploadingProfileImage: (state, { payload }) => {
      state.isUploadingProfileImage = payload;
    },
  },
});
