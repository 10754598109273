import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { SUBMIT_STATES } from './constants';
import { RESPONSE_STATUSES } from 'constants';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import { forgotPassword } from 'pages/auth/actions';
import { Toastify } from 'hooks';
import { actions } from './store';

import { AuthBackground, Input } from 'components';
import { AuthSuccess } from './components/auth-success';

export function ForgotPassword() {
  const submitState = useSelector((state) => state.auth.submitState);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(forgotPassword(values))
        .then(() => {
          dispatch(actions.setSubmitState(SUBMIT_STATES.submitted));
        })
        .catch((err) => {
          dispatch(actions.setSubmitState(SUBMIT_STATES.notSubmitted));
          if (err.response.status === RESPONSE_STATUSES.not_found) {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.notExistingEmail,
            });
          } else {
            Toastify({
              type: TOAST_TYPES.error,
              message: TOAST_MESSAGES.connectionCheck,
            });
          }
        });
    },
  });

  useEffect(() => {
    return () => dispatch(actions.setSubmitState(SUBMIT_STATES.notSubmitted));
  }, []);

  return (
    <div className="row g-0">
      <AuthBackground />
      {submitState === SUBMIT_STATES.submitted ? (
        <AuthSuccess
          title="The reset password request sent successfully"
          description="Please check your email (check your SPAM folder as well)"
        />
      ) : (
        <div className="col-md-6">
          <div className="form-wrapper">
            <form onSubmit={formik.handleSubmit}>
              <ul className="cstTabs">
                <li>
                  <Link to="/forgot-password" className="active">
                    Forgot Password
                  </Link>
                </li>
              </ul>
              <Input
                id="email"
                label="Email"
                name="email"
                placeholder="Valid email adrress"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
              <button
                className="btn cta-primary w-100"
                type="submit"
                disabled={submitState === SUBMIT_STATES.submitLoading}
              >
                Forgot Password
              </button>
              <p>
                {'Have you remembered password? '}
                <Link to="/sign-in">Sign In</Link>
              </p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
