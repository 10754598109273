import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Content from '../content';
import 'swiper/css/swiper.css';
import './style.scss';

const Main = ({ service, APIData }) => {
  const params = useParams();
  const [anim, setAnim] = useState();
  const [filterId, setFilterId] = useState(null);
  const [activeWorkIndex, setActiveWorkIndex] = useState(0);
  const secondAddress = APIData?.address?.substring(
    APIData.address?.indexOf(',') + 1
  );

  useEffect(() => {
    setFilterId(null);
    setActiveWorkIndex(0);
  }, [service]);

  useEffect(() => {
    setActiveWorkIndex(0);
  }, [filterId]);

  return (
    <>
      <h2 className="property_title download_section_title">
        PROPERTY WEBSITE
      </h2>
      {/* <span className="property_subtitle">{secondAddress}</span> */}
      <div
        className={`main ${anim ? 'animate' : ''} property_web screen`}
        id="property_section"
      >
        <Content APIData={APIData} />
      </div>
    </>
  );
};

export default Main;
