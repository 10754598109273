import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { login } from './actions';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import { Toastify } from 'hooks';

import { AuthBackground, Input } from 'components';

export function SignIn() {
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(6, 'Password should be of minimum 6 characters length')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values)).catch(() => {
        Toastify({
          type: TOAST_TYPES.error,
          message: TOAST_MESSAGES.invalidAuth,
        });
      });
    },
  });

  return (
    <div className="row g-0">
      <AuthBackground />
      <div className="col-md-6">
        <div className="form-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <ul className="cstTabs">
              <li>
                <Link to="/sign-in" className="active">
                  Sign In
                </Link>
              </li>
              <li>
                <Link to="/sign-up">Sign Up</Link>
              </li>
            </ul>
            <Input
              id="email"
              label="Email"
              name="email"
              placeholder="Valid email adrress"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />

            <Input
              id="password"
              label="Password"
              type="password"
              name="password"
              placeholder="Your password (min 8 chars)"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
            />
            <div className="text-end">
              <Link to="/forgot-password" className="cta">
                Forgot password?
              </Link>
            </div>
            <button className="btn cta-primary w-100" type="submit">
              Sign In
            </button>
            <p>
              {'Don’t have and account? '}
              <Link to="/sign-up">Create account now.</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
