import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationControl } from 'components';

// Images

import Reddit from 'assets/images/reddit.svg';
import Google from 'assets/images/google.svg';
import Facebook from 'assets/images/facebook.svg';
import Sales from 'assets/images/sales.png';
import ArrowDownCircle from 'assets/images/arrow-down-circle.svg';
import CheviconRight from 'assets/images/chevicon-right.svg';
import CheviconLeft from 'assets/images/chevicon-left.svg';
import Calendar from 'assets/images/calendar-today.svg';

const statuses = [
  {
    type: 'delivered',
    title: 'Delivered',
  },
];

export function TourAnalytics() {
  return (
    <div className="content">
      <NavigationControl
        isStatus
        isBack
        statuses={statuses}
        title="489 Clinton Street"
      />
      <div className="controlBar">
        <div className="col-xxl-5 mb-xxl-0 mb-3 order-xxl-1 order-2">
          <ul className="tabsRounded">
            <li>
              <Link to="#">Listing Details</Link>
            </li>
            <li>
              <Link to="#" className="active">
                Analytics
              </Link>
            </li>
            <li>
              <Link to="#">Property Website</Link>
            </li>
            <li>
              <Link to="#">Activity Log</Link>
            </li>
          </ul>
        </div>

        <div className="col-xxl-7 order-xxl-2 order-1 mb-xxl-0 mb-3">
          <ul className="overviewLinks">
            <li>
              <Link to="#">Last 7 days</Link>
            </li>
            <li>
              <Link to="#">Last month</Link>
            </li>
            <li className="dropdown">
              <Link
                to="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <i className="me-2">
                  <img src={Calendar} alt="calendar" />
                </i>{' '}
                Month
              </Link>
              <div className="dropdown-menu calendarDrop">
                <div className="calendarWrapper">
                  <div className="calendarHeader">
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="13/06/2022"
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="13/06/2022"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="calendar-body">
                    <div className="month">
                      <i>
                        <img src={CheviconLeft} alt="left" />
                      </i>
                      <div className="monthName">July</div>
                      <i>
                        <img src={CheviconRight} alt="right" />
                      </i>
                    </div>
                    <ul className="day">
                      <li>S</li>
                      <li>M</li>
                      <li>T</li>
                      <li>W</li>
                      <li>T</li>
                      <li>F</li>
                      <li>S</li>
                    </ul>
                    <ul className="date">
                      <li>
                        <span>1</span>
                      </li>
                      <li>
                        <span>2</span>
                      </li>
                      <li>
                        <span>3</span>
                      </li>
                      <li>
                        <span>4</span>
                      </li>
                      <li>
                        <span>5</span>
                      </li>
                      <li>
                        <span>6</span>
                      </li>
                      <li>
                        <span>7</span>
                      </li>
                      <li>
                        <span>8</span>
                      </li>
                      <li>
                        <span>9</span>
                      </li>
                      <li>
                        <span>10</span>
                      </li>
                      <li>
                        <span>11</span>
                      </li>
                      <li>
                        <span>12</span>
                      </li>
                      <li className="range rangeStart selected">
                        <span>13</span>
                      </li>
                      <li className="range">
                        <span>15</span>
                      </li>
                      <li className="range">
                        <span>16</span>
                      </li>
                      <li className="range">
                        <span>17</span>
                      </li>
                      <li className="range">
                        <span>18</span>
                      </li>
                      <li className="range">
                        <span>19</span>
                      </li>
                      <li className="range">
                        <span>20</span>
                      </li>
                      <li className="range">
                        <span>21</span>
                      </li>
                      <li className="range">
                        <span>22</span>
                      </li>
                      <li className="range rangeEnd selected">
                        <span>23</span>
                      </li>
                      <li>
                        <span>24</span>
                      </li>
                      <li>
                        <span>25</span>
                      </li>
                      <li>
                        <span>26</span>
                      </li>
                      <li>
                        <span>27</span>
                      </li>
                      <li>
                        <span>28</span>
                      </li>
                      <li>
                        <span>29</span>
                      </li>
                      <li>
                        <span>30</span>
                      </li>
                      <li>
                        <span>31</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="#" className="btn cta-primary">
                Dowload Report
                <i>
                  <img src={ArrowDownCircle} alt="down" />
                </i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="dashboardMain">
        <div className="card">
          <h3 className="heading3">Overview</h3>

          <div className="row mb-4">
            <div className="col-xl-7">
              <div className="cardGraph">
                <p>Number of Visitors of property website</p>
                <h4>
                  1,560
                  <small>13.02%</small>
                </h4>
                <div className="graph mt-4">
                  <img src={Sales} alt="sales" className="w-100" />
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="visitor-overview">
                    <p>Website Unique Visitors</p>
                    <div className="counter">120</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="visitor-overview">
                    <p>Website Unique Visitors</p>
                    <div className="counter">120</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="cardTraffice">
                    <h4>Traffic Sources</h4>
                    <div className="media">
                      <span>1</span>
                      <div className="icon">
                        <i>
                          <img src={Facebook} alt="facebook" />
                        </i>
                        <span>Facebook</span>
                      </div>
                      <div className="media-body">
                        https://facebook.com/fasfdas...
                      </div>
                      <div className="number">48%</div>
                    </div>
                    <div className="media">
                      <span>1</span>
                      <div className="icon">
                        <i>
                          <img src={Google} alt="google" />
                        </i>
                        <span>Google</span>
                      </div>
                      <div className="media-body">
                        https://facebook.com/fasfdas...
                      </div>
                      <div className="number">48%</div>
                    </div>
                    <div className="media">
                      <span>1</span>
                      <div className="icon">
                        <i>
                          <img src={Reddit} alt="reddit" />
                        </i>
                        <span>Reddit</span>
                      </div>
                      <div className="media-body">
                        https://facebook.com/fasfdas...
                      </div>
                      <div className="number">48%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3 className="heading3">Detailed Analytics</h3>
          <div className="galleryView">
            <div className="row mb-4">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <h4>Video views</h4>
                <div className="viewCard">
                  <div className="ratio ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                      title="YouTube video"
                      allowFullScreen
                    />
                  </div>
                  <div className="cardFooter">
                    <div className="row">
                      <div className="col-sm-4">
                        <span>Video total views</span>
                        <h4>120</h4>
                      </div>
                      <div className="col-sm-8">
                        <span>Video unique views</span>
                        <h4>1200</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-lg-0 mb-4">
                <h4 className="mt-4 mt-lg-0">Matterport views</h4>
                <div className="viewCard">
                  <div className="ratio ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                      title="YouTube video"
                      allowFullScreen
                    />
                  </div>
                  <div className="cardFooter">
                    <div className="row">
                      <div className="col-sm-4">
                        <span>Matterport total views</span>
                        <h4>120</h4>
                      </div>
                      <div className="col-sm-8">
                        <span>Matterport unique views</span>
                        <h4>1200</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-0">
                <h4 className="mt-4 mt-lg-0">Gallery Views</h4>
              </div>
              <div className="col-xl-6">
                <div className="viewCard">
                  <div className="ratio ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                      title="YouTube video"
                      allowFullScreen
                    />
                  </div>
                  <div className="cardFooter">
                    <div className="row">
                      <div className="col-lg-4 col-sm-4">
                        <span>Video total views</span>
                        <h4>120</h4>
                      </div>
                      <div className="col-lg-8 col-sm-8">
                        <span>Video unique views</span>
                        <h4>1200</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mb-0">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="viewCard">
                      <div className="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                          title="YouTube video"
                          allowFullScreen
                        />
                      </div>
                      <div className="cardFooter">
                        <div className="row">
                          <div className="col-lg-5 col-sm-4">
                            <span>Image Views</span>
                            <h4>120</h4>
                          </div>
                          <div className="col-lg-7 col-sm-8">
                            <span>Gallery Total Views</span>
                            <h4>1200</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="viewCard">
                      <div className="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                          title="YouTube video"
                          allowFullScreen
                        />
                      </div>
                      <div className="cardFooter">
                        <div className="row">
                          <div className="col-lg-5 col-sm-4">
                            <span>Image Views</span>
                            <h4>120</h4>
                          </div>
                          <div className="col-lg-7 col-sm-8">
                            <span>Gallery Total Views</span>
                            <h4>1200</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="viewCard">
                      <div className="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                          title="YouTube video"
                          allowFullScreen
                        />
                      </div>
                      <div className="cardFooter">
                        <div className="row">
                          <div className="col-lg-5 col-sm-4">
                            <span>Image Views</span>
                            <h4>120</h4>
                          </div>
                          <div className="col-lg-7 col-sm-8">
                            <span>Gallery Total Views</span>
                            <h4>1200</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="viewCard">
                      <div className="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                          title="YouTube video"
                          allowFullScreen
                        />
                      </div>
                      <div className="cardFooter">
                        <div className="row">
                          <div className="col-lg-5 col-sm-4">
                            <span>Image Views</span>
                            <h4>120</h4>
                          </div>
                          <div className="col-lg-7 col-sm-8">
                            <span>Gallery Total Views</span>
                            <h4>1200</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
