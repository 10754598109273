import { apiManager } from './apiManager';
import { COMPANY_TYPE, DASHBOARD_COMPANY} from 'constants';

export const toursApi = {
  getTours: async ({ queryStr }) =>
    await apiManager.get(`/dashboard/tours/tours?${queryStr}`, '', {
      [COMPANY_TYPE]: DASHBOARD_COMPANY,
    }),
  getTourById: async ({ tourId }) =>
    await apiManager.get(`/dashboard/tours/tours/${tourId}`),
  getPricingCompany: async (params) =>
    await apiManager.get('/dashboard/tours/dashboard-pricing-company', params),
  getServices: async (price) =>
    await apiManager.get(`/dashboard/tours/services/${price}`),
  getCompanyServices: async () =>
    await apiManager.get('/dashboard/tours/company-services'),
  getPackages: async (price) =>
    await apiManager.get(`/dashboard/tours/packages/${price ?? 1000}`),
  createTour: async (tour) => await apiManager.post('/dashboard/tours', tour),
  createMarketingTour: async (params) =>
    await apiManager.post('/dashboard/tours/marketing/store', params),
  goToPayment: async ({ id, is_main_service }) =>
    await apiManager.post(
      `/dashboard/tours/${id}/payment?is_main_service=${
        is_main_service ? 1 : 0
      }`
    ),
    payForCompanyServices: async ({ id }) =>
    await apiManager.post(`/dashboard/tours/${id}/company-service-payment`),
  goToMarketingTourPayment: async ({ id }) =>
    await apiManager.post(`/dashboard/tours/${id}/marketing-payment`),
  updateTour: async ({ tourId, body }) =>
    await apiManager.post(`/dashboard/tours/tours/update/${tourId}`, body),
  updateCompanyServicesPrice: async ({ tourId, body }) =>
    await apiManager.post(
      `/dashboard/tours/company-service/update-price/${tourId}`,
      body,
      { [COMPANY_TYPE]: DASHBOARD_COMPANY }
    ),
  updateTourServices: async ({ tourId, body }) =>
    await apiManager.post(
      `/dashboard/tours/tours/update-pricing/${tourId}`,
      body
    ),
  getCompanyUsers: async () =>
    await apiManager.get('/dashboard/tours/company-users'),
  getExistingToursToSelect: async () =>
    apiManager.get('/dashboard/tours/delivered-parent'),
};
