import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';

import { SignIn } from 'pages';

export const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export const PublicRoute = ({ isAuthenticated }) => {
  return !isAuthenticated ? <Outlet /> : <SignIn />;
};

export const AdminRoute = ({ isAdmin, isAuthenticated }) => {
  return isAuthenticated && isAdmin ? <Outlet /> : <Navigate to="/" />;
};
