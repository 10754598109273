import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BUTTON_TYPES } from 'components/button/constants';

import { Button } from 'components/button';

// Images
import forwardRightIcon from 'assets/images/forward-right.svg';

export const InformativeForward = (props) => {
  const { infoText, buttonText, forwardPath, onClick } = props;

  const currentStep = useSelector((state) => state.tours.currentStep);
  const selectedPackage = useSelector((state) => state.tours.selectedPackage);
  const selectedPackageServiceIds = useSelector(
    (state) => state.tours.selectedPackageServiceIds
  );
  const selectedCompanyServices = useSelector(
    (state) => state.tours.selectedCompanyServices
  );
  const selectedServices = useSelector((state) => state.tours.selectedServices);
  const selectedSqFt = useSelector((state) => state.tours.selectedSqFt);
  const steps = useSelector((state) => state.tours.steps);

  const navigate = useNavigate();

  const handleClickForward = () => {
    navigate(forwardPath, {
      state: {
        forwarded: true,
        currentStep,
        selectedPackage,
        selectedPackageServiceIds,
        selectedCompanyServices,
        selectedSqFt,
        selectedServices,
        steps,
      },
    });
  };

  return (
    <div className="informative_forward_container">
      <p className="informative_forward_text">{infoText}</p>
      <Button
        type={BUTTON_TYPES.forward}
        isActive={true}
        text={buttonText}
        icon={forwardRightIcon}
        hasRightIcon={true}
        onClick={() => {
          onClick();
          handleClickForward();
        }}
      />
    </div>
  );
};
