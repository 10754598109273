// Images
import prevArrow from 'assets/images/leftArrowSmall.svg';

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className="prev_arrow_container">
      <img src={prevArrow} alt="prev" />
    </div>
  );
};
