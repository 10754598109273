export const infoMessage =
  'Funds will be put on hold on your credit card and will be charged on the day services are delivered [NEXT DAY from the photoshoot]. Any changes or updates to your order will be reflected accordingly.';
export const STEPS = {
  finalize : 'Finalize & Pay',
  address: 'Property Address',
  city: 'City',
  province: 'Province',
  postal_code: 'Postal Code',
  unit_number: 'Unit Number',
  shooting_date: 'Requested Shooting Date & Time',
  order_summary: 'Order Summary',
  payment_message: 'Payment Message',
  charge_later: 'To be charged later.',
  charge_text: '*Due to the nature of this services some details require further information/clarification. These charges will be verified and processed at a later day.',
  more_services: 'More Services',
  price: 'Price',
  not_specified: 'Not specified',
  subtotal: 'Subtotal',
  hst: 'HST',
  hst_percent: '13%',
  total: 'Total',
  previous: 'Previous',
  pay: 'Pay',
  packages_services: 'Add Packages / Services',
  info_text: 'Not sure which services to choose?.',
  button_text: 'Explore the samples of our work.',
  property_sq_ft: 'Property Sq/ft',
  sq_ft: 'SQ/FT',
  select_sq_ft: 'Select SQ/FT range. Please select the square footage of your Listing in order to see the Pricing.',
  sq_ft_note: 'Note: The selected range must include finished basements.',
  sq_ft_title: '1000',
  select_package: 'Select package, or skip to à la carte add-ons/services',
  add_ons: 'Add-ons: Customize your selected package by adding any of our add-ons to your order.',
  payable_now: 'Payable now',
  next: 'Next',
  fixedHstPercent: 13,
  hundredPercent: 100,
  fixedCount: 2,
}