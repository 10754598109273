import React, { useState, useRef } from 'react';
import Select from '../select';
import { Polygon } from 'assets/images';
import { useOnClickOutside } from 'hooks';

export default function SortPopup({
  hideDataType = false,
  changeSort = () => {},
  close = () => {},
}) {
  const ref = useRef();
  useOnClickOutside(ref, close);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  })

  const handleSortDirectionChange = (e) => {
    setSort((prev) => ({ ...prev, direction: e.target.value }));
    const data = {
      field: sort.field,
      direction: e.target.value,
    };
    changeSort(data);
    close();
  };

  return (
    <div className="popup" ref={ref}>
      <div className="content">
        <Polygon className="popup-icon" />
        {!hideDataType && (
          <Select
            hideLabel
            value={sort.field}
            id="data_type"
            name="data_type"
            onChange={(e) =>
              setSort((prev) => ({ ...prev, field: e.target.value }))
            }
            placeholder="Choose Date Type"
            options={[
              {
                label: 'Requested Shoot Date',
                value: 'date',
              },
              {
                label: 'Order Place Date',
                value: 'created_at',
              },
            ]}
          />
        )}
        <Select
          hideLabel
          value={sort.direction}
          id="increase"
          name="increase"
          onChange={(e) => handleSortDirectionChange(e)}
          placeholder="Choose direction"
          options={[
            {
              label: 'Increase',
              value: 'ASC',
            },
            {
              label: 'Decrease',
              value: 'DESC',
            },
          ]}
        />
      </div>
    </div>
  );
}
