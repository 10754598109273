import AlertIcon from 'assets/icons/alert_circle.svg';

export const WarningInfoMessage = (props) => {
  const { text, withIcon } = props;

  return (
    <div className="warning_message_container">
      {withIcon && <img alt="warning" src={AlertIcon} />}
      <p className="warning_text">{text}</p>
    </div>
  );
};
