import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { HoverMessage } from 'components/hoverMessage';
import { PackageCheckbox } from './packageCheckbox';
import { CUSTOMIZE } from 'pages/tours/constants';
import { salesOptionsSelector } from 'pages/tours/selectors';
import checkIcon from "./images/checkIcon.svg";

export const PackageItem = (props) => {
  const {
    pack,
    isSelected,
    selectPackage,
    selectedSqFt,
    selectedPackage,
    isLastItem
  } = props;

  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [checked, setChecked] = useState(false);
  const salesOptions = useSelector(salesOptionsSelector);

  const ref = useRef(null);

  return (
    <div
      ref={ref}
      className={`pack_item_container ${isSelected && 'selected_container'} ${
        !selectedSqFt && 'container_blur'
      } ${selectedSqFt && !selectedPackage?.id ? "container_active_blur" : ""} ${
        isLastItem ? "last_pack_item" : ""
      }`}
      onClick={() => selectPackage(pack)}
    >
      <p className="price">
        {selectedSqFt && pack.price ? `$${pack.price}` : 'Select sq/ft'}
      </p>
      <p className="package_title">{pack.title}</p>
      <div className="service_names_container">
        {pack.dashboard_services.map((service) => {
          return (
            <div key={service.id} className="title_container">
              <p hidden className="service_title_dash">
                -
              </p>
              <img src={checkIcon} alt="Check" />
              <p className="service_title">{service.title}</p>
            </div>
          );
        })}
      </div>
      <button className="select_btn selected_btn">
        {isSelected ? 'Selected' : 'Select'}
      </button>
      {!!pack?.dashboard_sale_options?.length &&
        <div className="sale_options">
          <p className="sale_title">{CUSTOMIZE}</p>
          {pack.dashboard_sale_options.map((option) => (
            <PackageCheckbox
              key={option?.id}
              isPackageSelected={isSelected}
              isOptionSelected={!!salesOptions?.find((elem) => elem?.id === option.id)}
              option={option}
              selectedSqFt={selectedSqFt}
            />
          ))}
        </div>
      }
      {!selectedSqFt && (
        <HoverMessage
          myRef={ref}
          coords={coords}
          setCoords={setCoords}
          checked={checked}
          setChecked={setChecked}
          message={'Please select sq/ft'}
          width="fit-content"
          isVisible="hover_message_visible"
        />
      )}
    </div>
  );
};
