import { isIOS } from 'constants';

// Images
import pdfIcon from 'assets/images/pdf_icon.svg';

const image_base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.replace('/storage', '/');

export const PdfItem = (props) => {
  const {
    id,
    coverImage,
    description,
    fileName,
    path,
    onClickItem = () => {},
  } = props;

  const handleOpenPdf = () => {
    if (isIOS) {
      window.open(path);
    } else {
      onClickItem(id);
    }
  };

  return (
    <div className="pdf_item">
      <div onClick={handleOpenPdf} className="img_part">
        <img
          className="cover_img"
          alt="cover"
          src={`${image_base_url_without_storage}${coverImage}`}
        />
      </div>
      <div onClick={handleOpenPdf} className="pdf_cover">
        <div className="heading">
          <img alt="pdf" src={pdfIcon} />
          <p className="file_name">{fileName}</p>
        </div>
      </div>
      <div className="description_part">
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
