import moment from 'moment';

const convertDate = (date) => {
  const convertDate = moment(Number(date)).format('DD/MM/YYYY');
  return convertDate;
};

export const convertDateFromString = (date) => {
  const convertDate = moment(date).format('DD/MM/YYYY');
  return convertDate;
};

export default convertDate;
