export const makeToursGetQueryStr = ({
  searchValue,
  sortField,
  sortDirection,
  selectedFilterObjQueryStr = '',
  page,
}) => {
  return `page=${page || 1}${sortField ? `&sort[0]=${sortField}` : ''}${
    sortDirection ? `&sort[1]=${sortDirection}` : ''
  }${selectedFilterObjQueryStr}${searchValue ?`&search=${searchValue}`: ""}`;
};

export const makeUserGetQueryStr = ({
  searchValue,
  sortDirection,
  selectedFilterObjQueryStr = '',
  page,
}) => {
  return `page=${page || 1}${sortDirection ? `&sort=${sortDirection}` : ''}
  ${selectedFilterObjQueryStr}${searchValue ?`&search=${searchValue}`: ''}`;
};
