import { createSlice } from '@reduxjs/toolkit';
import { adaptUserData } from 'pages/users/adapters';

import { SUBMIT_STATES } from './constants';

const initialState = {
  user: {},
  submitState: SUBMIT_STATES.notSubmitted,
};

export const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = adaptUserData(payload);
    },
    setSubmitState: (state, { payload }) => {
      state.submitState = payload;
    },
  },
});
