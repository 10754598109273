import { createSelector } from '@reduxjs/toolkit';



const authRootSelector = createSelector(
    ({ auth }) => auth,
    (auth) => auth
);

export const userSelector = createSelector(
    authRootSelector,
    ({ user }) => user
);