import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { userSelector } from 'pages/auth/selectors';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';

// Pages
import {
  SignUp,
  SignIn,
  Users,
  HelpCenter,
  Profile,
  Billing,
  Analytics,
  Activity,
  Tours,
  Intro,
  ProfileSettings,
  TourDetails,
  TourAnalytics,
  TourSteps,
  PropertyWebsite,
  PaymentAccepted,
  PaymentDeclined,
  ChoosingMarketing,
  ResetPassword,
  ForgotPassword,
  CreateCompanyUser,
  DeliverPage,
  OurWork,
} from './pages';
import { DownLoadZone } from 'pages/download-zone';

// Routes Configuration
import { AdminRoute, PrivateRoute, PublicRoute } from 'configureRoutes';

// Layout
import DefaultLayout from './layout/defaultLayout';

export default function Router() {
  const user = useSelector(userSelector);
  const isAuth = () => localStorage.getItem('access_token');
  const getUserRole = () => localStorage.getItem('user_role');
  const isAdmin = isBrokerageAdmin({ role: getUserRole() });

  return (
    <BrowserRouter>
      {!!user && !isAuth() ? (
        <Routes>
          <Route element={<PublicRoute isAuthenticated={isAuth()} />}>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password*" element={<ResetPassword />} />
            <Route path="/property-website/:id/*" element={<DeliverPage />} />
            <Route path="/property-website/:id" element={<DeliverPage />} />
            <Route path="*" element={<Navigate to="/sign-in" />} />
            <Route path="/download-zone/:id" element={<DownLoadZone />} />
            <Route path="/download-zone/:id/*" element={<DownLoadZone />} />
          </Route>
        </Routes>
      ) : (
        <DefaultLayout>
          <Routes>
            <Route
              element={
                <AdminRoute isAuthenticated={isAuth()} isAdmin={isAdmin} />
              }
            >
              <Route path="/users" element={<Users />} />
              <Route path="/user/:id" element={<Profile />} />
              <Route path="/user/:id/edit" element={<ProfileSettings />} />
            </Route>
            <Route element={<PrivateRoute isAuthenticated={isAuth()} />}>
              <Route path="/profile/:id/edit" element={<ProfileSettings />} />
              <Route path="/our-work" element={<OurWork />} />
              <Route path="/" element={<Tours />} />
              <Route path="/help-center" element={<HelpCenter />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/activity" element={<Activity />} />
              <Route path="/tours" element={<Tours />} />
              <Route path="/tour-details/:id" element={<TourDetails />} />
              <Route path="/tour-analytics" element={<TourAnalytics />} />
              <Route path="/tour-steps" element={<TourSteps />} />
              <Route
                path="/property-website-edit/:id"
                element={<PropertyWebsite />}
              />
              <Route path="/payment-accepted" element={<PaymentAccepted />} />
              <Route path="/payment-declined" element={<PaymentDeclined />} />
              <Route path="/choose-market" element={<ChoosingMarketing />} />
              <Route path="/order-new-tour" element={<Intro />} />
              <Route
                path="/users/create-user"
                element={<CreateCompanyUser />}
              />
              <Route path="/property-website/:id/*" element={<DeliverPage />} />
              <Route path="/property-website/*" element={<DeliverPage />} />
              <Route path="*" element={<Navigate to="/tours" />} />
              <Route path="/download-zone/:id" element={<DownLoadZone />} />
              <Route path="/download-zone/:id/*" element={<DownLoadZone />} />
            </Route>
          </Routes>
        </DefaultLayout>
      )}
    </BrowserRouter>
  );
}
