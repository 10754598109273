import { useDispatch, useSelector } from 'react-redux';
import { goToDownload } from 'pages/billing/actions';

import { DownloadBtn } from 'components/donwloadBtn';

const base_url = process.env.REACT_APP_BASE_URL;

const downloadPdfUrl = base_url + '/api/dashboard/tours/download-receipt/';

export function Download(props) {
  const { payment_id, isRefund } = props;

  const dispatch = useDispatch();

  const auth_id = useSelector((state) => state.billing.paymentHistory.auth_id);
  const paymentHistory = useSelector(
    (state) => state.billing.paymentHistory.data
  );

  const handleDownload = (linkKey) => {
    const paymentById = paymentHistory?.find(
      (state) => payment_id === state.payment_id
    );

    if (paymentById.is_main_service) {
      dispatch(goToDownload({ payment_id, auth_id, linkKey }));
    } else {
      window.open(`${downloadPdfUrl}${payment_id}?auth_id=${auth_id}`);
    }
  };

  return (
    <div className="download_invoice">
      <DownloadBtn
        title="Download Invoice"
        handleDownload={handleDownload}
        linkKey="invoice_pdf"
        payment_id={payment_id}
        auth_id={auth_id}
      />
      {isRefund && (
        <DownloadBtn
          title="Download Refund"
          handleDownload={handleDownload}
          payment_id={payment_id}
          auth_id={auth_id}
          linkKey="refund_pdf"
        />
      )}
    </div>
  );
}
