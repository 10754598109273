import { actions } from './store';
import { usersApi, profileApi } from 'api';
import { adaptUserData } from './adapters';

export const getCompanyUsers = (params) => async (dispatch) => {
  const data = await usersApi.getCompanyUsers(params?.queryStr);
  dispatch(actions.setUsersList(data.data));
};

export const deleteCompanyUser = (id, usersList) => async (dispatch) => {
  await usersApi.deleteCompanyUser(id);
  const filteredUsersList = {
    ...usersList,
    data: usersList.data.filter((el) => el.id !== id),
  };
  dispatch(actions.setUsersList(filteredUsersList));
};

export const getCompanyUser = (id) => async (dispatch) => {
  const user = await usersApi.getCompanyUser(id);
  dispatch(actions.setUser(adaptUserData(user.data.data)));
};

export const createCompanyUser = (params) => async (dispatch) => {
  dispatch(actions.setCreateLoading(true));
  const data = await usersApi.createCompanyUser(params);
  dispatch(actions.setUsersList(data.data));
};

export const getTourByAgent =
  ({ id, queryStr }) =>
  async (dispatch) => {
    const tours = await usersApi.getTourByAgent(id, queryStr);
    dispatch(actions.setTourByAgent(tours.data));
  };

export const changeProfilePassword = (body, callback) => async (dispatch) => {
  dispatch(actions.setIsSavingProgress(true));
  return await profileApi.changePasswor(body).finally(() => {
    callback();
    dispatch(actions.setIsSavingProgress(false));
  });
};

export const changeAgentPassword = (body) => async () => {
  return await profileApi.changePasswor(body);
};

export const getProfileData = () => async (dispatch) => {
  const {
    data: { data },
  } = await profileApi.getProfileData();
  dispatch(actions.setProfileData(data));
};

export const updateProfileData = (body) => async (dispatch) => {
  if (body instanceof FormData) {
    dispatch(actions.setIsUploadingProfileImage(true));
  } else {
    dispatch(actions.setIsSavingProgress(true));
  }
  const res = await profileApi.updateProfile(body);
  await dispatch(getProfileData());
  await dispatch(actions.setIsSavingProgress(false));
  await dispatch(actions.setIsUploadingProfileImage(false));
  return res;
};

export const updateAgentProfile =
  (id, body, isChangingImage) => async (dispatch) => {
    if (isChangingImage) {
      dispatch(actions.setIsUploadingProfileImage(true));
    } else {
      dispatch(actions.setIsSavingProgress(true));
    }
    const res = await usersApi.updateCompanyUser(id, body);
    await dispatch(getCompanyUser(id));
    await dispatch(actions.setIsSavingProgress(false));
    await dispatch(actions.setIsUploadingProfileImage(false));
    return res;
  };
