import { useEffect, useState } from "react";

import checkIcon from "./images/check.svg";
import { v4 as uuidv4 } from 'uuid';
import { actions } from 'pages/tours/store';
import { useDispatch, useSelector } from "react-redux";
import { salesOptionsSelector } from "pages/tours/selectors";
import { ADD_FOR_ONLY, DOLLAR_SYMBOL } from "pages/tours/constants";

export const PackageCheckbox = ({
  isPackageSelected,
  option,
  isOptionSelected,
  selectedSqFt
}) => {
  const [value, setValue] = useState(true);
  const salesOptions = useSelector(salesOptionsSelector);

  const id = uuidv4();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPackageSelected) {
      dispatch(actions.setSalesOptions(
        value ?
          salesOptions.filter((elem) => elem?.id !== option?.id)  :
          [ ...salesOptions, option ]
      ))
      setValue(!value);
    }
  }

  useEffect(() => {
    if (!isPackageSelected) {
      setValue(false);
    } else if (isOptionSelected) {
      setValue(true);
    } else if (!isOptionSelected) {
      setValue(false)
    }
  }, [isPackageSelected, isOptionSelected])

  return (
    <label
      key={option.id}
      className={`option_field ${value ? "active_option" : ""}`}
      htmlFor={id}
      onClick={handleChange}
    >
      <div className="option_desc">
        <p className="option_title">{option?.title}</p>
        <p className="option_price">
          {ADD_FOR_ONLY}
          {!!selectedSqFt && ` ${DOLLAR_SYMBOL}${option?.price}`}
        </p>
      </div>
      <div className="checkbox_field">
        <label
          className={`custom_checkbox ${value ? "checkbox_selected" : ""}`}
          aria-label="Checkbox Label"
        >
          <input
            type="checkbox"
            className="checkbox_input"
            id={id}
            disabled={!isPackageSelected}
            checked={value}
            onChange={() => {}}
          />
          {value &&
            <img src={checkIcon} alt="Check" />
          }
        </label>
      </div>
    </label>
  )
}