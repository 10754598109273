import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';
import { BUTTON_TYPES } from './constants';

import { CircularProgress } from 'components/circularProgress';

// Images
import PhoneIconLight from 'assets/icons/phone_light.svg';
import ArrowBack from 'assets/images/arrow-back.svg';

export const Button = (props) => {
  const {
    phone_button,
    text,
    type,
    name,
    disabledWithClassOnly,
    icon,
    hasRightIcon,
    hasLeftIcon,
    isActive,
    loading,
    hidden,
    customClasses,
    submitType = 'submit',
    onClick = () => {},
  } = props;
  return (
    <button
      hidden={hidden}
      name={name}
      onClick={onClick}
      className={`${customClasses} btn_main ${type} ${type}_btn ${
        disabledWithClassOnly && 'disabled_edit'
      } ${isActive && 'active'} ${
        type === BUTTON_TYPES.main_save && 'cta-primary'
      }`}
      type={submitType}
    >
      {loading ? (
        <CircularProgress
          type={CIRCULAR_PROGRESS_TYPES.white}
          size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
        />
      ) : (
        <>
          {phone_button && (
            <a href="tel:+155500000000">
              <img alt="phone" src={PhoneIconLight} /> +1 (555) 000-00000
            </a>
          )}
          {type === BUTTON_TYPES.back && <img alt="back" src={ArrowBack} />}
          {hasLeftIcon && <img alt="" src={icon} />}
          {!phone_button && text}
          {hasRightIcon && <img alt="" src={icon} />}
        </>
      )}
    </button>
  );
};
