import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../button/index';
import '../virtual-staging/styles.scss';
import { useService } from '../../context';
import downloadAsZip from '../../global/download-zip/download-zip';
import DownloadAlert from '../download-alert/download-alert';
import {
  cancelSelection,
  checkImage,
  selectAll,
} from '../../global/image-check-control';
import checkData from '../../global/data-converter-for-download';
import Quantity from '../quantity-text';
import AddressHolder from '../address-holder';
import PhotoSelectButton from '../download-page-sections/photo-select-button/PhotoSelectButton';

const VirtualStaging = ({ open, APIData }) => {
  const { download } = useService();
  const [checkedInput, setCheckedInput] = useState({});
  const params = useParams();
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [links, setLinks] = useState([]);
  const [secondLink, setSecondLink] = useState([]);
  const [thirdLink, setThirdLink] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [data, setData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [touchMove, setTouchMove] = useState(false);
  const firstAddress = APIData?.address?.split(/[,]/)[0];

  const handleDownload = () => {
    if (!!links.length) {
      return downloadAsZip(
        links,
        setIsDownloaded,
        `${firstAddress} ${
          APIData?.unit_number ? `, unit number - ${APIData?.unit_number} ` : ''
        }_Virtual Staging HD photos`
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    setThirdLink([...links, ...secondLink]);
  }, [links, secondLink]);

  useEffect(() => {
    setSelectedItemCount(Object.entries(checkedInput).length);
  }, [checkedInput]);

  useEffect(() => {
    setData(checkData(APIData?.virtual_staging));
  }, [APIData]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    }
  });

  return (
    <>
      {!!APIData?.virtual_staging?.length && (
        <div className="main-content" id="11">
          {isMobileView && (
            <div className="gallery_section_select_button_wrapper">
              <PhotoSelectButton
                isSelectedMode={isSelectedMode}
                setIsSelectedMode={setIsSelectedMode}
              />
            </div>
          )}
          <div className="info">
            <div className="main-info">
              <h2 className="title download_section_title">
                {' '}
                Virtual staging{' '}
              </h2>
              <div className="delivery_update_button">
                <Button sectionName="Virtual Staging" APIData={APIData} />
              </div>
            </div>
            <div className="subtitles_virtual_staging">
              <div className="virtual-staging-address-holder">
                <AddressHolder APIData={APIData} />
              </div>

              <Quantity lengthOfItems={data?.[0]?.files?.length} />
            </div>

            <div className="all_content">
              <div className="virtual-staging-grid-container">
                <div className="screen scrollbar scrollbar-default">
                  {data?.[0]?.files?.map((el, i) => (
                    <div className="staging-items" key={i}>
                      <img className="image" src={el.thumbnailLink} />
                      <div
                        className="hover-section"
                        onTouchStart={() => setTouchMove(false)}
                        onTouchMove={() => setTouchMove(true)}
                        onTouchEnd={(e) =>
                          isSelectedMode && !touchMove
                            ? checkImage(
                                e,
                                el,
                                data?.[1]?.files,
                                links,
                                setLinks,
                                secondLink,
                                setSecondLink,
                                checkedInput,
                                setCheckedInput,
                                selectedItemCount
                              )
                            : !isSelectedMode && !touchMove
                            ? open(
                                data?.[0]?.files,
                                i,
                                'gallery',
                                data?.[0]?.files
                              )
                            : null
                        }
                        onClick={() =>
                          !isMobileView
                            ? open(
                                data?.[0]?.files,
                                i,
                                'gallery',
                                data?.[0]?.files
                              )
                            : null
                        }
                      >
                        <div
                          className={`before_checkbox_section ${
                            checkedInput[el.id]
                              ? 'show_checkbox'
                              : 'hide_checkbox'
                          }`}
                          onClick={(e) =>
                            checkImage(
                              e,
                              el,
                              data?.[1]?.files,
                              links,
                              setLinks,
                              secondLink,
                              setSecondLink,
                              checkedInput,
                              setCheckedInput,
                              selectedItemCount
                            )
                          }
                        >
                          <input
                            className="checkmark_checkbox"
                            type="checkbox"
                            name={el.id}
                            id={el.id}
                            checked={
                              checkedInput[el.id] ? checkedInput[el.id] : false
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="gallery_button_section">
                <div className="btn-wrapper">
                  {!!links.length && (
                    <button
                      className="cancel"
                      onClick={() =>
                        cancelSelection(
                          setLinks,
                          setSecondLink,
                          setThirdLink,
                          setCheckedInput
                        )
                      }
                    >
                      CANCEL SELECTION
                    </button>
                  )}
                  <button
                    className="select"
                    onClick={() =>
                      selectAll(
                        data?.[0]?.files,
                        data?.[1]?.files,
                        setLinks,
                        setSecondLink,
                        setCheckedInput
                      )
                    }
                  >
                    SELECT ALL
                  </button>
                  <div className="download_btn_wrapper">
                    <button
                      className={`download_virtual_staging_btn ${
                        !!isDownloaded
                          ? 'download_virtual_staging_btn_disable'
                          : ''
                      } ${
                        !links.length
                          ? 'download_virtual_staging_btn_opacity'
                          : ''
                      }`}
                      onClick={() => handleDownload()}
                      disabled={!!isDownloaded ? true : false}
                    >
                      DOWNLOAD
                    </button>
                  </div>
                </div>
                <div>
                  <span className={selectedItemCount ? 'select_item' : 'hide'}>
                    {`Selected ${links.length} items`}
                  </span>
                </div>
              </div>
              <span className="liner" id="matterport_line"></span>
            </div>
          </div>
          {isDownloaded && <DownloadAlert />}
        </div>
      )}
    </>
  );
};

export default VirtualStaging;
