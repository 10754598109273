import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import room from '../images/room.png';
import './style.scss';
import magnifier from '../images/magnifier.svg';
import DownloadFilesForm from '../../../download-files-form';
import { useService } from '../../../context';
import Button from '../../button/index';
import DownloadAlert from '../../download-alert/download-alert';
import {
  cancelSelection,
  checkImage,
  selectAll,
} from '../../../global/image-check-control';
import checkData from '../../../global/data-converter-for-download';
import Quantity from '../../quantity-text';
import AddressHolder from '../../address-holder';
import DownloadButtonPopup from '../../download-button-popup/DownloadButtonPopup';
import PhotoSelectButton from '../photo-select-button/PhotoSelectButton';

const virtualStagingDescriptionText =
  'We are very excited to offer our VIRTUAL STAGING service To help our clients adapt to the fast-changing environment of today’s Real Estate Market and bring the best possible value proposition to your listings marketing.';

function Floor({ open, APIData, content }) {
  const params = useParams();
  const [checkedInputWith, setCheckedInputWith] = useState([]);
  const [checkedInputWithOuth, setCheckedInputWithOuth] = useState([]);
  const [linksWith, setLinksWith] = useState([]);
  const [linksWithOuth, setLinksWithOuth] = useState([]);
  const { download } = useService();
  const [isOpen, setisOpen] = useState(false);
  const [apiDataContent, setApiDataContent] = useState({});
  const secondAddress = APIData?.address?.substring(
    APIData?.address?.indexOf(',') + 1
  );
  const [isDownloadedWith, setIsDownloadedWith] = useState(false);
  const [isDownloadedWithOuth, setIsDownloadedWithOuth] = useState(false);
  const [secondLinkWith, setSecondLinkWith] = useState([]);
  const [thirdLinkWith, setThirdLinkWith] = useState([]);
  const [secondLinkWithOuth, setSecondLinkWithOuth] = useState([]);
  const [thirdLinkWithOuth, setThirdLinkWithOuth] = useState([]);
  const [selectedItemCountWith, setSelectedItemCountWith] = useState(0);
  const [selectedItemCountWithOuth, setSelectedItemCountWithOuth] = useState(0);
  const [dataWith, setDataWith] = useState([]);
  const [dataWithOuth, setDataWithOuth] = useState([]);
  const firstAddress = APIData?.address?.split(/[,]/)[0];
  const [isNotificationShownWith, setIsNotificationShownWith] = useState(false);
  const [isNotificationShownWithOuth, setIsNotificationShownWithOuth] =
    useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [touchMove, setTouchMove] = useState(false);

  const handleTouch = (
    e,
    el,
    firstDataArray,
    secondDataArray,
    links,
    setLinks,
    secondLink,
    setSecondLink,
    checkedInput,
    setCheckedInput,
    selectedItemCount,
    i,
    sectionName
  ) => {
    // eslint-disable-next-line no-unused-expressions
    isSelectedMode && !touchMove
      ? checkImage(
          e,
          el,
          secondDataArray,
          links,
          setLinks,
          secondLink,
          setSecondLink,
          checkedInput,
          setCheckedInput,
          selectedItemCount
        )
      : !isSelectedMode && !touchMove
      ? open(firstDataArray, i, sectionName, firstDataArray)
      : null;
  };

  const handleClick = (firstDataArray, secondDataArray, i, sectionName) => {
    !isMobileView && open(firstDataArray, i, sectionName, secondDataArray);
  };

  useEffect(() => {
    setThirdLinkWith([...linksWith, ...secondLinkWith]);
  }, [linksWith, secondLinkWith]);

  useEffect(() => {
    setThirdLinkWithOuth([...linksWithOuth, ...secondLinkWithOuth]);
  }, [linksWithOuth, secondLinkWithOuth]);

  useEffect(() => {
    setSelectedItemCountWith(Object.entries(checkedInputWith).length);
  }, [checkedInputWith]);

  useEffect(() => {
    setSelectedItemCountWithOuth(Object.entries(checkedInputWithOuth).length);
  }, [checkedInputWithOuth]);

  useEffect(() => {
    setDataWith(checkData(APIData?.floor_plans?.withSqFt, false, 'withSqFt'));
    setDataWithOuth(
      checkData(APIData?.floor_plans?.withoutSqFt, false, 'withoutSqFt')
    );
  }, [APIData]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    }
  });

  return (
    <>
      {(!!APIData?.floor_plans?.withSqFt[0]?.files?.length ||
        !!APIData?.floor_plans?.withoutSqFt[0]?.files?.length) && (
        <div className="floor_section" id="14">
          {isMobileView && (
            <div className="gallery_section_select_button_wrapper">
              <PhotoSelectButton
                isSelectedMode={isSelectedMode}
                setIsSelectedMode={setIsSelectedMode}
              />
            </div>
          )}
          <div className=" main_info_floor_plan">
            <h2 className="download_section_title">FLOOR PLAN</h2>
            <div className="delivery_update_button">
              <Button sectionName="Floor Plan" APIData={APIData} />
            </div>
          </div>
          <div className="floor_subtitle">
            <AddressHolder APIData={APIData} />
          </div>
          <div className="floor_item">
            {!!dataWith?.length && (
              <div className="size_mob">
                <Quantity lengthOfItems={dataWith?.[0]?.files?.length} />
              </div>
            )}
            {!!dataWith?.[0]?.files?.length && <h3>With SQ/FT</h3>}
            {!!dataWith?.length && (
              <div className="size_desk">
                <Quantity lengthOfItems={dataWith?.[0]?.files?.length} />
              </div>
            )}
            {!!dataWith?.length && (
              <div className="new">
                <div className="images_section screen scrollbar scrollbar-default">
                  {dataWith?.[0]?.files?.map((el, i) => (
                    <div
                      className={`image_section_item ${
                        checkedInputWith[el.id] && 'image_checked'
                      }`}
                      key={i}
                    >
                      <img
                        src={el?.thumbnailLink ? el.thumbnailLink : room}
                        id="img_3"
                        className="floor_image"
                      />
                      <div
                        className="hover_section"
                        onTouchStart={() => setTouchMove(false)}
                        onTouchMove={() => setTouchMove(true)}
                        onTouchEnd={(e) =>
                          handleTouch(
                            e,
                            el,
                            dataWith?.[0]?.files,
                            dataWith?.[1]?.files,
                            linksWith,
                            setLinksWith,
                            secondLinkWith,
                            setSecondLinkWith,
                            checkedInputWith,
                            setCheckedInputWith,
                            selectedItemCountWith,
                            i,
                            'gallery'
                          )
                        }
                        onClick={() =>
                          handleClick(
                            dataWith?.[0]?.files,
                            dataWith?.[0]?.files,
                            i,
                            'floor_plan'
                          )
                        }
                      >
                        <div className="absolute_gradient"></div>
                        <img src={magnifier} />
                        <div
                          className={`before_checkbox_section ${
                            checkedInputWith[el.id]
                              ? 'show_checkbox'
                              : 'hide_checkbox'
                          }`}
                          onClick={(e) =>
                            checkImage(
                              e,
                              el,
                              dataWith?.[1]?.files,
                              linksWith,
                              setLinksWith,
                              secondLinkWith,
                              setSecondLinkWith,
                              checkedInputWith,
                              setCheckedInputWith,
                              selectedItemCountWith
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            name="check_4"
                            className="checkmark_checkbox"
                            id={el.id}
                            checked={
                              checkedInputWith[el.id]
                                ? checkedInputWith[el.id]
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="floor_button_section">
                  <div className="btn-wrapper">
                    {!!linksWith.length && (
                      <button
                        onClick={() =>
                          cancelSelection(
                            setLinksWith,
                            setSecondLinkWith,
                            setThirdLinkWith,
                            setCheckedInputWith
                          )
                        }
                      >
                        CANCEL SELECTION
                      </button>
                    )}
                    <button
                      className="select_button_floor_plan_section"
                      onClick={() =>
                        selectAll(
                          dataWith?.[0]?.files,
                          dataWith?.[1]?.files,
                          setLinksWith,
                          setSecondLinkWith,
                          setCheckedInputWith
                        )
                      }
                    >
                      SELECT ALL
                    </button>
                    <DownloadButtonPopup
                      isDownloaded={isDownloadedWith}
                      links={linksWith}
                      setIsDownloaded={setIsDownloadedWith}
                      secondLink={secondLinkWith}
                      thirdLink={thirdLinkWith}
                      data={dataWith}
                      sectionNameFirst={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ SQ/FT JPG`}
                      sectionNameSecond={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ SQ/FT PDF`}
                      sectionNameThird={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ SQ/FT JPG and PDF`}
                      downloadTypeFirst="DOWNLOAD JPG"
                      downloadTypeSecond="DOWNLOAD PDF"
                      downloadTypeThird="DOWNLOAD BOTH"
                      folderNameFirst="JPG"
                      folderNameSecond="PDF"
                      section="Floor plan"
                      filesTypeCount="2"
                      setIsNotificationShown={setIsNotificationShownWith}
                    />
                  </div>
                  <span className="select_item">
                    {!!linksWith.length && `Selected ${linksWith.length} items`}
                  </span>
                </div>
              </div>
            )}
            {!!dataWithOuth?.[0]?.files?.length && (
              <div className="floor_item">
                <div className="size_mob">
                  <Quantity lengthOfItems={dataWithOuth?.[0]?.files?.length} />
                </div>
                <h3>WithOUT SQ/FT</h3>
                <div className="size_desk">
                  <Quantity lengthOfItems={dataWithOuth?.[0]?.files?.length} />
                </div>

                <div className="images_section screen scrollbar scrollbar-default">
                  {dataWithOuth?.[0]?.files?.map((el, i) => (
                    <div
                      className={`image_section_item ${
                        checkedInputWithOuth[el.id] && 'image_checked'
                      }`}
                      key={i}
                    >
                      <div className="floor_image">
                        <img
                          src={el?.thumbnailLink ? el.thumbnailLink : room}
                          id="img_3"
                          className="floor_image"
                        />
                      </div>
                      <div
                        className="hover_section"
                        onTouchStart={() => setTouchMove(false)}
                        onTouchMove={() => setTouchMove(true)}
                        onTouchEnd={(e) =>
                          handleTouch(
                            e,
                            el,
                            dataWithOuth?.[0]?.files,
                            dataWithOuth?.[1]?.files,
                            linksWithOuth,
                            setLinksWithOuth,
                            secondLinkWithOuth,
                            setSecondLinkWithOuth,
                            checkedInputWithOuth,
                            setCheckedInputWithOuth,
                            selectedItemCountWithOuth,
                            i,
                            'gallery'
                          )
                        }
                        onClick={() =>
                          handleClick(
                            dataWithOuth?.[0]?.files,
                            dataWithOuth?.[0]?.files,
                            i,
                            'floor_plan'
                          )
                        }
                      >
                        <div className="absolute_gradient"></div>
                        <img src={magnifier} />
                        <div
                          className={`before_checkbox_section ${
                            checkedInputWithOuth[el.id]
                              ? 'show_checkbox'
                              : 'hide_checkbox'
                          }`}
                          onClick={(e) =>
                            checkImage(
                              e,
                              el,
                              dataWithOuth?.[1]?.files,
                              linksWithOuth,
                              setLinksWithOuth,
                              secondLinkWithOuth,
                              setSecondLinkWithOuth,
                              checkedInputWithOuth,
                              setCheckedInputWithOuth,
                              selectedItemCountWithOuth
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            name="check_4"
                            id={el.id}
                            checked={
                              checkedInputWithOuth[el.id]
                                ? checkedInputWithOuth[el.id]
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="floor_button_section">
                  <div className="btn-wrapper">
                    {!!linksWithOuth.length && (
                      <button
                        onClick={() =>
                          cancelSelection(
                            setLinksWithOuth,
                            setSecondLinkWithOuth,
                            setThirdLinkWithOuth,
                            setCheckedInputWithOuth
                          )
                        }
                      >
                        CANCEL SELECTION
                      </button>
                    )}
                    <button
                      className="select_button_floor_plan_section"
                      onClick={() =>
                        selectAll(
                          dataWithOuth?.[0]?.files,
                          dataWithOuth?.[1]?.files,
                          setLinksWithOuth,
                          setSecondLinkWithOuth,
                          setCheckedInputWithOuth
                        )
                      }
                    >
                      SELECT ALL
                    </button>
                    <DownloadButtonPopup
                      isDownloaded={isDownloadedWithOuth}
                      links={linksWithOuth}
                      setIsDownloaded={setIsDownloadedWithOuth}
                      secondLink={secondLinkWithOuth}
                      thirdLink={thirdLinkWithOuth}
                      data={dataWithOuth}
                      sectionNameFirst={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ Without SQ/FT JPG`}
                      sectionNameSecond={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ Without SQ/FT PDF`}
                      sectionNameThird={`${firstAddress} ${
                        APIData?.unit_number
                          ? `, unit number - ${APIData?.unit_number} `
                          : ''
                      }_ Without SQ/FT JPG and PDF`}
                      downloadTypeFirst="DOWNLOAD JPG"
                      downloadTypeSecond="DOWNLOAD PDF"
                      downloadTypeThird="DOWNLOAD BOTH"
                      folderNameFirst="JPG"
                      folderNameSecond="PDF"
                      section="Floor plan"
                      filesTypeCount="2"
                      setIsNotificationShown={setIsNotificationShownWithOuth}
                    />
                  </div>
                  <span className="select_item">
                    {!!linksWithOuth.length &&
                      `Selected ${linksWithOuth.length} items`}
                  </span>
                </div>
              </div>
            )}
          </div>
          <span className="floor-multi-liner"></span>
        </div>
      )}
      <DownloadFilesForm />
      {(isDownloadedWith || isDownloadedWithOuth) && <DownloadAlert />}
      {((isNotificationShownWith && !linksWith.length) ||
        (isNotificationShownWithOuth && !linksWithOuth.length)) && (
        <div
          className="notification_element_for_download_button"
          id="notification_element_for_download_button"
        >
          Please select items for download
        </div>
      )}
    </>
  );
}

export default Floor;
