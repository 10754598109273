import { MODAL_TYPES_ICONS } from '../constants';

export const Header = (props) => {
  const { type, title, headerType } = props;
  return (
    <div className={`modal_header_container ${headerType}`}>
      {!!type && <img alt="type" src={MODAL_TYPES_ICONS[type]} />}
      <h3 className="header_title">{title}</h3>
    </div>
  );
};
