import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';
import DownloadPageService from '../../services/DownloadPageService';
import { downloadFile } from './DownloadFile';
import { getVideosById } from 'pages/download-zone/newActions';

export const DownloadButton = ({ buttonName = '', videoLink = '' }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDownloadVideo = (video) => {
    setIsLoading(true);
    const getVideoId = (videoLink) => {
      const urlArrLength = videoLink.split('/').length;
      const videoId = videoLink
        .replaceAll("'", '')
        .replaceAll('"', '')
        .trim()
        .split('/')[urlArrLength - 1];
      return videoId;
    };

    const getVideoByQuality = (videoLink) => {
      const urlArray = [];
      urlArray.push(videoLink?.[0]?.link);
      downloadFile(urlArray);
      setIsLoading(false);
    };

    DownloadPageService.getVideosById(getVideoId(video)).then((res) => {
      const video_hd_1920 = res?.filter(
        (video) => video.quality === 'hd' && video.width === 1920
      );
      const video_hd_1280 = res?.filter(
        (video) => video.quality === 'hd' && video.width === 1280
      );
      if (video_hd_1920.length > 0) {
        getVideoByQuality(video_hd_1920);
        return;
      }
      if (video_hd_1280.length > 0) {
        getVideoByQuality(video_hd_1280);
        return;
      } else {
        const urlArray = [];
        urlArray.push(res.sort((a, b) => b.width - a.width)?.[0].link);
        downloadFile(urlArray);
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="download_btn_wrapper">
      <button
        className={`download_btn_component down_zone_main_btn`}
        onClick={() => handleDownloadVideo(videoLink)}
        disabled={isLoading}
      >
        {!isLoading ? buttonName : 'PREPARING...'}
      </button>
    </div>
  );
};
