import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { SUBMIT_STATES } from './constants';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';

import { AuthBackground, Input } from 'components';
import { Toastify } from 'hooks';
import { resetPassword } from 'pages/auth/actions';

export function ResetPassword() {
  const [submitState, setSubmitState] = useState(SUBMIT_STATES.notSubmitted);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { token, email } = Object.fromEntries([...searchParams]);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(6, 'Password should be of minimum 6 characters length')
      .max(50, 'Password is too long')
      .required('Password is required'),
    password_confirmation: yup
      .string()
      .min(6, 'Password should be of minimum 6 characters length')
      .max(50, 'Password is too long')
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitState(SUBMIT_STATES.submitLoading);
      dispatch(resetPassword({ ...values, token, email }))
        .then(() => {
          Toastify({
            type: TOAST_TYPES.success,
            message: TOAST_MESSAGES.passwordUpdate,
          });
          setSubmitState(SUBMIT_STATES.submitted);
          navigate('/sign-in');
        })
        .catch(() => {
          setSubmitState(SUBMIT_STATES.notSubmitted);
          Toastify({
            type: TOAST_TYPES.error,
            message: TOAST_MESSAGES.tryAgain,
          });
        });
    },
  });

  return (
    <div className="row g-0">
      <AuthBackground />
      <div className="col-md-6">
        <div className="form-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <ul className="cstTabs">
              <li>
                <Link to="/reset-password" className="active">
                  Reset Password
                </Link>
              </li>
            </ul>
            <Input
              id="password"
              label="Password"
              name="password"
              type="password"
              placeholder="Enter new Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
            />
            <Input
              id="password_confirmation"
              label="Confirm Password"
              name="password_confirmation"
              type="password"
              placeholder="Please confirm Password"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.password_confirmation &&
                Boolean(formik.errors.password_confirmation)
              }
              helperText={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
              }
              required
            />
            <button
              className="btn cta-primary w-100"
              type="submit"
              disabled={submitState === SUBMIT_STATES.submitLoading}
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
