import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import {
  FOOTER_ELEMENTS_DIRECTIONS,
  FOOTER_TYPES,
  HEADER_TYPES,
} from './constants';

export const ModalComponent = (props) => {
  const {
    isOpened,
    setIsOpened,
    type,
    content_1,
    content_2,
    content_3,
    title,
    button_1_phone = false,
    button_1_type,
    button_1_text,
    button_2_phone = false,
    button_2_type,
    button_2_text,
    footerElementsDirection = FOOTER_ELEMENTS_DIRECTIONS.row,
    width = '343px',
    headerType = HEADER_TYPES.light,
    footerType = FOOTER_TYPES.light,
  } = props;
  return (
    <PureModal
      width={width}
      header={<Header headerType={headerType} type={type} title={title} />}
      footer={
        <Footer
          footerType={footerType}
          footerElementsDirection={footerElementsDirection}
          button_1_phone={button_1_phone}
          button_1_type={button_1_type}
          button_1_text={button_1_text}
          button_2_phone={button_2_phone}
          button_2_type={button_2_type}
          button_2_text={button_2_text}
          close={() => setIsOpened(false)}
        />
      }
      isOpen={isOpened}
      closeButton={null}
      closeButtonPosition="header"
      onClose={() => {
        setIsOpened(false);
        return true;
      }}
    >
      <div className="content_container">
        <p className="content_text">{content_1}</p>
        <p className="content_text">{content_2}</p>
        <p className="content_text">{content_3}</p>
      </div>
    </PureModal>
  );
};
