import { Link } from 'react-router-dom';

// Images
import Star from 'assets/images/star.svg';
import Mastercard from 'assets/images/Mastercard.png';

export function PaymentMethods(props) {
  const { title } = props;
  return (
    <div hidden className="payments_container">
      <h3 className="heading3">{title}</h3>
      <div className="billingInfo">
        <div className="caption">
          <h3>Payment methods </h3>
          <p>
            Add and manage your payment methods using our secure payment system.
          </p>
        </div>
        <div className="billingCard">
          <div className="icon">
            <img src={Mastercard} alt="master card" />
          </div>
          <div className="mediaBody">
            <h4>
              MasterCard •••• 1234
              <Link to="#">
                <i>
                  <img src={Star} alt="star" />
                </i>{' '}
                Default
              </Link>
            </h4>
            <p>Expiration: 11/23</p>
          </div>
          <Link to="#">Remove Card</Link>
        </div>
        <div className="billingCard">
          <div className="icon">
            <img src={Mastercard} alt="master card" />
          </div>
          <div className="mediaBody">
            <h4>MasterCard •••• 1234 </h4>
            <p>Expiration: 11/23</p>
          </div>
          <Link to="#">Remove Card</Link>
        </div>

        <div className="billingCard">
          <Link to="#" className="btn cta-primary">
            Add a payment method
          </Link>
        </div>
      </div>
    </div>
  );
}
