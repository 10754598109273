import { createSlice } from '@reduxjs/toolkit';

const bag = localStorage.getItem('bag');
const obj = JSON.parse(bag);

const initialState = {
  bag: obj || {
    selectedServices: [],
    selectedPackages: [],
  },
  sticky: {
    sticky: false,
    scrollY: 0,
  },
  downloadedItems: [],
  tourAddress: {},
  downloadFileType: '',
  services: [],
  servicesPrice: [],
  openCloseModal: false,
};

export const { reducer, actions } = createSlice({
  name: 'downloadZone',
  initialState,
  reducers: {
    setBagData: (state, { payload }) => {
      state.bag = payload;
    },
    setBagEmpty: (state) => {
      state.bag = [];
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setChangeStickyMode: (state, { payload }) => {
      state.sticky = payload.sticky;
      state.scrollY = payload.scrollY;
    },
    setDownloadProgress: (state, { payload }) => {
      state.downloadedItems = [...payload];
    },
    setAddress: (state, { payload }) => {
      state.tourAddress = [payload];
    },
    setDownloadFileType: (state, { payload }) => {
      state.downloadFileType = payload;
    },
    setServices: (state, { payload }) => {
      state.services = payload;
    },
    setServicePrice: (state, { payload }) => {
      state.servicesPrice = payload;
    },
    setOpenCloseModal: (state, { payload }) => {
      state.openCloseModal = payload;
    },
  },
});
