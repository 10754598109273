import { CircularProgress, Input } from 'components';
import { useDispatch } from 'react-redux';
import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';

import { actions } from '../../store';
import { BUTTON_TYPES } from 'components/button/constants';
import { CIRCULAR_PROGRESS_SIZE_NAMES, CIRCULAR_PROGRESS_TYPES } from 'components/circularProgress/constants';

import GooglePlacesAutocomplete from 'components/placesAutocomplete';
import { Button } from 'components/button';

export const AddressSection = (props) => {
  const {
    editingFieldsState,
    tourAddress,
    handleChangeAddress,
    handleClickSave,
    tourDetails,
    handleClickEdit,
    isEditingDisabled,
    setTourAddress,
    onlyAddress,
    addressDetails,
    setAddressDetails,
    handleClickCancel,
  } = props;

  const dispatch = useDispatch();

  const handleSelect = async (value, placeId) => {
    const splittedValue = value.split(',');
    const country = splittedValue.pop();
    const province = splittedValue.pop();
    const city = splittedValue.pop();
    const address = splittedValue.join(',');
    const results = await geocodeByAddress(value);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const data = {
      ...tourDetails,
      city,
      address,
      country,
      province,
      postal_code: postalCode,
    };
    setTourAddress(value);
    dispatch(actions.setTourDetails(data));
  };

  const handleChangeAddressDetails = (e) => {
    setAddressDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <h3 className="address_section_title">Property Address</h3>
      {editingFieldsState.address.status ? (
        <>
          <div
            className={`${
              editingFieldsState.address.status
                ? 'address_details_editing_container'
                : 'address_details_container'
            }`}
          >
            <div className="address_details_first_row">
              <div className="address_sec_container address_input_container">
                <GooglePlacesAutocomplete
                  onSelect={handleSelect}
                  value={tourDetails?.address}
                  onChange={handleChangeAddress}
                  label={'Street Name'}
                />
              </div>
              <div className="address_sec_container">
                <Input
                  customClasses={'address_sec_form_group'}
                  onChange={handleChangeAddressDetails}
                  name={'unit_number'}
                  placeholder={'Unit Number'}
                  label={'Unit Number'}
                  value={addressDetails.unit_number}
                />
              </div>
            </div>
            <div className="address_details_second_row">
              <div className="address_sec_container">
                <Input
                  customClasses={'address_sec_form_group'}
                  onChange={handleChangeAddressDetails}
                  name={'city'}
                  placeholder={'City'}
                  label={'City'}
                  value={addressDetails.city}
                />
              </div>
              <div className="address_sec_container">
                <Input
                  customClasses={'address_sec_form_group'}
                  onChange={handleChangeAddressDetails}
                  name={'province'}
                  placeholder={'Province'}
                  label={'Province'}
                  value={addressDetails.province}
                />
              </div>
              <div className="address_sec_container">
                <Input
                  customClasses={'address_sec_form_group'}
                  onChange={handleChangeAddressDetails}
                  name={'postal_code'}
                  placeholder={'Postal Code'}
                  label={'Postal Code'}
                  value={addressDetails.postal_code}
                />
              </div>
            </div>
          </div>
          {editingFieldsState.address.loading ? (
            <CircularProgress type={CIRCULAR_PROGRESS_TYPES.danger} size={CIRCULAR_PROGRESS_SIZE_NAMES.small} />
          ) : (
            <div className="card_buttons_container">
              <Button
                onClick={({ target }) => handleClickSave(target)}
                name={'address'}
                type={BUTTON_TYPES.save}
                text="Save"
              />
              <Button
                onClick={({ target }) => handleClickCancel(target)}
                name={'address'}
                type={BUTTON_TYPES.cancel}
                text="Cancel"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="address_details_container">
            <div className="address_sec_container">
              <h4 className="part_title">Street Name</h4>
              <p>{`${onlyAddress || ''}`}</p>
            </div>
            <div className="address_sec_container">
              <h4 className="part_title">Unit Number</h4>
              <p>{`${tourDetails?.unit_number || 'Unit number'}`}</p>
            </div>
            <div className="address_sec_container">
              <h4 className="part_title">City</h4>
              <p>{`${tourDetails?.city || ''}`}</p>
            </div>
            <div className="address_sec_container">
              <h4 className="part_title">Province</h4>
              <p>{`${tourDetails?.province || ''}`}</p>
            </div>
            <div className="address_sec_container">
              <h4 className="part_title">Postal Code</h4>
              <p>{`${tourDetails?.postal_code || ''}`}</p>
            </div>
          </div>
          <Button
            onClick={({ target }) => handleClickEdit(target)}
            name={'address'}
            type={BUTTON_TYPES.edit}
            text="Edit"
            disabledWithClassOnly={isEditingDisabled}
          />
        </>
      )}
    </>
  );
};
