import React from 'react';

// Styles
import '../address-holder/styles.scss';

export const AddressHolder = ({ APIData }) => {
  const firstAddress = APIData?.address.split(',').slice(0, -2).join(',');

  return <span className="address">{firstAddress}</span>;
};

export default AddressHolder;
