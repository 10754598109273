import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { TOUR_STATUSES } from 'constants';
import { BUTTON_TYPES } from 'components/button/constants';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';

import { Button } from 'components/button';

// images
import ArrowBack from 'assets/images/arrow-back.svg';
import AddIcon from 'assets/images/add.svg';
import AddIconDark from 'assets/images/add-dark.svg';
import SearchIcon from 'assets/images/search.svg';
import EditWhite from 'assets/images/edit-white.svg';

const types = {
  [TOUR_STATUSES.delivered]: 'badge-success',
  [TOUR_STATUSES.completed]: 'badge-success',
  [TOUR_STATUSES.pending]: 'badge-danger',
  [TOUR_STATUSES.new]: 'badgeDanger',
  [TOUR_STATUSES.uploaded]: 'badgeDarkWarning',
  [TOUR_STATUSES.scheduled]: 'badgeDarkWarning',
  [TOUR_STATUSES.edited]: 'badgeWarning',
};

export default function NavigationControl({
  title,
  childNumber,
  statuses,
  tourStatus,
  noButtons,
  isBack = false,
  backBtnText = null,
  isStatus = false,
  isEdit = false,
  isUsersPage = false,
  isRightBackBtn = false,
  acceptPayment,
}) {
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const params = useParams();

  const { state } = useLocation();

  return (
    <div className="navigationControl bg-white">
      <h2>
        {isBack && !isRightBackBtn && (
          <>
            {backBtnText ? (
              <Button
                onClick={() => {
                  navigate('/tour-steps', {
                    state,
                  });
                }}
                type={BUTTON_TYPES.back}
                text={backBtnText}
              />
            ) : (
              <button
                className="btn-rounded me-3"
                onClick={() =>
                  navigate(acceptPayment ? '/tours' : state?.pathname || -1)
                }
              >
                <img src={ArrowBack} alt="back arrow" />
              </button>
            )}
          </>
        )}
        {!!title && `${title}${childNumber ? ' (' + childNumber + ')' : ''}`}
      </h2>

      <ul className="topNavLinks">
        {backBtnText && isRightBackBtn && (
          <Button
            customClasses={'back_to_order'}
            onClick={() => navigate('/tour-steps', { state })}
            type={BUTTON_TYPES.back}
            text={backBtnText}
          />
        )}
        {isStatus && (
          <ul className="status">
            {statuses?.map(({ type, visibleType, visibleTitle }, index) => {
              if (type === tourStatus) {
                return (
                  <li key={index}>
                    <span className={`badges ${types[visibleType]}`}>
                      {visibleTitle}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        )}
        {isEdit && isBrokerageAdmin(user) && (
          <li>
            <Link to={`/user/${params?.id}/edit`} className="btn cta-primary">
              Edit Profile
              <i>
                <img src={EditWhite} alt="edit icon" />
              </i>
            </Link>
          </li>
        )}
        {isUsersPage && isBrokerageAdmin(user) && (
          <li>
            <Link to="/users/create-user" className="btn cta-primary">
              Add New User
              <i>
                <img src={AddIcon} alt="add icon" />
              </i>
            </Link>
          </li>
        )}
        {!noButtons && (
          <li>
            <Link
              to="/order-new-tour"
              className={
                isEdit || isUsersPage
                  ? 'btn cta-outline-primary ms-3'
                  : 'btn cta-primary'
              }
            >
              New order
              <i>
                <img
                  src={isUsersPage || isEdit ? AddIconDark : AddIcon}
                  alt="add icon"
                />
              </i>
            </Link>
          </li>
        )}
        <li>
          <Link hidden to="#" className="btn-rounded">
            <img src={SearchIcon} alt="search" />
          </Link>
        </li>
      </ul>
    </div>
  );
}
