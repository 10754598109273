import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { store } from './store';
import Router from './router';
import Context from 'pages/download-zone/context';

// Styles
import './App.css';
import 'scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <Context>
        <div className="App">
          <Router />
        </div>
        <ToastContainer />
      </Context>
    </Provider>
  );
}

export default App;
