export const formatPhoneNumber = (typedNumber) => {
  if (!typedNumber) return typedNumber;

  const phoneNumber = typedNumber.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )} - ${phoneNumber.slice(6, 10)}`;
};
