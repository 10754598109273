import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function Confirm({
    title = "Confirm to submit",
    confirm = () => {},
}) {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="react-confirm-alert">
            <div className="react-confirm-alert-overlay">
                <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                    <h1>{title}</h1>
                    <p>Are you sure?</p>
                    <div className="react-confirm-alert-button-group">
                        <button onClick={() => {
                            confirm();
                            onClose();
                        }}>Yes</button>
                        <button onClick={onClose}>No</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
          );
        }
      });
};