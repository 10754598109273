import React, { useId } from 'react';
import ReactTooltip from 'react-tooltip';

export default function Tooltip(props) {
  const { description, tooltipIcon, why_need_this, what_is_this } = props;

  const tooltipId = useId();

  return (
    <>
      {(why_need_this || what_is_this || description) && (
        <div className="tooltip_container">
          <i className="iconInfo" data-for={tooltipId} data-tip>
            <img src={tooltipIcon} alt="info" />
          </i>
          <ReactTooltip
            id={tooltipId}
            className="react_tooltip"
            place="right"
            effect="solid"
            multiline
          >
            {what_is_this && (
              <div>
                <p className="subtitle">What is this?</p>
                <p className="text">{what_is_this}</p>
              </div>
            )}
            {why_need_this && (
              <div>
                <p className="subtitle">Why do I need this?</p>
                <p className="text">{why_need_this}</p>
              </div>
            )}
            {description || ''}
          </ReactTooltip>
        </div>
      )}
    </>
  );
}
