import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  steps: {},
  currentStep: 1,
  packages: [],
  services: [],
  companyServices: [],
  otherServices: [],
  pricingCompanyList: [],
  selectedSqFt: 0,
  selectedServices: [],
  selectedCompanyServices: [],
  chosenOptionsObj: {},
  chosenMarketingOptionsObj: {},
  selectedPackage: {},
  selectedPackageServiceIds: [],
  selectedOtherServices: [],
  selectedPricingCompanyList: [],
  selectedFilterObj: { agents: [], statuses: [] },
  currPage: 1,
  sortingField: '',
  sortingDirection: '',
  tourDetails: {},
  companyUsers: null,
  existingToursToSelect: [],
  salesOptions: [],
  selectedPackageSalOption: [],
  disabledServices: new Set(),
};

export const { reducer, actions } = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setToursList: (state, { payload }) => {
      state.list = payload;
    },
    setPricingCompanyList: (state, { payload }) => {
      state.pricingCompanyList = payload;
    },
    setStepsData: (state, { payload }) => {
      state.steps = Object.keys(payload).length
        ? { ...state.steps, ...payload }
        : payload;
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    setPackages: (state, { payload }) => {
      state.packages = payload;
    },
    setServices: (state, { payload }) => {
      const activeWithServiceIds = new Set();

      const inactiveIds = [];

      payload?.data?.forEach((elem) => {
        if (!payload?.selected_ids?.includes(elem?.id)) {
          inactiveIds.push(...elem?.active_services_ids);
        }
      });

      const data = payload?.data?.map((elem) => {
        if (payload?.selected_ids?.includes(elem?.id)) {
          elem?.inactive_services_ids.forEach((item) => {
            if (!inactiveIds?.includes(item)) activeWithServiceIds.add(item);
          });
        } else {
          elem?.active_services_ids.forEach(
            activeWithServiceIds.add,
            activeWithServiceIds
          );
        }
        return elem;
      });

      state.services = data;
      state.disabledServices = activeWithServiceIds;
    },
    setCompanyServices: (state, { payload }) => {
      state.companyServices = payload;
    },
    setSelectedSqFt: (state, { payload }) => {
      state.selectedSqFt = payload;
    },
    setSelectedServices: (state, { payload }) => {
      state.selectedServices = payload;
    },
    setSelectedCompanyServices: (state, { payload }) => {
      state.selectedCompanyServices = payload;
    },
    setSelectedPackage: (state, { payload }) => {
      state.selectedPackage = payload;
    },
    setSelectedPackageServiceIds: (state, { payload }) => {
      state.selectedPackageServiceIds = payload;
    },
    setSelectedFilterObj: (state, { payload }) => {
      state.selectedFilterObj = payload;
    },
    setCurrPage: (state, { payload }) => {
      state.currPage = payload;
    },
    setSortingField: (state, { payload }) => {
      state.sortingField = payload;
    },
    setSortingDirection: (state, { payload }) => {
      state.sortingDirection = payload;
    },
    setTourDetails: (state, { payload }) => {
      state.tourDetails = payload;
    },
    setCompanyUsers: (state, { payload }) => {
      state.companyUsers = payload;
    },
    setOtherServices: (state, { payload }) => {
      state.otherServices = payload;
    },
    setSelectedOtherServices: (state, { payload }) => {
      state.selectedOtherServices = payload;
    },
    setSelectedPricingCompanyList: (state, { payload }) => {
      state.selectedPricingCompanyList = payload;
    },
    setChosenOptionsObj: (state, { payload }) => {
      state.chosenOptionsObj = payload;
    },
    setChosenMarketingOptionsObj: (state, { payload }) => {
      state.chosenMarketingOptionsObj = payload;
    },
    setExistingToursToSelect: (state, { payload }) => {
      state.existingToursToSelect = payload;
    },
    setSalesOptions: (state, { payload }) => {
      state.salesOptions = payload;
    },
    setSelectedPackageSalOption: (state, { payload }) => {
      state.selectedPackageSalOption = payload;
    },
  },
});
