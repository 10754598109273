import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import { TOUR_STATUSES, classes } from 'constants';
import {
  dayMonthYearHoursMinutesAMSpacesFormatter,
} from 'helpers/momentHelpers';

import { AgentNoImage } from 'components/agentNoImage';

// Images
import DownloadIcon from 'assets/images/download.svg';
import GlobalIcon from 'assets/images/globe.svg';
import ChevronRight from 'assets/images/chevron.right.svg';
import TourDefaultImage from 'assets/images/tour-default-image.svg';

const base_url = process.env.REACT_APP_IMAGE_BASE_URL.split('storage').join('');
const regular_base_url = process.env.REACT_APP_IMAGE_BASE_URL;

export default function MediaTour(props) {
  const { tour, types, isAnalytics = false } = props;
  const {
    address,
    date,
    user,
    mainslider,
    created_at,
    id,
    tour_child_numbers,
    unit_number,
  } = tour;

  const { pathname } = useLocation()

  const formattedShootDate = moment(date).format('lll');
  const formattedCreateDate =
    dayMonthYearHoursMinutesAMSpacesFormatter(created_at);

  const tourDetailsState = { id, pathname };

  return (
    <div className="mediaTour">
      <ul className="badgesList">
        {types?.map(({ type, visibleType, visibleTitle }, index) => {
          return (
            (type === tour.status ||
              (type === 'OTHER' && !!tour.company_services.length)) && (
              <li key={index}>
                <span className={classes[visibleType]}>{visibleTitle}</span>
              </li>
            )
          );
        })}
      </ul>
      <div className="row">
        <div className="col-lg-5">
          <div className="mediatitle">
            <div className="img-holder">
              <Link
                to={`/tour-details/${tourDetailsState.id}`}
                state={tourDetailsState}
              >
                <img
                  src={
                    mainslider[0]?.image
                      ? `${base_url}${mainslider[0].image}`
                      : TourDefaultImage
                  }
                  alt="tour details"
                />
              </Link>
            </div>
            <div className="media-content">
              <h3>
                <Link
                  to={`/tour-details/${tourDetailsState.id}`}
                  state={tourDetailsState}
                >
                  {`${address}${unit_number ? `, unit number: ${unit_number}` : ''
                    } ${tour_child_numbers ? ' (' + tour_child_numbers + ')' : ''
                    }`}
                </Link>
              </h3>
              <h4>Ordered by:</h4>
              <div className="user">
                <div className="avatar">
                  {user?.image ? (
                    <img
                      src={`${regular_base_url}${user?.image}`}
                      alt="user avatar"
                    />
                  ) : (
                    <AgentNoImage
                      noBorder={true}
                      name={user?.name}
                      surname={user?.last_name}
                    />
                  )}
                </div>
                <div className="media-body">
                  {`${user?.name ?? ''} ${user.last_name ?? ''}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-6">
              <div className="orderTags">
                <h4>Order Place Date:</h4>
                <p>{formattedCreateDate}</p>
              </div>
              <div
                className={`orderTags orderTags-${tour?.status?.toLowerCase()}`}
              >
                <h4>
                  {tour?.status === TOUR_STATUSES.new
                    ? 'Requested Shoot Date'
                    : 'Scheduled Shoot Date'}
                </h4>
                <p>{formattedShootDate}</p>
              </div>
            </div>
            {isAnalytics ? (
              <div className="col-lg-6">
                <div className="media-cta align-items-center justify-content-center h-100">
                  <Link to="#" className="cta-analytics">
                    Go to Analytics
                    <i>
                      <img src={ChevronRight} alt="analytics icon" />
                    </i>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="col-lg-6">
                <div className="media-cta">
                  <div className="media-body">
                    <ul>
                      <li>
                        <button
                          className="btn cta-outline-primary cta-rounded"
                          disabled={tour.status !== TOUR_STATUSES.delivered}
                        >
                          <Link to={`/download-zone/${id}`} target={'_blank'}>
                            <i>
                              <img src={DownloadIcon} alt="download icon" />
                            </i>{' '}
                            Download Zone
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn cta-outline-primary cta-rounded tour_item_website_btn"
                          disabled={tour.status !== TOUR_STATUSES.delivered}
                        >
                          <Link
                            to={`/property-website/${id}`}
                            target={'_blank'}
                          >
                            <i>
                              <img src={GlobalIcon} alt="Globe icon" />
                            </i>{' '}
                            Property Website
                          </Link>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
