export const PROPERTY_WEBSITE = {
  property_website: "Property Website",
  property_sale_price: "Property Sale Price",
  dollar_symbol: "$",
  price_example: "ex. $1.000.000",
  edit: "Edit",
  save: "Save",
  property_highlights: "Property Highlights",
  open_house_dates: "Open House Dates",
  add_new_date: "Add new date",
  date: "Date",
  start_date_time: "Start Date & Time",
  time: "Time",
  end_time: "End Time",
  end_time_placeholder: "18:00 PM",
  alternate_text: "Alternate Text",
  offer_presentation_date: "Offer Presentation Date",display: "display",
  property_details: "Property Details",
  property_description: "Property Description",
  highlights: "Highlights",
  highlight: "Highlight",
  add_highlight: "Add highlight",
  neighborhood_info: "Neighborhood Info",
  agent_info: "Agent Info",
  add_agent: "Add agent",
  click_upload_image: "Click here to Upload image",
  upload_image: "Upload Image",
  first_name: "First Name",
  last_name: "Last Name",
  short_Description: "Short Description",
  agent_description: "Description About Agent",
  address: "Address",
  phone: "Phone",
  enter_phone_number: "Enter phone number",
  website: "Website",
  enter_website: "Enter website",
  email: "Email",
  enter_email: "Enter email",
  title: "Title",
  enter_title: "Enter title",
  see_live_preview: "See Live Preview",

}

export const DELIVERY_PAGE = {
  menu: "MENU",
  close_video: "CLOSE VIDEO",
  offered_at: "Offered at $",
  request_showing: "REQUEST A SHOWING",
  highlights: "HIGHLIGHTS",
  matterport: "3D MATTERPORT",
  open_house_on: "OPEN HOUSE ON",
  presentation_date: "PRESENTATION DATE",
  video: "VIDEO",
  gallery: "GALLERY",
  load_more: "LOAD MORE",
  display: "display",
  property_details: "PROPERTY DETAILS",
  what_to_know: "WHAT TO KNOW",
  key_highlights: "KEY HIGHLIGHTS",
  neighborhood_info: "NEIGHBORHOOD INFO",
  floor_plans: "FLOOR PLANS",
  agent: "Agent",
  short_phone: "P:",
  short_email: "M:",
  short_designation: "D:",
  short_website: "W:",
  short_address: "A:",
  copyright: "Ⓒ Copyright 2022 PV Apps. All Rights Reserved",
  powered_by: "Powered by",
  pv_apps: "PV Apps",
}