import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationControl } from 'components';

// Images
import IconUp from 'assets/images/iconUp.svg';
import Avatar from 'assets/images/avatarSingle.png';

import ActivityItem from './components/activityItem';

const activity = {
  date: 'Thu 12 Dec, 10:00 AM',
  name: 'Maria Jones',
  avatar: Avatar,
  log: {
    type: 'Changed tour status to',
    to: 'Delivered',
    from: 'Scheduled',
  },
};

const statuses = [
  {
    type: 'delivered',
    title: 'Delivered',
  },
];

export function Activity() {
  return (
    <div className="content">
      <NavigationControl
        isStatus
        isBack
        statuses={statuses}
        title="489 Clinton Street"
      />
      <div className="controlBar">
        <ul className="tabsRounded">
          <li>
            <Link to="tour-detail.html">Listing Details</Link>
          </li>
          <li>
            <Link to="analytics.html">Analytics</Link>
          </li>
          <li>
            <Link to="property-website.html">Property Website</Link>
          </li>
          <li>
            <Link to="activity.html" className="active">
              Activity Log
            </Link>
          </li>
        </ul>
      </div>
      <div className="dashboardMain">
        <div className="card">
          <h3 className="heading3">Activity Log</h3>

          <div className="table-responsive activityTable">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    User
                    <i>
                      <img src={IconUp} alt="up" />
                    </i>
                  </th>
                  <th>Activity</th>
                  <th>
                    Date & Time
                    <i>
                      <img src={IconUp} alt="up" />
                    </i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {new Array(12).fill(null).map((_, index) => (
                  <ActivityItem activity={activity} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
