import React from 'react';
import './style.scss';

const Loading = ({progress}) => {
    return (
        <div className='photo_loading_wrapper'>
            <div className='photo_loading_outer'>
                <div className='photo_loading_inner'>
                    <svg height="21.5" width="21.5" transform='rotate(-90)'>
                        <circle
                            cx="10.7"
                            cy="10.7"
                            r="9.3"
                            stroke="#e71b4c"
                            stroke-width="3.3"
                            fill="none"
                            stroke-dasharray={`${251 + (0.6 * progress)}`}
                            stroke-dashoffset='251'
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Loading;
