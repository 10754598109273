import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../feature-sheet/style.scss';
import pdfExample from './elements/example.svg';
import pdfIcon from './elements/pdfIcon.svg';
import Button from '../../button/index';
import Quantity from '../../quantity-text';
import AddressHolder from '../../address-holder';

const pdfItems = [
  { icon: [pdfIcon], image: [pdfExample], name: 'Name.pdf' },
  { icon: [pdfIcon], image: [pdfExample], name: 'Name.pdf' },
  { icon: [pdfIcon], image: [pdfExample], name: 'Name.pdf' },
];

export default function FeatureSheet({ APIData, open }) {
  const [checkedInput, setCheckedInput] = useState(false);
  const [shown, setShown] = useState(false);

  const modalBody = () => (
    <div>
      <div className="pdf-modal">
        <div className="pdf-modal-title">
          <div className="first-title">sample-file-name.pdf</div>
          <button className="closing-btn" onClick={() => setShown(false)}>
            Close
          </button>
        </div>
        <div
          className="pdf-holder"
          style={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <iframe
            src="http://www.africau.edu/images/default/sample.pdf#view=fitH"
            width="100%"
            className="pdf-file"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!!APIData?.feature_sheet?.length && (
        <div className="feature-sheet-container" id="15">
          <div className="feature-sheet-info">
            <h2 className="title download_section_title">Feature sheet</h2>
            <div className="delivery_update_button">
              <Button sectionName="Feature sheet" APIData={APIData} />
            </div>
            <div className="address-holder">
              <AddressHolder APIData={APIData} />
            </div>
            <div className="quantity-text">
              <Quantity />
            </div>
          </div>
          <div className="screen scrollbar scrollbar-default">
            {APIData?.feature_sheet?.map((data) => (
              <div className="pdf-holder">
                <div className="feature-sheet-item">
                  <img src={data.icon} alt="icon" className="icon" />
                  <img src={data.image} className="pdf-field" alt="pdf file" />
                  <div className="hover-button">
                    <div className="button-wrapper">
                      <button
                        className="view-button"
                        onClick={() => setShown(true)}
                      >
                        View
                      </button>
                      <div className="checkbox-item">
                        <label
                          className="custom-checkbox"
                          aria-label="Checkbox Label"
                        >
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="pdf-name"> {data.name}</h4>
              </div>
            ))}
          </div>
          <div className="feature-sheet-button-container">
            <div className="first-button">
              <button className="select-all-button">Select all</button>
            </div>
            <div className="second-button">
              <button className="download-all-button">
                DOWNLOAD
                <div className="liner_border"></div>
              </button>
              <div className="dowload_section">
                <div className="download_options">
                  <span>DOWNLOAD PDF</span>
                  <span>DOWNLOAD BOTH</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
    </>
  );
}
