import SearchIcon from 'assets/images/search.svg';

export function SearchComponent(props) {
  const { onFilter, onClear, filterText } = props;

  return (
    <>
      <div className="searchField">
        <input
          onChange={onFilter}
          value={filterText}
          type="text"
          className="form-control table_search_inp"
          placeholder="Try ‘Property Address’"
        />
        <i>
          {filterText.length ? (
            <span className="search_clear_icon" onClick={onClear}>
              x
            </span>
          ) : (
            <img src={SearchIcon} alt="search" />
          )}
        </i>
      </div>
    </>
  );
}
