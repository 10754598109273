import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getPricingCompany } from 'pages/tours/actions';
import { orderNewMarketingTour } from './actions';
import { actions } from 'pages/tours/store';
import { checkLabelsDropdownState } from 'pages/tours/create/helpers';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';

import { CircularProgress, NavigationControl, ServiceLabel } from 'components';
import { ServicesOrderSummary } from 'components/servicesOrderSummary';

const fixedPercentAmount = 13;

export function ChoosingMarketing() {
  const [localSelectedServices, setLocalSelectedServices] = useState([]);
  const [isOpenServiceOptionsId, setIsOpenServiceOptionsId] = useState(null);
  const [isOrderingTour, setIsOrderingTour] = useState(false);

  const pricingCompanyList = useSelector(
    (state) => state.tours.pricingCompanyList
  );
  const selectedPricingCompanyList = useSelector(
    (state) => state.tours.selectedPricingCompanyList
  );
  const chosenMarketingOptionsObj = useSelector(
    (state) => state.tours.chosenMarketingOptionsObj
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subTotal = localSelectedServices.reduce(
    (acc, item) => acc + item.price,
    0
  );

  const subTotalPercentPrice = (
    ((subTotal || 0) * fixedPercentAmount) /
    100
  ).toFixed(0);

  const totalPrice = Number(subTotal) + Number(subTotalPercentPrice);

  const selectCompanyService = (service) => {
    let serviceIds = [];
    if (selectedPricingCompanyList.includes(service?.id)) {
      serviceIds = selectedPricingCompanyList.filter(
        (el) => el !== service?.id
      );
      const filteredLocalServices = localSelectedServices.filter(
        (el) => el.id !== service?.id
      );
      setLocalSelectedServices(filteredLocalServices);
    } else {
      serviceIds = new Set([...selectedPricingCompanyList, service?.id]);
      const selectedService = pricingCompanyList?.find(
        (item) => item.id === service?.id
      );
      setLocalSelectedServices((servs) => [...servs, selectedService]);
    }

    dispatch(actions.setSelectedPricingCompanyList([...serviceIds]));
  };

  const handleOpenServicesOptionsDropdown = (e, id) => {
    e.stopPropagation();
    if (isOpenServiceOptionsId === id) {
      setIsOpenServiceOptionsId(null);
    } else {
      setIsOpenServiceOptionsId(id);
    }
  };

  const handleOptionSelect = ({ serviceId, optionId }) => {
    dispatch(
      actions.setChosenMarketingOptionsObj({
        ...chosenMarketingOptionsObj,
        [serviceId]: Number(optionId),
      })
    );
  };

  const handleOrder = () => {
    const pricingCompanyList = [];
    selectedPricingCompanyList.forEach((id) => {
      if (chosenMarketingOptionsObj[id]) {
        pricingCompanyList.push({
          id: Number(id),
          option_id: Number(chosenMarketingOptionsObj[id]),
        });
      } else {
        pricingCompanyList.push({ id: Number(id), option_id: null });
      }
    });
    setIsOrderingTour(true);
    dispatch(
      orderNewMarketingTour({
        services: pricingCompanyList,
        total_cost: totalPrice,
      })
    )
      .then((res) => {
        dispatch(actions.setSelectedCompanyServices([]));
        navigate('/payment-accepted', {
          state: { ...res.data, isMarketingTour: true },
        });
      })
      .catch((err) => {
        navigate('/payment-declined');
      });
  };

  useEffect(() => {
    dispatch(getPricingCompany());
    return () => {
      dispatch(actions.setSelectedPricingCompanyList([]));
    };
  }, []);

  return (
    <div className="content">
      <NavigationControl isBack title="New Order" />
      <div className="dashboardMain">
        <div className="card">
          <h3 className="heading3">Add Services</h3>

          <div className="row">
            <div className="col-xxl-6">
              <div className="form-group">
                <label htmlFor="">More Services</label>
                <ul className="labelList">
                  {pricingCompanyList?.map((service) => (
                    <ServiceLabel
                      key={service.id}
                      service={service}
                      description={service.description}
                      isOpenServiceOptionsId={isOpenServiceOptionsId}
                      handleOptionSelect={handleOptionSelect}
                      checkLabelsDropdownState={checkLabelsDropdownState}
                      handleOpenServicesOptionsDropdown={
                        handleOpenServicesOptionsDropdown
                      }
                      selectService={selectCompanyService}
                      options={'company_pricing_options'}
                      selectedServices={selectedPricingCompanyList}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-6" />
          </div>
          <div className="packageStep">
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <ServicesOrderSummary
                  title={'Order Summary'}
                  services={localSelectedServices}
                  handlePay={handleOrder}
                  fixedPercentAmount={fixedPercentAmount}
                  isLoadingPayCompanyServices={isOrderingTour}
                  note={
                    ' *Due to the nature of this services some details require further information/clarification. These charges will be verified and processed at a later day.'
                  }
                  hasBtn={false}
                  hasSetPriceOpportunity={false}
                />
              </div>
            </div>
          </div>

          <div className="text-end footerCta mt-5">
            <button
              onClick={() => navigate('/order-new-tour')}
              className="btn cta-outline-primary"
            >
              Back to Selection
            </button>
            <button
              disabled={!selectedPricingCompanyList?.length || isOrderingTour}
              onClick={handleOrder}
              className="btn cta-primary"
            >
              {isOrderingTour ? (
                <CircularProgress
                  type={CIRCULAR_PROGRESS_TYPES.white}
                  size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                />
              ) : (
                'Order'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
