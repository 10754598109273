import React from 'react';

import config from '../../config';
import './style.scss';
import 'animate.css';

// images
import Clock from './white-clock.svg';
import download_icon from './download_icon.svg';
import { getDateWithSlashes } from 'helpers/momentHelpers';

const DownloadPageInformation = ({
  APIData = {},
  title = '',
  agent = '',
  shoot_date = '',
  liner_icon = '',
  services = [],
  services_packages = [],
  customStyle = '',
  clockBlack = Clock,
  popup = false,
}) => {
  const url = config?.imagesBaseUrl;
  const firstAddress = APIData?.address?.split(/[,]/)[0];
  const secondAddress = APIData?.address?.substring(
    APIData.address?.indexOf(',') + 1
  );

  return (
    <div
      className={`download_header_page_info_wrapper animate__animated animate__fadeIn ${customStyle}`}
    >
      <div className={`download_header_page_info_download_icon ${customStyle}`}>
        <img src={download_icon} className="" alt="downloading icon" />
      </div>
      <div className={`download_header_page_info_line_icon ${customStyle}`}>
        <img src={liner_icon} className="" />
      </div>
      <div className={`download_header_page_info_title ${customStyle}`}>
        {title}
      </div>
      <div
        className={`download_header_page_info_address_wrapper ${customStyle}`}
      >
        <div
          className={`download_header_page_info_first_address ${customStyle}`}
        >
          {`${firstAddress}${
            APIData?.unit_number ? `, Unit number: ${APIData.unit_number}` : ''
          }${
            APIData?.tour_child_numbers
              ? ', (' + APIData?.tour_child_numbers + ')'
              : ''
          }`}
        </div>
      </div>
      {agent !== 'undefined undefined' && (
        <div
          className={`download_header_page_info_agent_wrapper ${customStyle}`}
        >
          <div className={`download_header_page_info_agent ${customStyle}`}>
            Agent:
          </div>
          <div
            className={`download_header_page_info_agent_name ${customStyle}`}
          >
            {agent}
          </div>
        </div>
      )}
      <div
        className={`download_header_page_info_date_shoot_wrapper ${customStyle}`}
      >
        <div
          className={`download_header_page_info_date_shoot_name ${customStyle}`}
        >
          Date of Shoot:
        </div>
        <div className={`download_header_page_info_date_shoot ${customStyle}`}>
          {shoot_date}
        </div>
      </div>
      <div
        className={`download_header_page_info_delivery_date_wrapper ${customStyle}`}
      >
        <div
          className={`download_header_page_info_delivery_date_icon ${customStyle}`}
        >
          <img alt="clock" id="clock" src={clockBlack || Clock} />
        </div>
        <div
          className={`download_header_page_info_delivery_date_name ${customStyle}`}
        >
          Delivery date:
        </div>
        <div
          className={`download_header_page_info_delivery_date ${customStyle}`}
        >
          {getDateWithSlashes(APIData?.deliver_date)}
        </div>
      </div>
      <div className={`download_header_page_info_icons_wrapper ${customStyle}`}>
        {services_packages?.map((elem, index) => (
          <div
            className={`download_header_page_info_icon ${customStyle}`}
            key={index}
          >
            <img
              src={url + (popup ? elem.icon : elem.download_center_icon)}
              alt=" servicesIcon"
              className={`${
                popup ? 'download_header_page_info_popup_icon' : ''
              }`}
            />
          </div>
        ))}
        {services?.map((elem, index) => (
          <div
            className={`download_header_page_info_icon ${customStyle}`}
            key={index}
          >
            <img
              src={url + (popup ? elem.icon : elem.download_center_icon)}
              alt=" servicesIcon"
              className={`${
                popup ? 'download_header_page_info_popup_icon' : ''
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DownloadPageInformation;
