import { actions } from './store';
import { ourWorkApi } from 'api';

export const getPropertyWebsiteHighlights = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await ourWorkApi.getOurWork();
    dispatch(actions.setOurServices(data));
    return data;
  };
};
