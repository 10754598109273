import React from 'react';

export const AuthSuccess = ({ title, description }) => {
  return (
    <div className="col-md-6 auth-success-container">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};
