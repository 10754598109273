import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentHistory: { data: [], auth_id: '' },
  isPaymentHistoryLoading: false,
};

export const { reducer, actions } = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setPaymentHistory: (state, { payload }) => {
      state.paymentHistory = payload;
    },
    setIsPaymentHistoryLoading: (state, { payload }) => {
      state.isPaymentHistoryLoading = payload;
    },
  },
});
