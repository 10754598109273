export const INTRO_FLOW_ITEMS = [
  {
    id: 1,
    navigate_path: '/tour-steps',
    title: 'Order Services For New Listings',
    description:
      'Everything you need to make your Listings shine online from Realtor.ca to Social media platforms and Print marketing. See the examples of services below.',
    example_text:
      'Still photography ,Cinematic video, 3D Matterport, Agent Intro, Floor plans, Instagram Reels, Drone videography/photography, Twilight photography, Brochures, Feature sheets, Postcards.',
  },
  {
    id: 2,
    navigate_path: '/tour-steps',
    title: 'Order Services For Existing Listings',
    description:
      'If your Listing already exists in the system, please follow this link to order additional services and reshoots. See the examples of services below.',
    example_text:
      'Still photography ,Cinematic video, 3D Matterport, Agent Intro, Floor plans, Instagram Reels, Drone videography/photography, Twilight photography, Brochures, Feature sheets, Postcards.',
    isExistingFlow: true,
  },
  {
    id: 3,
    navigate_path: '/choose-market',
    title: 'Personal Marketing & Promotional Services',
    description:
      'Looking to take your personal marketing to the next level? See examples of personal marketing and promotional services below.',
    example_text:
      'Instagram Carousel Post, Virtual Staging, Business Cards, Head Shot, Farming Campaign (Post Cards + Canada Post mailing), Branding Package, Social Media Paid Ad, E-blast, Landing Page, Custom Design Requests.',
  },
  {
    id: 4,
    navigate_path:
      'https://www.truesigngroup.com/sign-printing/jxl16t2d3bn2clrc87rrciuezxdt8e',
    title: 'Order Signage',
    description:
      'Please follow the link to place generic sign rentals and installation. See the examples of services below. For Personalized signage and Open House signs, please order services in Personal Marketing & Promotional Services.',
    example_text: 'See templates here',
    isBlankTarget: true,
    isAbsolutePath: true,
    see_templates_here:
      'https://drive.google.com/drive/folders/1rzh1_kUTj78YcHLJFpr1NWyELplqgug8?usp=drive_link',
  },
];

export const SEE_MORE_LENGTH = 100;
export const EXAMPLE_TITLE = 'Example';
export const SEE_LESS = 'See Less';
export const SEE_MORE = 'See More';
