import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getContent } from './newActions';
import { getDateWithSlashes } from 'helpers/momentHelpers';
import config from './config';
import DownloadPageService from './services/DownloadPageService';

import { DownloadFaq } from './components/DownloadFaq';
import DownloadSections from './components/download-page-sections';
import CoverSection from './components/downloads-cover-section/cover-section';
import { MainSlider } from 'components/mainSlider';

// Styles
import './home.style.scss';
import './variables.scss';
import './index.css';
import 'animate.css';

// images
import download from 'pages/download-zone/icons/download.png';
import liner from 'pages/download-zone/icons/liner.png';
import mouse from './icons/mouse.svg';
import close_icon from './images/close-icon.svg';

function DownloadsPage() {
  const [data, setData] = useState({});
  const [content, setContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [modalImageArray, setModalImage] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [swipeGallery, setSwipeGallery] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const dataOPeningDuration = 800;
  const modalBox = document.getElementById('modal_box');
  const loadingPage = document.getElementById('loading-page');

  const handleOpenGalleryModalOnSpecificIndex = (arr, index) => {
    const sortedGallery = [...arr.slice(index), ...arr.slice(0, index)];
    setSwipeGallery(sortedGallery);
    setIsGalleryModalOpen(!isGalleryModalOpen);
    document.body.style.overflow = 'hidden';
  };

  const closeModalHandler = () => {
    setModalImage([]);
    setActiveKey(0);
    setIsGalleryModalOpen(false);
    modalBox?.classList.remove('animate__zoomIn');
    modalBox?.classList.add('animate__zoomOut');
    setModalValue('');
    document.body.style.overflow = 'auto';

    setTimeout(() => {
      setShowModal(!showModal);
    }, 800);
  };

  const changeLoadStatus = () => {
    setPageLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('load', changeLoadStatus);
    return () => window.removeEventListener('load', changeLoadStatus);
  }, []);

  useEffect(() => {
    DownloadPageService.getContent(params.id).then((res) => {
      if (res.status === 404) {
        navigate('/404');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return;
      }
      {
        res.success === true &&
          setTimeout(() => {
            setLoading(false);
          }, dataOPeningDuration);
      }

      setContent(res.data?.item);
    });

    dispatch(getContent(params.id));
  }, [data]);

  useEffect(() => {
    if (loading && document.body) {
      document.body.style.overflow = 'hidden';
      if (loadingPage !== null) {
        loadingPage?.classList.add('animate__fadeOut');
      }
    } else if (document.body) {
      document.body.style.overflow = 'unset';
    }
  }, [loading]);

  return (
    <div
      className={`download_zone_container ${
        isGalleryModalOpen && 'disabled_touch_events'
      }`}
    >
      <div className="relative">
        <CoverSection
          pageLoaded={pageLoaded}
          loading={loading}
          APIData={content}
          idCover={'downloads'}
          img={content?.cover ? config.imagesBaseUrl + content?.cover : null}
          title={'DOWNLOAD ZONE'}
          sub_title={content?.address ?? ''}
          agent={content?.agent?.first_name + ' ' + content?.agent?.last_name}
          shoot_date={
            content?.shoot_data
              ? getDateWithSlashes(content?.shoot_data)
              : '12/02/2021'
          }
          services={content?.services}
          services_packages={content?.packages?.pricing_services_list}
          download_icon={download}
          liner_icon={liner}
          mouse_icon={mouse}
          video={
            content?.cover_video
              ? config.apiBaseUrl + content?.cover_video
              : null
          }
        />
        <DownloadSections
          loading={loading}
          open={handleOpenGalleryModalOnSpecificIndex}
          content={content}
        />
        <DownloadFaq />
        {!loading && isGalleryModalOpen && (
          <MainSlider
            close_icon={close_icon}
            swipeGallery={swipeGallery}
            closeGalleryModal={closeModalHandler}
            showDownloadLink={true}
            isDownloadZone={true}
          />
        )}
      </div>
    </div>
  );
}

export default DownloadsPage;
