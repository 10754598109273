import { NextArrow } from './components/nextArrow';
import { PrevArrow } from './components/prevArrow';

export const defaultSettings = {
  infinite: true,
  className: 'center',
  slidesToShow: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  edgeFriction: 1,
  swipeToSlide: true,
  accessibility: true,
  focusOnSelect: true,
  draggable: true,
  speed: 500,
};
