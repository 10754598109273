// images
import prevArrow from 'assets/images/prevArrow.svg';
import nextArrow from 'assets/images/nextArrow.svg';

export const defaultSettings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '50px',
  slidesToShow: 1,
  nextArrow: <img src={nextArrow} alt="next" />,
  prevArrow: <img src={prevArrow} alt="prev" />,
  edgeFriction: 1,
  swipeToSlide: true,
  variableWidth: true,
  accessibility: true,
  focusOnSelect: true,
  draggable: true,
  speed: 1000,
};

export const mobileDefaultSettings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '4px',
  slidesToShow: 1,
  nextArrow: <img hidden src={nextArrow} alt="next" />,
  prevArrow: <img hidden src={prevArrow} alt="prev" />,
  edgeFriction: 1,
  swipeToSlide: true,
  variableWidth: true, // the position issue caused by this prop
  accessibility: true,
  focusOnSelect: true,
  draggable: true,
  speed: 1000,
};
